var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "gray-plus-button",
      class: { _extend: _vm.isOpen },
      attrs: { href: "javascript:void(0);" },
      on: { click: _vm.emitClick },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.text && _vm.isOpen
        ? _c("span", { staticClass: "gray-plus-button__text" }, [
            _vm._v(_vm._s(_vm.getSwitchText())),
          ])
        : _vm.text && !_vm.isOpen
        ? _c("span", { staticClass: "gray-plus-button__text" }, [
            _vm._v(_vm._s(_vm.text)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gray-plus-button__circle" }, [
      _c("span", { staticClass: "gray-plus-button__line" }),
      _vm._v(" "),
      _c("span", { staticClass: "gray-plus-button__line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }