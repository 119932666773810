var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications-block", class: { _show: _vm.count } },
    [
      _c("div", { staticClass: "notifications-block__outer" }, [
        _c("div", { staticClass: "container container_spacer" }, [
          _c("div", { staticClass: "notifications-block__inner" }, [
            _c(
              "ul",
              { staticClass: "notifications-block__list" },
              _vm._l(_vm.notifications, function (notification, key) {
                return _c(
                  "li",
                  {
                    key: notification.key,
                    staticClass: "notifications-block__item",
                    class: ["_" + notification.type],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "notifications-block__icon" },
                      [
                        notification.type === "error"
                          ? _c("warning-icon")
                          : notification.type === "success"
                          ? _c("success-green-icon")
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "notifications-block__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(notification.text) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "notifications-block__closer",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.removeNotification(key)
                          },
                        },
                      },
                      [_c("closer-icon")],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }