import {onDomChanges} from "../../components/dynamic/observer";
import Jax from "../../components/jax/jax";

class AutoRequestForm {
    constructor(form) {
        this.form = form;

        this.initFields();
        this.bindFields();

        this.form.dataset.initialized = "true";
    }

    initFields() {
        this.checkedInputs = Array.from(this.form.querySelectorAll('input[type="checkbox"], input[type="radio"]'));

    }
    bindFields() {

        this.checkedInputs.forEach((field) => {
            field.addEventListener('change', (e) => {
                this.request();
            });
        });

    }

    request() {
        const jax = new Jax(this.form.getAttribute('action'), this.form.getAttribute('method'), true);
        jax.send(new FormData(this.form)).then((response) => {
            const message = response.message;
            if (message) {
                const event = response.success ? 'showSuccessNotification': 'showErrorNotification'
                document.dispatchEvent(new CustomEvent(event, {
                    detail: {
                        text: message,
                    },
                }))
            }
        });
    }
}

onDomChanges(() => {
   document.querySelectorAll('[data-auto-request-form]:not([data-initialized="true"])').forEach((form) => {
       new AutoRequestForm(form);
   });
});