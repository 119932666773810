import { onDomChanges, onDomReady } from '../../components/dynamic/observer';
import { singleBind } from '../../components/dynamic/bind';
import Jax from '../../components/jax/jax';

class RecoverBlock {
  constructor(element) {
    this.element = element;
    this.loading = false;
    this.init();
  }

  init() {
    this.form = document.querySelector('form');
    const redirectLink = this.element.querySelector('a[data-redirect]');
    if (redirectLink) {
      window.location.href = redirectLink.href;
    }
    this.bind();
  }

  bind() {
    this.bindForm();
  }

  bindForm() {
    if (this.form && !this.form.dataset.initialized) {
      const instance = this;
      this.form.addEventListener('submit', (e) => {
        e.preventDefault();
        instance.request();
      });
    }
  }

  async request() {
    this.setLoading();
    const jax = new Jax(this.form.getAttribute('action'), 'post');
    const response = await jax.send(new FormData(this.form));
    this.replaceContent(response);
    this.init();
    this.unsetLoading();
  }

  replaceContent(html) {
    const page = document.createElement('div');
    page.innerHTML = html;
    const newData = page.querySelector('[data-recover-block]');
    this.element.innerHTML = newData.innerHTML;
  }

  setLoading() {
    this.loading = true;
    this.element.classList.add('_loading');
  }

  unsetLoading() {
    this.loading = false;
    this.element.classList.remove('_loading');
  }
}

onDomReady(() => {
  const element = document.querySelector('[data-recover-block]');
  if (element) {
    const recoverBlock = new RecoverBlock(element);
  }
});

// Апгрейдим ссылку "Забыли пароль?" для передачи логина с формы входа в форму восстановления
onDomChanges(() => {
  const recoverLink = document.querySelector('[data-recover-link]');
  if (recoverLink) {
    singleBind(recoverLink, 'init', 'click', (e) => {
      e.preventDefault();
      const action = recoverLink.href;
      const loginForm = document.querySelector('[data-login-form]');
      if (!loginForm) {
        window.location.href = action;
      }

      loginForm.action = action;
      loginForm.method = 'post';
      loginForm.submit();
    });
  }
});