var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-page__vue" }, [
    _vm.count
      ? _c("div", { staticClass: "cart-page__container grid-block" }, [
          _c(
            "div",
            { staticClass: "cart-page__cart-wrapper" },
            [
              _vm.isForManager ? _c("the-cart-client-selector") : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "cart-page__products" }, [
                _c(
                  "ul",
                  { staticClass: "cart-page__products-list" },
                  _vm._l(_vm.checkout.items, function (item) {
                    return _c(
                      "li",
                      { staticClass: "cart-page__products-item" },
                      [_c("cart-product", { attrs: { item: item } })],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("the-cart-order-comment"),
              _vm._v(" "),
              _vm.isForManager ? _c("the-cart-manager-form") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cart-page__sidebar" }, [
            _c(
              "div",
              { staticClass: "cart-page__sidebar-sticky" },
              [_c("the-cart-result")],
              1
            ),
          ]),
        ])
      : _c("div", { staticClass: "cart-page__empty" }, [
          _c("h2", { staticClass: "cart-page__empty-text" }, [
            _vm._v("\n      Ваша корзина пуста\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cart-page__empty-text-subtitle" }, [
            _vm._v("\n      Перейдите в каталог для выбора товаров\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cart-page__empty-button-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "cart-page__empty-button button button__secondary",
                attrs: { href: "/catalog/tyre/car" },
              },
              [
                _c("TyreIcon", { staticClass: "cart-page__icon" }),
                _vm._v(" "),
                _c("span", { staticClass: "cart-page__empty-button-text" }, [
                  _vm._v("Шины"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "cart-page__empty-button button button__secondary",
                attrs: { href: "/catalog/wheel/car" },
              },
              [
                _c("WheelIcon", { staticClass: "cart-page__icon" }),
                _vm._v(" "),
                _c("span", { staticClass: "cart-page__empty-button-text" }, [
                  _vm._v("Диски"),
                ]),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }