<template>
  <div class="stat-page__content-inner">
    <div class="stat-page__row">
      <key-value-table class="stat-page__table"
          :name="'Количество предложений по поставщикам'"
          :tableKey="'offers_by_providers_table'"
          :extra="{ type: this.productType}"
      />
      <key-value-table class="stat-page__table"
          :name="'Количество предложений на складах'"
          :tableKey="'offers_by_storages_table'"
          :extra="{ type: this.productType}"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import KeyValueTable from "./tables/KeyValueTable.vue";

export default {
  name: "StatOffers",
  components: {
    KeyValueTable
  },
  props: {
    productType: {
      type: String,
      required: false
    },
  },
}
</script>
