<template>
  <form class="cart-order-comment">
    <div class="cart-order-comment__labels">
      <h3 class="cart-order-comment__title">
        Комментарий к заказу
      </h3>
      <div class="cart-order-comment__subtitle text text__main">
        Что нужно учесть при выполнении заказа?
      </div>
    </div>
    <textarea
        v-model="orderData.comment"
        class="cart-order-comment__comment"
        placeholder="Комментарий..."
    />
  </form>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TheCartOrderComment",
  computed: {
    ...mapState('order', {
      'orderData': 'orderData'
    })
  }
}
</script>
