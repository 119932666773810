var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.92304 16.9231C7.77271 16.9231 8.46151 16.2343 8.46151 15.3846C8.46151 14.5349 7.77271 13.8461 6.92304 13.8461C6.07338 13.8461 5.38458 14.5349 5.38458 15.3846C5.38458 16.2343 6.07338 16.9231 6.92304 16.9231Z",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M15.3846 16.9231C16.2343 16.9231 16.9231 16.2343 16.9231 15.3846C16.9231 14.5349 16.2343 13.8461 15.3846 13.8461C14.5349 13.8461 13.8461 14.5349 13.8461 15.3846C13.8461 16.2343 14.5349 16.9231 15.3846 16.9231Z",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M15.3846 13.8461H6.92306L4.61537 3.0769H3.0769",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M5.38458 4.61536L16.923 5.38459L15.3846 10.7692H6.92304",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }