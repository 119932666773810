<template>
  <div class="catalog-filter">
    <preloader v-if="loading"/>

    <div class="catalog-filter__top" v-if="hasAutoTab">
      <div class="catalog-filter__tabs-wrap">
        <ul class="smart-tabs tabs tabs_black">
          <li class="smart-tab tabs__item" :class="{'_active': isParamFilter}">
            <a href="javascript:void(0);" class="tabs__link" @click="clickParamTab">
              По параметрам
            </a>
          </li>
          <li class="smart-tab tabs__item" :class="{'_active': isAutoFilter}">
            <a href="javascript:void(0);" class="tabs__link" @click="clickAutoTab">
              По автомобилю
            </a>
          </li>
        </ul>
      </div>
    </div>

    <TheFilterParam v-if="isParamFilter"/>

    <TheFilterAuto v-else-if="isAutoFilter"/>

    <TheFilterPanel/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import TheFilterParam from './TheFilterParam.vue';
import TheFilterAuto from './TheFilterAuto.vue';
import TheFilterPanel from './TheFilterPanel.vue';
import BaseFieldSwitch from '../parts/fields/BaseFieldSwitch.vue';
import BaseFieldCheckbox from '../parts/fields/BaseFieldCheckbox.vue';
import Preloader from '../parts/Preloader.vue';


export default {
  name: "TheCatalogFilter",
  components: {
    BaseFieldSwitch,
    TheFilterParam,
    TheFilterAuto,
    TheFilterPanel,
    BaseFieldCheckbox,
    Preloader
  },
  data() {
    return {
      settings: {
        hasAuto: false,
        productType: '',
        filterMode: 'disable'
      }
    }
  },
  computed: {
    ...mapState('catalogFilter', {
      'loading': 'loading',
    }),
    ...mapGetters('catalogFilter', {
      'isParamFilter': 'isParamFilter',
      'isAutoFilter': 'isAutoFilter',
      'hasAutoTab': 'hasAutoTab',
    }),
  },
  methods: {
    ...mapActions('catalogFilter', {
      'initFilter': 'initFilter',
      'switchMode': 'switchMode'
    }),
    clickParamTab() {
      this.switchMode('param');
    },
    clickAutoTab() {
      this.switchMode('auto');
    },
  },
  mounted() {
    this.initFilter(this.settings);
  }
}
</script>
