import {
  Controller, Swiper, Autoplay, Pagination, EffectCreative, Grid
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';
import Runner from './runner';

require('../../../node_modules/swiper/swiper-bundle.min.css');

onDomReady(() => {
  const containers = document.querySelectorAll('[data-partners-slider-container]');
  if (containers.length === 0) {
    return;
  }
  containers.forEach((container) => {
    if (container) {
      const imageSliderEl = container.querySelector('[data-partners-slider]');

      const progress = container.querySelector('[data-partners-progress]');

      let imageSlider;

      if (imageSliderEl) {
        imageSlider = new Swiper(imageSliderEl, {
          modules: [Controller, Pagination, Autoplay, Grid],
          breakpoints: {
            0: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 4,
            },
          },
          grid: {
            rows: 3,
          },
          spaceBetween: 30,
          speed: 1500,
          autoplay: {
            delay: 3500,
            disableOnInteraction: true,
          },
          pagination: {
            el: progress,
            type: 'bullets',
            clickable: true,
          },

        });

        const runnerElement = progress.parentNode.querySelector('[data-slider-runner]');
        if (runnerElement) {
          const runner = new Runner(runnerElement);
          imageSlider.on('slideChange', () => {
            runner.translate(imageSlider.realIndex);
          });
        }
      }
    }
  });
});