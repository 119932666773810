<template>
    <div class="markup-content" v-if="node.content">

      <div class="markup-content__inner">
        <div class="markup-content__than-block">
          <span class="markup-content__than-text">
              То
          </span>
        </div>

        <div class="markup-content__main">
          <v-select
              class="markup-content__select markup-content__select_strategy"
              v-model="node.content.strategy"
              label="name"
              :reduce="(option) => option.value"
              :options="[{name: 'Увеличить', value: 'increase'}, {name: 'Уменьшить', value: 'decrease'}, {name: 'Установить', value: 'set'}, {name: 'Рассчитать', value: 'custom'}]"
              :clearable="false"
              :searchable="false"
          ></v-select>

          <div class="markup-content__main-title" v-if="isChange">
            стоимость товара на
          </div>
          <div class="markup-content__main-title" v-else>
            стоимость товара <span v-if="isCustom">по формуле</span>
          </div>


          <input v-if="isCustom"
                 v-model="node.content.custom"
                 class="markup-content__input markup-content__input_custom"
                 type="text"
                 autocomplete="off"
          >

          <input v-else
                 v-model="node.content.value"
                 class="markup-content__input"
                 type="text"
                 autocomplete="off"
          >

          <v-select
              class="markup-content__select markup-content__select_base"
              v-if="isChange"
              v-model="node.content.base"
              label="name"
              :reduce="(option) => option.value"
              :options="[{name: '%', value: 'percent'}, {name: 'руб', value: 'money'}]"
              :clearable="false"
              :searchable="false"
          ></v-select>

        </div>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  name: "MarkupTree",
  props: {
    node: Object
  },
  computed: {
    isCustom() {
      return this.node.content.strategy === 'custom';
    },
    isChange() {
      return ['increase', 'decrease'].includes(this.node.content.strategy)
    }
  },
  methods: {
    getDefaultContent() {
      return {
        value: 10,
        strategy: 'increase',
        base: 'percent'
      }
    }
  },
  mounted() {
    if (!this.node.content) {
      this.$set(this.node, 'content', this.getDefaultContent());
    }
  }
}
</script>

<style lang="scss">

</style>