import NotifySettingsService from '../../services/notifySettings.service';

const NotifySettings = {
  namespaced: true,
  state: {
    email: '',
    orders: {
      enable: false,
      avail_statuses: [],
      current_statuses: [],
    },
    products: {
      filters: {
        personal: [],
        general: [],
      },
      avail_events: [],
      current_events: [],
      end_at: null,
      reserve_count: '',
    },
  },
  getters: {

  },
  actions: {
    async loadInitialState({ commit }) {
      try {
        const response = await NotifySettingsService.getInitialState();

        const { email, orders, products } = response.data;
        products.current_events = [];

        await commit('setEmail', email);
        await commit('setOrders', orders);
        await commit('setProducts', products);
        await commit('setCurrentEvents', []);
        await commit('setEndAt', null);
      } catch (e) {
        console.log(e);
      }
    },
    async loadCurrentProductsEvents({ commit, state }, { type, id }) {
      try {
        const response = await NotifySettingsService.getCurrentProductsEvents(type, id);
        // await commit('setCurrentEvents', response.data.events);
        // await commit('setEndAt', response.data.end_at);
        const currentProducts = state.products;
        currentProducts.current_events = response.data.events;
        currentProducts.end_at = response.data.end_at;
        currentProducts.reserve_count = response.data.reserve_count;

        await commit('setProducts', currentProducts);
      } catch (e) {
        console.log(e);
      }
    },
    async saveCurrentProductsEvents({ state, dispatch }, { type, id }) {
      try {
        const data = {
          events: state.products.current_events,
          email: state.email,
          end_at: state.products.end_at,
          reserve_count: state.products.reserve_count
        };
        const response = await NotifySettingsService.saveCurrentProductsEvents(type, id, data);
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
        return;
      }

      dispatch('notifications/showSuccessNotification', 'Настройки оповещений сохранены', { root: true });
    },
    async saveCurrentOrderStatuses({ state, dispatch }) {
      try {
        const data = {
          email: state.email,
          enable: state.orders.enable,
          statuses: state.orders.current_statuses,
        };
        const response = await NotifySettingsService.saveCurrentOrderStatuses(data);
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
        return;
      }

      dispatch('notifications/showSuccessNotification', 'Настройки оповещений сохранены', { root: true });
    },
  },
  mutations: {
    setEmail(state, email) {
      state.email = email;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setProducts(state, products) {
      state.products = products;
    },
    setCurrentEvents(state, events) {
      state.products.current_events = events;
    },
    setEndAt(state, date) {
      console.log(date);
      state.products.end_at = date;
    },
  },
};

export default NotifySettings;