import Jax from '../../components/jax/jax.js';
import { onDomReady } from '../../components/dynamic/observer';
import {validatorCleanErrors, validatorValidateForm} from '../../components/forms/validation';

class Chat {
  constructor(container) {
    this.container = container;
    this.messagesBlock = container.querySelector('[data-messages]');
    this.form = container.querySelector('[data-chat-form]');
    this.chatLink = document.querySelector(`[data-chat-link="${this.container.dataset.chatBlock}"]`);

    this.init();
    this.bind();
  }

  init() {
    this.removeUnread();
  }

  removeUnread() {
    if (this.chatLink) {
      this.chatLink.classList.remove('_message');
    }
  }

  bind() {
    this.bindForm();
  }

  bindForm() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      const jax = new Jax(this.form.getAttribute('action'), 'post', true);
      jax.send(new FormData(this.form)).then((response) => {
        if (response.state === 'success') {
          validatorCleanErrors(this.form, this.form.dataset.formName);
          this.refreshMessages();
          this.clearInput();
        } else if (response.state === 'error') {
          this.handleError(response.errors);
        }
      });
    });
  }

  refreshMessages() {
    const jax = new Jax('', 'get', false);
    jax.send(new FormData()).then((html) => {
      const page = document.createElement('div');
      page.innerHTML = html;
      const newData = page.querySelector('[data-messages]');
      const currentData = document.querySelector('[data-messages]');
      currentData.innerHTML = newData.innerHTML;
      refreshFsLightbox();
    });
  }

  clearInput() {
    this.form.querySelector('input[type="text"]').value = '';
    this.form.querySelector('input[type="file"]').dispatchEvent(new Event('clear'));
  }

  handleError(errors) {
    const key = this.form.dataset.formName;
    validatorValidateForm(this.form, key, errors[key]);
  }
}

onDomReady(() => {
  const element = document.querySelector('[data-chat-block]');
  if (element) {
    new Chat(element);
  }
});