import {onDomReady} from '../../components/dynamic/observer';

class PriceEditor {
    constructor(element) {
        this.element = element;
        this.link = element.querySelector('a');
        this.input = element.querySelector('input');
        this.isInit = false;
        this.items = [];
        this.markup = null;
        this.isPercent = false;

        this.selectors = [
            '.catalog-avail-list__price'
        ];

        this.wholesaleSelector = '.catalog-avail-list__price';
        this.mainPriceSelector = '.catalog-product__rrc';

        this.bind();
    }

    bind() {
        this.link.addEventListener('click', (e) => {
            e.preventDefault();
            this.element.classList.toggle('_open');
            if (!this.isInit) {
                this.init();
            }
        });

        this.bindInput();
    }

    bindInput() {
        this.input.addEventListener('input', (e) => {
            const value = e.target.value;
            if (value === '') {
                this.clear();
                return;
            }

            const isPercent = value.includes('%');
            const number = parseInt(value.replaceAll(/[^0-9]-/gumi, ''), 10);
            if (!number) {
                return;
            }

            this.isPercent = isPercent;
            this.markup = number;

            this.recalculate();
        });
    }

    recalculate() {
        const markup = this.markup;
        if (!markup) {
            return;
        }
        this.items.forEach((item) => {
            let mainPrice = '';
            item.prices.forEach((price) => {
                let newPrice = price.originalPrice;

                if (this.isPercent) {
                    const diff = newPrice / 100 * Math.abs(markup);
                    newPrice = markup > 0 ? newPrice + diff : newPrice - diff;
                } else {
                    newPrice += markup
                }

                newPrice = Math.round(newPrice);
                const newContent = `${newPrice.toLocaleString()} ₽`;
                price.element.innerHTML = newContent;
                if (!mainPrice) {
                    mainPrice = newContent;
                }
            });

            item.mainPrice.element.innerHTML = mainPrice
        });
    }

    clear() {
        this.markup = null;
        this.items.forEach((item) => {
            item.mainPrice.element.innerHTML = item.mainPrice.originalContent;
            item.prices.forEach((price) => {
               price.element.innerHTML = price.originalContent;
            });
        });
    }


    init() {
        const items = document.querySelectorAll('[data-catalog-product]:not([data-price-init="true"])');
        items.forEach((item) => {
            const mainPriceElement = item.querySelector(this.mainPriceSelector);

            const itemObject = {
                element: item,
                mainPrice: {
                    element: mainPriceElement,
                    originalContent: mainPriceElement.innerHTML
                },
                prices: []
            }

            item.querySelectorAll(this.wholesaleSelector).forEach((priceElement) => {
                const content = priceElement.innerHTML;
                const priceObject = {
                    element: priceElement,
                    originalContent: content,
                    originalPrice: parseInt(content.replaceAll(/[^0-9\\.]/gumi, ''), 10),
                }
                itemObject.prices.push(priceObject);
            });

           this.items.push(itemObject);
        });

        if (!this.isInit) {
            this.isInit = true;
            document.addEventListener('DOMContentMutated', () => {
                this.init();
                if (this.markup) {
                    this.recalculate();
                }
            });
        }

    }
}

onDomReady(() => {
    const element = document.querySelector('[data-price-editor]');
    if (element) {
        new PriceEditor(element);
    }
});