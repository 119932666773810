import axios from 'axios';

const NotifySettingsService = {
  async getInitialState() {
    const url = '/notifications/api/get_initial_state';
    return axios.get(url);
  },
  async getCurrentProductsEvents(type, filterId) {
    const url = `/notifications/api/get_products_events/${type}/${filterId}`;
    return axios.get(url);
  },
  async saveCurrentProductsEvents(type, filterId, data) {
    const url = `/notifications/api/save_products_events/${type}/${filterId}`;
    return axios.post(url, data);
  },
  async saveCurrentOrderStatuses(data) {
    const url = '/notifications/api/save_order_statuses';
    return axios.post(url, data);
  },
};

export default NotifySettingsService;