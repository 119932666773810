var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__inner-content" }, [
    _c(
      "a",
      {
        staticClass: "stat-page__back-link",
        attrs: { href: "javascript:void(0);" },
        on: {
          click: function ($event) {
            return _vm.setCurrentClient(null)
          },
        },
      },
      [
        _c("ArrowLongLeft", { staticClass: "stat-page__back-link-icon" }),
        _vm._v("\n    Вернуться к списку клиентов\n  "),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-page__row" },
      [
        _c("the-stat-client-actions", {
          staticClass: "stat-page__large-block",
          attrs: {
            actions: _vm.statData.client_actions,
            "is-loaded": _vm.isLoadeds.client_actions,
            total: _vm.statData.total_client_actions,
          },
          on: {
            update: function (params) {
              return _vm.update("client_actions", params)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }