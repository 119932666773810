var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__content" }, [
    _c("div", { staticClass: "stat-page__inner-content" }, [
      _c("div", { staticClass: "stat-page____row" }, [
        _c(
          "div",
          { staticClass: "stat-page__large-block" },
          [
            _c("BaseGraph", {
              attrs: {
                "graph-key": "durations-graph",
                title: "Отслеживание продолжительности (сек.)",
                "initial-range": _vm.getDayRange(),
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }