var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-filter__params" },
    [
      _vm.isEav
        ? _c("TheFilterParamShortEav")
        : _vm.isNotEav
        ? _c("TheFilterParamShort")
        : _vm._e(),
      _vm._v(" "),
      _vm.extend ? _c("TheFilterParamExtend") : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("preloader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }