var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.node.content
    ? _c("div", { staticClass: "markup-content" }, [
        _c("div", { staticClass: "markup-content__inner" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "markup-content__main" },
            [
              _c("v-select", {
                staticClass:
                  "markup-content__select markup-content__select_strategy",
                attrs: {
                  label: "name",
                  reduce: function (option) {
                    return option.value
                  },
                  options: [
                    { name: "Увеличить", value: "increase" },
                    { name: "Уменьшить", value: "decrease" },
                    { name: "Установить", value: "set" },
                    { name: "Рассчитать", value: "custom" },
                  ],
                  clearable: false,
                  searchable: false,
                },
                model: {
                  value: _vm.node.content.strategy,
                  callback: function ($$v) {
                    _vm.$set(_vm.node.content, "strategy", $$v)
                  },
                  expression: "node.content.strategy",
                },
              }),
              _vm._v(" "),
              _vm.isChange
                ? _c("div", { staticClass: "markup-content__main-title" }, [
                    _vm._v("\n        стоимость товара на\n      "),
                  ])
                : _c("div", { staticClass: "markup-content__main-title" }, [
                    _vm._v("\n        стоимость товара "),
                    _vm.isCustom
                      ? _c("span", [_vm._v("по формуле")])
                      : _vm._e(),
                  ]),
              _vm._v(" "),
              _vm.isCustom
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.node.content.custom,
                        expression: "node.content.custom",
                      },
                    ],
                    staticClass:
                      "markup-content__input markup-content__input_custom",
                    attrs: { type: "text", autocomplete: "off" },
                    domProps: { value: _vm.node.content.custom },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.node.content,
                          "custom",
                          $event.target.value
                        )
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.node.content.value,
                        expression: "node.content.value",
                      },
                    ],
                    staticClass: "markup-content__input",
                    attrs: { type: "text", autocomplete: "off" },
                    domProps: { value: _vm.node.content.value },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.node.content, "value", $event.target.value)
                      },
                    },
                  }),
              _vm._v(" "),
              _vm.isChange
                ? _c("v-select", {
                    staticClass:
                      "markup-content__select markup-content__select_base",
                    attrs: {
                      label: "name",
                      reduce: function (option) {
                        return option.value
                      },
                      options: [
                        { name: "%", value: "percent" },
                        { name: "руб", value: "money" },
                      ],
                      clearable: false,
                      searchable: false,
                    },
                    model: {
                      value: _vm.node.content.base,
                      callback: function ($$v) {
                        _vm.$set(_vm.node.content, "base", $$v)
                      },
                      expression: "node.content.base",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "markup-content__than-block" }, [
      _c("span", { staticClass: "markup-content__than-text" }, [
        _vm._v("\n          То\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }