var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Tree", {
    attrs: { treeId: _vm.treeId, baseUrl: _vm.baseUrl, context: _vm.context },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var node = ref.node
          return [_c("IncomingContent", { attrs: { node: node } })]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }