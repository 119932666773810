<template>
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9586 1.66779L4.94348 9.09501L0.289372 5.00166L1.61353 3.52872L4.60237 6.15743L9.30981 0.344906L10.9586 1.66779Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "CheckIcon"
}
</script>