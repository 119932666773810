import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class AutoPagination {
  links = [];
  constructor() {
    this.findLinks();
    this.bind();
    this.detectLinks();
  }

  bind() {
    this.bindMutated();
    this.bindScroll();
  }

  bindMutated() {
    const autoPaginator = this;
    onDomChanges((e) => {
      setTimeout(() => {
        autoPaginator.findLinks();
        autoPaginator.detectLinks();
      }, 600);
    })
  }

  bindScroll() {
    const autoPaginator = this;
    window.addEventListener('scroll', (e) => {
      autoPaginator.detectLinks();
    });
  }

  detectLinks() {
    const toTriggerIndexes = [];
    this.links.forEach((link, index) => {
      const bound = link.getBoundingClientRect();
      if (bound.top - window.innerHeight <= window.innerHeight / 2) {
        toTriggerIndexes.push(index);
      }
    });

    if (toTriggerIndexes.length) {
      this.dispatchLinks(toTriggerIndexes)
    }
  }

  dispatchLinks(indexes) {
    const autoPaginator = this;
    const event = new Event('click', {'detail': 'mydetail', cancelable: true});
    indexes.forEach((index) => {
      const link = autoPaginator.links[index];
      if (link && link.getBoundingClientRect().height) {
        autoPaginator.links[index].dispatchEvent(event);
        autoPaginator.links.splice(index, 1);
      }
    })
  }

  findLinks() {
    document.querySelectorAll('[data-endless-action]:not([data-initialized])').forEach((link) => {
      link.dataset.initialized = 'true';
      this.links.push(link);
    });
  }
}

function initAutoPagination() {
  const autoPaginator = new AutoPagination();
}

onDomReady(initAutoPagination);