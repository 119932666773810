<template>
  <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12.5" cy="12" r="10" stroke="#FF891D" stroke-width="1.3"/><circle cx="12.5" cy="12" r="3.636" stroke="#FF891D" stroke-width="1.3"/><circle cx="12.5" cy="12" r="8.182" stroke="#FF891D" stroke-width="1.3"/><circle cx="12.5" cy="12" r="1.818" stroke="#FF891D" stroke-width="1.3"/><path d="M10.986 3.695c-.008.167-.011.337-.011.509 0 2.34.683 4.237 1.525 4.237.842 0 1.525-1.897 1.525-4.237 0-.172-.003-.342-.01-.509M14.014 20.306c.008-.167.011-.337.011-.509 0-2.34-.683-4.237-1.525-4.237-.842 0-1.525 1.897-1.525 4.237 0 .172.003.342.01.509M4.195 13.514c.167.008.337.011.509.011 2.34 0 4.237-.683 4.237-1.525 0-.842-1.897-1.525-4.237-1.525-.172 0-.342.003-.509.01M20.805 10.486a11.79 11.79 0 0 0-.509-.011c-2.34 0-4.237.683-4.237 1.525 0 .842 1.897 1.525 4.237 1.525.172 0 .342-.003.509-.01M5.557 7.198c.112.123.23.246.351.368 1.655 1.654 3.48 2.513 4.075 1.917.596-.596-.263-2.42-1.917-4.075-.122-.121-.245-.239-.368-.352M19.444 16.802c-.113-.123-.23-.246-.352-.368-1.655-1.654-3.48-2.513-4.075-1.917-.596.596.263 2.42 1.917 4.075.122.121.245.239.368.352M7.7 18.944c.122-.113.245-.23.367-.352 1.654-1.655 2.513-3.48 1.917-4.075-.596-.596-2.42.263-4.075 1.917-.121.122-.239.245-.352.368M17.303 5.057c-.123.112-.246.23-.368.351-1.654 1.655-2.513 3.48-1.917 4.075.595.596 2.42-.263 4.075-1.917.121-.122.239-.245.352-.368" stroke="#FF891D" stroke-width="1.3"/></svg>
</template>

<script>
export default {
  name: 'WheelIcon'
};
</script>

<style scoped>

</style>