import axios from 'axios';

const autoParamService = {
  async fetchManufacturers() {
    return axios.get('/car/manufacturers');
  },
  async fetchModels(manufactureId) {
    return axios.get(`/car/models/${manufactureId}`);
  },
  async fetchGenerations(modelId) {
    return axios.get(`/car/generations/${modelId}`);
  },
  async fetchModifications(generationId) {
    return axios.get(`/car/modifications/${generationId}`);
  },
  async fetchModificationsByModel(modelId) {
    return axios.get(`/car/modifications-by-model/${modelId}`);
  },
  async fetchSizes(type, modificationId, params) {
    return axios.post(`/car/sizes/${type}/${modificationId}`, params);
  },
  async fetchProducts(type, modificationId, params) {
    return axios.post(`/car/products/${type}/${modificationId}`, params);
  },
  async fetchPrevFilter(data) {
    const url = '/catalog/api/filter/fetch-prev';
    return axios.post(url, data);
  }
};

export default autoParamService;