<template>
  <div class="catalog-client-selector">
    <div class="custom-select custom-select_type_field-iconable"
         v-if="hasClients"
         :class="{_open: isOpen}"
         data-vue-custom-select
    >
      <div class="custom-select__wrapper">
        <label class="custom-select__iconable-field" @click="openable">
          <span class="custom-select__field-name">
            {{ clientName }}
          </span>
          <span class="custom-select__arrow-icon custom-select__arrow-icon_relative">
            <ArrowSmall/>
          </span>
        </label>
        <div class="custom-select__content-wrap custom-select__content-wrap_openable">
          <div class="custom-select__list">
            <div class="custom-select__item">
              <input
                  type="radio"
                  class="custom-select__input custom-select__input_radio"
                  :id="uniqueId()"
                  :value="null"
                  name="catalog-target-client"
                  @change="selectClient"
              >
              <label :for="uniqueId()" class="custom-select__label">
                <span class="custom-select__choice-name">
                  Выбрать клиента
                </span>
              </label>
            </div>

            <div class="custom-select__item" v-for="client in clients">
              <input
                  type="radio"
                  class="custom-select__input custom-select__input_radio"
                  name="catalog-target-client"
                  :id="uniqueId(client.value)"
                  :value="client.value"
                  @change="selectClient"
              >
              <label :for="uniqueId(client.value)" class="custom-select__label">
                <span class="custom-select__choice-name">
                  {{ client.name }}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowSmall from '../icons/ArrowSmall.vue';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TheCatalogClientSelector",
  components: {
    ArrowSmall
  },
  props: {

  },
  computed: {
    ...mapState('targetClient', {
      'targetClientsInfo': 'targetClientsInfo'
    }),
    ...mapGetters('targetClient', {
      'clients': 'clients',
      'currentClientId': 'currentClientId',
      'hasClients': 'hasClients',
    }),
    ...mapState('catalogFilter', {
      'catalogMode': 'mode'
    }),
    ...mapState('autoParam', {
      'autoExtend': 'extend'
    }),
    clientName() {
      const client = this.currentClientId ? this.clients.find((client) => client.value === this.currentClientId) : null;
      return client ? client.name : 'Выбрать клиента';
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    ...mapActions('targetClient', {
      'setClient': 'setClient',
      'setPurchaser': 'setPurchaser',
      'resetClient': 'resetClient',
      'resetPurchaser': 'resetPurchaser',
    }),
    ...mapActions('filterParam', {
      'paramResetFields': 'resetFields',
      'paramFetchPossibleFields': 'fetchPossibleFields',
      'paramUpdatePossibleFields': 'updatePossibleFields',
      'paramFetchProducts': 'fetchProducts',
    }),
    ...mapActions('autoParam', {
      'autoResetFields': 'resetFields',
      'autoFetchSizes': 'fetchSizes',
      'autoFetchProducts': 'fetchProducts',
    }),
    async selectClient(e) {
      const value = e.target.value;
      if (value) {
        await this.setClient({ clientId: e.target.value })
      } else {
        await this.resetClient();
      }
      this.isOpen = false;

      await this.paramResetFields();
      await this.paramUpdatePossibleFields();
      if (this.autoExtend) {
        await this.autoResetFields();
        await this.autoFetchSizes();
      }

      if (this.catalogMode === 'param') {
        await this.paramFetchProducts();

      } else if (this.catalogMode === 'auto' && this.autoExtend) {
        await this.autoFetchProducts();
      }
    },
    openable(e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
    },
    uniqueId(value) {
      value = value === undefined ? 'none' : value;
      return `catalog-client-selector_${value}`;
    },
  },
  mounted() {
    const instance = this;
    document.addEventListener('click', (e) => {
      const el = instance.$el.querySelector('[data-vue-custom-select]');
      if (instance.isOpen && !(el === e.target || el.contains(e.target))) {
        instance.isOpen = false;
      }
    });
  }
}
</script>
