import OrderService from '../../services/order.service';
import Modal from '../../../components/modal/modal';

const orderModule = {
  namespaced: true,
  state: {
    orderData: {
      comment: '',
      useBonuses: false,
      shipment: 'delivery',
      createAutoOrder: true,
      fourPointsContract: null,
    },
    errors: {},
    redirectBaseUrl: '/order/success',
  },
  actions: {
    async send({ state, dispatch, commit }) {
      try {
        const result = await OrderService.save(state.orderData);
        if (!result.data.errors.length) {
          dispatch('successHandle', result.data.order_id);
          dispatch('cart/fetchCheckout', {}, { root: true });
        } else {
          // TODO
        }
      } catch (error) {
        // TODO
      }
    },
    async successHandle({ state }, orderId) {
      try {
        const result = await OrderService.success(orderId);
        const element = document.createElement('div');
        element.innerHTML = result.data.querySelector('[data-success]').outerHTML;
        const modal = new Modal(element, {
          closerText: '',
          onAfterOpen: (modal) => {
            modal.content.querySelectorAll('._need-remove')
              .forEach((contentElement) => contentElement.classList.remove('_need-remove'));
          },
        });
      } catch (error) {
      // TODO
      }
    },
  },
  mutations: {
    setComment(state, comment) {
      state.orderData.comment = comment;
    },
  },
};

export default orderModule;