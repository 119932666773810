var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _vm.opened
      ? _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "popup__layout" }, [
            _c(
              "div",
              { staticClass: "popup__window" },
              [
                _vm.isProcessing ? _c("preloader") : _vm._e(),
                _vm._v(" "),
                _vm.canBeClosed
                  ? _c(
                      "a",
                      {
                        staticClass: "popup__close",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_c("CloserIcon")],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "popup__content" },
                  [_vm._t("default")],
                  2
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }