<template>
  <div class="catalog-list-header">
    <div
        class="catalog-list-header__column"
        v-for="column in columns"
        :class="column.key"
    >
      <BaseFieldSort
        v-if="column.sort"
        :fieldKey="column.key"
        :name="'catalog-sort'"
        :label="switchableLabel(column)"
        :current-value="currentOrder"
        v-model="currentOrder"
        @change="change"
      />
      <div
          v-else
          class="catalog-list-header__label"
      >
        {{ column.name }}
      </div>
    </div>

    <preloader v-if="productPreloader"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import BaseFieldSort from '../parts/fields/BaseFieldSort.vue';
import Preloader from "../parts/Preloader.vue";

export default {
  name: "TheCatalogListHeader",
  components: {
    Preloader,
    BaseFieldSort
  },
  computed: {
    ...mapState('catalogFilter', {
      'columns': 'columns',
      'currentOrder': 'currentOrder',
      'hidePrice': 'hidePrice',
      'productPreloader': 'productPreloader'
    }),
  },
  methods: {
    ...mapActions('catalogFilter', {
      'setOrder': 'setOrder'
    }),
    switchableLabel(column) {
      if (column.key === 'rrc') {
        return this.hidePrice ? column.name_alt : column.name;
      }

      return column.name;
    },
    change(order) {
      this.setOrder(order)
    },
  }
}
</script>
