import {
  Controller, Swiper, Autoplay, Pagination, EffectCreative,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';
import Runner from './runner';

onDomReady(() => {
  const containers = document.querySelectorAll('[data-hero-slider-container]');

  if (containers.length === 0) {
    return;
  }

  containers.forEach((container) => {
    if (container) {
      const imageSliderEl = container.querySelector('[data-hero-slider]');
      const textSliderEl = container.querySelector('[data-text-slider]');

      let textSlider;
      let imageSlider;

      const progress = container.querySelector('[data-hero-slider-progress]');
      const textProgress = container.querySelector('[data-hero-slider-text-progress]');

      if (imageSliderEl) {
        imageSlider = new Swiper(imageSliderEl, {
          modules: [Controller, Pagination, Autoplay, EffectCreative],
          slidesPerView: 1,
          speed: 3000,
          autoplay: {
            delay: 3500,
            disableOnInteraction: true,
          },
          pagination: {
            el: progress,
            type: 'bullets',
            clickable: true,
          },
          creativeEffect: {
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          },
        });
      }

      if (textSliderEl) {
        textSlider = new Swiper(textSliderEl, {
          modules: [Pagination],
          preventInteractionOnTransition: true,
          allowTouchMove: false,
          pagination: {
            el: textProgress,
            type: 'bullets',
            clickable: true,
          },
        });
      }

      const runnerElement = progress.parentNode.querySelector('[data-slider-runner]');
      const runner = runnerElement ? new Runner(runnerElement) : null;

      const textRunnerElement = textProgress.parentNode.querySelector('[data-slider-runner]');
      const textRunner = textRunnerElement ? new Runner(textRunnerElement) : null;

      imageSlider.on('slideChange', () => {
        textSlider.slideTo(imageSlider.realIndex, 1500);
        if (runner) {
          runner.translate(imageSlider.realIndex);
        }
        if (textRunner) {
          textRunner.translate(imageSlider.realIndex);
        }
      });
    }
  });
});