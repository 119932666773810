<template>
  <a href="/cart" target="_blank" class="header__button">
    <cart-gray-icon/>
    <span class="header__button-counter header__cart-counter" v-if="count">
        {{ count }}
    </span>
  </a>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CartGrayIcon from '../icons/CartGrayIcon.vue';

export default {
  name: "TheCartWidget",
  components: {
    CartGrayIcon
  },
  computed: {
    ...mapGetters('cart', {
      'count': 'countProducts',
    })
  },
  methods: {
    ...mapActions('cart', {
      'loadCheckout': 'loadCheckout',
    })
  },
  mounted() {
    this.loadCheckout();
  }
}
</script>
