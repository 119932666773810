class SorterItem {
  static pickingElement = null;

  constructor(itemElement) {
    this.element = itemElement;
    this.dragable = this.element.children[0];
    this.position = parseInt(itemElement.dataset.sorterPosition, 10);
    this.runner = itemElement.querySelector('[data-sorter-runner]');

    this.isBefore = false;
    this.isAfter = false;
    this.picking = false;
    this.newOffset = 0;
    this.oldCursorPosition = 0;

    this.init();
  }

  init() {
    this.element.dataset.sorterItem = '';
    this.bind();
  }

  bind() {
    this.bindDrag();
  }

  bindDrag() {
    this.runner.addEventListener('mousedown', (e) => {
      this.dragMouseDown(e);
    });
  }

  dragMouseDown(e) {
    SorterItem.pickingElement = this;

    this.setPicking();

    this.oldCursorPosition = e.clientY;

    document.addEventListener('mouseup', this.closeDragElement);
    document.addEventListener('mousemove', this.elementDrag);
  }

  elementDrag(e) {
    const item = SorterItem.pickingElement;
    const parent = item.element.parentElement;
    const newOffset = item.oldCursorPosition - e.clientY;
    const top = item.dragable.offsetTop - newOffset;

    if (item.element.offsetTop + top < parent.offsetTop) {
      return;
    }

    if (item.element.offsetTop + top + item.element.offsetHeight > parent.offsetTop + parent.offsetHeight) {
      return;
    }

    item.newOffset = newOffset;
    item.oldCursorPosition = e.clientY;
    item.dragable.style.top = `${top}px`;

    parent.dispatchEvent(new CustomEvent('ItemDrag'));
  }

  closeDragElement() {
    const item = SorterItem.pickingElement;
    const parent = item.element.parentElement;
    parent.dispatchEvent(new CustomEvent('ItemDrop'))
    // item.unsetPicking();
    document.removeEventListener('mousemove', item.elementDrag);
  }

  setPicking() {
    this.setRect();
    this.picking = true;
    this.element.classList.add('_picking');
  }

  unsetPicking() {
    this.element.classList.remove('_picking');
    this.picking = false;
    SorterItem.pickingElement = null;

   this.unsetRect();
  }

  setRect() {
    const rect = this.element.getBoundingClientRect();
    this.element.style.width = `${rect.width}px`;
    this.dragable.style.width = `${rect.width}px`;
    this.element.style.height = `${rect.height}px`;
    this.dragable.style.height = `${rect.height}px`;
  }

  unsetRect() {
    this.element.style.width = '';
    this.dragable.style.width = '';
    this.element.style.height = '';
    this.dragable.style.height = '';

    this.dragable.style.top = '';
  }

  getTop() {
    return this.element.offsetTop;
  }

  getHeight() {
    return this.element.offsetHeight;
  }

  getMiddle() {
    return this.getTop() + this.getHeight() / 2;
  }

  getBottom() {
    return this.getTop() + this.getHeight();
  }

  setAfter() {
    this.element.classList.add('_picking-after');
    this.isAfter = true;
  }

  setBefore() {
    this.element.classList.add('_picking-before');
    this.isBefore = true;
  }

  unsetAfter() {
    this.element.classList.remove('_picking-after');
    this.isAfter = false;
  }

  unsetBefore() {
    this.element.classList.remove('_picking-before');
    this.isBefore = false;
  }

  unsetBeforeAfter() {
    this.unsetAfter();
    this.unsetBefore();
  }

  setPosition(position) {
    this.position = position;
    this.element.dataset.sorterPosition = position;
  }
}

export default SorterItem;