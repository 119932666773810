import axios from 'axios';

const StatService = {
  async fetchTotalProductsByCategories(params) {
    const url = '/stat/api/total_products_by_categories';
    return axios.get(url, { params });
  },
  async fetchProductsByCategories(params) {
    const url = '/stat/api/products_by_categories';
    return axios.get(url, { params });
  },
  async fetchOffersByCategories(params) {
    const url = '/stat/api/offers_by_categories';
    return axios.get(url, { params });
  },
  async fetchTotalOffersByCategories(params) {
    const url = '/stat/api/total_offers_by_categories';
    return axios.get(url, { params });
  },
  async fetchTotalProvidersByCategories(params) {
    const url = '/stat/api/total_providers_by_categories';
    return axios.get(url, { params });
  },
  async fetchTotalStoragesByCategories(params) {
    const url = '/stat/api/total_storages_by_categories';
    return axios.get(url, { params });
  },
  async fetchOffersByStorages(type, params) {
    const url = `/stat/api/offers_by_storages/${type}`;
    return axios.get(url, { params });
  },
  async fetchProductsByStorages(type, params) {
    const url = `/stat/api/products_by_storages/${type}`;
    return axios.get(url, { params });
  },
  async fetchOffersByProviders(type, params) {
    const url = `/stat/api/offers_by_providers/${type}`;
    return axios.get(url, { params });
  },
  async fetchProductsByProviders(type, params) {
    const url = `/stat/api/products_by_providers/${type}`;
    return axios.get(url, { params });
  },
  async fetchDurations(params) {
    const url = '/stat/api/get_durations';
    return axios.get(url, { params });
  },
};

export default StatService;