var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3M4 7h16H4Zm6 4v6-6Zm4 0v6-6ZM5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12H5Z",
          stroke: "#CAC9CE",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }