<template>
    <div class="tree-nodes">
        <div class="tree-nodes__list">
            <TreeNode
                v-for="(node, key) in nodes"
                :node="node"
                :key="key"
                :index="key"
                :treeId="treeId"
                :parent-node="parentNode"
                :parent-nodes="parentNodes"
                @prepend="prependNode"
                @remove="removeNode"
                @split="splitNode"
                v-slot="slotProps">
                <slot :node="slotProps.node"></slot>
            </TreeNode>
        </div>

        <a href="javascript:void(0)" class="tree-nodes__append" title="Добавить ветку" @click.prevent="appendNode">
            <span class="tree-node__icon-wrap">
                ➕
            </span>
        </a>
    </div>
</template>

<script>
import TreeNode from "./TreeNode.vue";
import tree from "../../../mixins/tree";

export default {
  name: "TreeNodes",
  components: {
    TreeNode,
  },
  props: {
    treeId: String,
    nodes: Array,
    parentNode: Object,
    parentNodes: Array,
  },
  mixins: [
    tree
  ],
  methods: {
    buildNode() {
      return {
        conditions: {
          subConditionsOperator: null,
          subConditions: [],
          treeCondition: null
        },
        children: null,
        content: null
      }
    },
    prependNode(key) {
      this.nodes.splice(key, 0, this.buildNode());
    },
    appendNode(key) {
      this.nodes.push(this.buildNode());
    },
    removeNode(key) {
      this.nodes.splice(key, 1);
      // if (this.nodes.length === 1) {
      //   this.$emit('last', this.nodes[0]);
      // }
    },
    splitNode(key) {
      const node = this.nodes[key];
      this.$set(node, 'children', []);
      node.children.push(this.buildNode());
    }
  }
}
</script>

<style lang="scss">

</style>