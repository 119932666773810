<template>
  <div class="catalog-filter-panel" :class="{'_param': isParamFilter, '_auto': isAutoFilter}">
    <div class="catalog-filter-panel__opener-wrap">
      <plus-button
          v-if="isParamFilter"
          class="catalog-filter-panel__opener"
          :text="'Расширенный фильтр'"
          :text-alt="'Короткий фильтр'"
          :is-open="paramExtend"
          @click="handleExpand"
      />
    </div>

    <div class="catalog-filter-panel__total" v-if="showTotal">
      <div class="catalog-filter-panel__total-preloader" v-if="totalPreloader">
        <preloader/>
      </div>
      <div class="catalog-filter-panel__total-inner" v-else>
        <div class="catalog-filter-panel__total-inner-text" v-if="totalProducts">
          Найдено {{ totalProductsShow }} товаров
        </div>
        <div class="catalog-filter-panel__total-inner-text" v-else>
          Не найдено товаров
        </div>
      </div>
    </div>

    <the-filter-collection/>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import TheFilterCollection from './TheFilterCollection.vue';
import Preloader from '../parts/Preloader.vue';
import PlusButton from '../parts/plus-button.vue';

export default {
  name: "TheFilterPanel",
  components: {
    TheFilterCollection,
    Preloader,
    PlusButton
  },
  computed: {
    ...mapState('catalogFilter', {
      'totalProducts': 'totalProducts',
      'totalPreloader': 'totalPreloader'
    }),
    ...mapState('filterParam', {
      'paramExtend': 'extend',
      'enableSecondAxis': 'enableSecondAxis',
    }),
    ...mapState('autoParam', {
      'autoExtend': 'extend',
    }),
    ...mapGetters('catalogFilter', {
      'isParamFilter': 'isParamFilter',
      'isAutoFilter': 'isAutoFilter',
    }),
    showTotal() {
      return this.isParamFilter || this.isAutoFilter && this.autoExtend;
    },
    totalProductsShow() {
      return this.enableSecondAxis ? `≈ ${this.totalProducts}`: this.totalProducts;
    }
  },
  methods: {
    ...mapActions('filterParam', {
      'switchExtend': 'switchExtend'
    }),
    ...mapMutations({
      'setExtend': 'filterParam/setExtend'
    }),
    handleExpand(e) {
      this.switchExtend();
    },
  }
}
</script>