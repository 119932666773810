function getLastMonthRange() {
  const date = new Date();
  const ty = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const tm = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const td = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  date.setMonth(date.getMonth() - 1);
  const fy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const fm = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const fd = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  return {
    from: `${fy}-${fm}-${fd}`,
    to: `${ty}-${tm}-${td}`,
  };
}

function getLastDay() {
  const date = new Date();
  const ty = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const tm = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const td = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  date.setMonth(date.getDay() - 1);
  const fy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const fm = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const fd = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  return {
    from: `${fy}-${fm}-${fd}`,
    to: `${ty}-${tm}-${td}`,
  };
}

export default getLastMonthRange;
export {
  getLastDay,
}