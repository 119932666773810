<template>
  <form class="cart-manager-form">
    <h3 class="cart-manager-form__title">
      Автозаказ
    </h3>

    <div class="cart-manager-form__fields">
      <div class="cart-manager-form__field">
        <base-field-switch
            :label="'Создавать автоматический заказ'"
            :input-id="'create_auto_order_switch'"
            :current-value="orderData.createAutoOrder"
            v-model="orderData.createAutoOrder"
        />
      </div>

      <div class="cart-manager-form__field">
        <base-field-select
            :field-id="'order_shipment_select'"
            :values="shipments"
            :current-value="orderData.shipment"
            v-model="orderData.shipment"
            :no-default="true"
            :label="'Способ доставки'"
            v-if="orderData.createAutoOrder"
        />
      </div>

      <div class="cart-manager-form__field">
        <base-field-switch
            :label="'Использовать бонусы (при возможности)'"
            :input-id="'order_use_bonuses_switch'"
            :current-value="orderData.useBonuses"
            v-model="orderData.useBonuses"
            v-if="orderData.createAutoOrder"
        />
      </div>


      <div class="cart-manager-form__field">
        <base-field-select
            :field-id="'four_points_contract_select'"
            :values="fourPointsContracts"
            :current-value="orderData.fourPointsContract"
            v-model="orderData.fourPointsContract"
            :label="'Договор для 4 точек'"
            :default-value="{name: 'По умолчанию', value: null}"
            v-if="orderData.createAutoOrder && isForInternalManager"
        />
      </div>
    </div>
  </form>
</template>

<script>
import BaseFieldSwitch from "../parts/fields/BaseFieldSwitch.vue";
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "TheCartManagerForm",
  components: {BaseFieldSelect, BaseFieldSwitch},
  data: () => {
    return {
      shipments: [
        {
          'name': 'Доставка',
          'value': 'delivery'
        },
        {
          'name': 'Самовывоз',
          'value': 'pickup'
        },
        {
          'name': 'Транспортная компания',
          'value': 'tk'
        },
      ],
      shipment: 'delivery',
      fourPointsContracts: [
        {
          'name': 'Договор 176 от 19.08.2016',
          'value': '954'
        },
        {
          'name': 'ДС № 11 к дог. 176 от 05.12.2022',
          'value': '22383'
        },
      ],
    }
  },
  computed: {
    ...mapState('order', {
      'orderData': 'orderData'
    }),
    ...mapGetters('cart', {
      'isForInternalManager': 'isForInternalManager'
    })
  },
  methods: {
  }
}
</script>
