<template>
  <div class="stat-page__inner-content">
    <a href="javascript:void(0);" class="stat-page__back-link" @click="setCurrentClient(null)">
      <ArrowLongLeft class="stat-page__back-link-icon"/>
      Вернуться к списку клиентов
    </a>

    <div class="stat-page__row">
      <the-stat-client-actions
          class="stat-page__large-block"
          :actions="statData.client_actions"
          :is-loaded="isLoadeds.client_actions"
          :total="statData.total_client_actions"
          @update="(params) => update('client_actions', params)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ArrowLongLeft from "../icons/ArrowLongLeft.vue";
import TheStatClientActions from "./TheStatClientActions.vue";

export default {
  name: "TheStatClientInstance",
  components: {
    ArrowLongLeft,
    TheStatClientActions
  },
  data() {
    return {
      isLoadeds: {
        client_actions: false
      }
    }
  },
  computed: {
    ...mapState('statClient', {
      'statData': 'statData'
    })
  },
  methods: {
    ...mapActions('statClient', {
      'setCurrentClient': 'setCurrentClient',
      'updateStatData': 'updateStatData'
    }),
    async update(statDataKey, params) {
      this.isLoadeds[statDataKey] = true;
      await this.updateStatData({ statDataKey, params });
      this.isLoadeds[statDataKey] = false;
    }
  },

}
</script>
