var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree-node" }, [
    _c(
      "a",
      {
        staticClass: "tree-node__append-before",
        attrs: { href: "javascript:void(0)", title: "Добавить ветку" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.prepend.apply(null, arguments)
          },
        },
      },
      [
        _c("span", { staticClass: "tree-node__icon-wrap" }, [
          _vm._v("\n            ➕\n        "),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tree-node__head" }, [
      _c("div", { staticClass: "tree-node__title" }, [
        _vm._v("\n            Если\n        "),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "tree-node__remove-branch",
          attrs: { href: "javascript:void(0)", title: "Удалить ветку" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.remove.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n            ×\n        ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tree-node__conditions" },
      [
        _c("TreeConditionSet", {
          attrs: {
            treeId: _vm.treeId,
            conditions: _vm.node.conditions,
            rootConditionSet: true,
            "parent-nodes": _vm.parentNodes,
          },
        }),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.node.comment,
              expression: "node.comment",
            },
          ],
          staticClass: "tree-node__comment",
          domProps: { value: _vm.node.comment },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.node, "comment", $event.target.value)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.hasChildren
      ? _c(
          "div",
          { staticClass: "tree-node__content" },
          [_vm._t("default", null, { node: _vm.node })],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasChildren
      ? _c("div", { staticClass: "tree-node__create-sub-branch" }, [
          _c(
            "a",
            {
              staticClass: "tree-node__sub-branch-button",
              attrs: { href: "javascript:void();", title: "Разбить ветку" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.split.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "tree-node__icon-wrap" }, [
                _vm._v("\n                ➕\n            "),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasChildren
      ? _c(
          "div",
          { staticClass: "tree-node__sub" },
          [
            _c("TreeNodes", {
              attrs: {
                treeId: _vm.treeId,
                nodes: _vm.node.children,
                parentNode: _vm.thisNodeIfItsParent,
                "parent-nodes": _vm.createParentNodes,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (slotProps) {
                      return [_vm._t("default", null, { node: slotProps.node })]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }