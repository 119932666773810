var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-price-move" }, [
    _c(
      "ul",
      { staticClass: "stat-price-move__tabs tabs tabs_black" },
      _vm._l(_vm.tabs, function (tab, tabKey) {
        return _c(
          "li",
          {
            staticClass: "tabs__item",
            class: { _active: _vm.currentTab === tabKey },
          },
          [
            _c(
              "a",
              {
                staticClass: "tabs__link",
                attrs: { href: "javascript:void(0);" },
                on: {
                  click: function ($event) {
                    return _vm.setCurrentTab(tabKey)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(tab) + "\n      ")]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-price-move__tab-contents" },
      [
        _c("price-move-graph", {
          class: { _active: _vm.currentTab === "wholesale" },
          attrs: {
            title: "Движения закупочных цен",
            "graph-key": "provider_prices_moves_graph",
            product: _vm.productData,
          },
        }),
        _vm._v(" "),
        _c("price-move-graph", {
          class: { _active: _vm.currentTab === "clientMargin" },
          attrs: {
            title: "Средняя наценка до клиента",
            "graph-key": "client_margins_graph",
            product: _vm.productData,
          },
        }),
        _vm._v(" "),
        _c("price-move-graph", {
          class: { _active: _vm.currentTab === "mrcMargin" },
          attrs: {
            title: "Наценка до МРЦ",
            "graph-key": "mrc_margins_graph",
            product: _vm.productData,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }