<template>
  <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12.5" cy="12" r="9.25" stroke="#FF891D" stroke-width="1.5"/><circle cx="12.5" cy="12" r="4.767" stroke="#FF891D" stroke-width="1.5"/>
    <path d="M12.5 2.345v1.724M12.5 19.93v1.725M22.154 12H20.43M4.568 12H2.844M19.326 5.173l-1.219 1.219M6.89 17.608l-1.219 1.22M17.326 3.639l-.862 1.493M8.533 18.868l-.862 1.493M15 2.674l-.446 1.665M10.45 19.661l-.447 1.665M10 2.674l.446 1.665M14.553 19.661l.446 1.665M7.674 3.638l.862 1.493M16.467 18.868l.862 1.493M5.674 5.173l1.219 1.219M18.11 17.607l1.218 1.22M4.139 7.172l1.493.862M19.37 15.965l1.492.862M3.174 9.501l1.665.446M20.16 14.053l1.666.446M3.174 14.499l1.665-.446M20.16 9.947l1.666-.446M3.174 14.499l1.665-.446M20.16 9.947l1.666-.446M4.139 16.828l1.493-.862M19.367 8.035l1.493-.862" stroke="#FF891D" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'TyreIcon'
};
</script>

<style scoped>

</style>