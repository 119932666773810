<template>
  <div class="checkbox-list">
    <div class="checkbox-list__group-switch">
      <BaseFieldSwitch
          v-if="managerLoaded"
          :input-id="uniqueId(inputId, 'switcher')"
          v-model="groupByProvider"
          :label="'Сгруппировать по поставщикам'"
          @change="onSwitch"
      />
    </div>

    <div class="checkbox-list__group">
      <div class="checkbox-list__group-value" v-if="checkAll">
        <input
            class="checkbox-list__input"
            type="checkbox"
            :value="defaultValue.value"
            :id="uniqueId(inputId, 'all')"
            :name="uniqueId(inputId, 'all')"
            @change="changeAll"
        >
        <label class="checkbox-list__label" :for="uniqueId(inputId, 'all')">
          {{ defaultValue.name }}
        </label>
      </div>
    </div>


    <div v-for="group in groupedValues" class="checkbox-list__group">
      <label class="checkbox-list__group-name">
        {{ group.name }}
      </label>

      <div class="checkbox-list__group-values">
        <div v-for="value in group.values" class="checkbox-list__group-value">
          <input type="checkbox"
                 class="checkbox-list__item"
                 :id="uniqueId(inputId, value.value)"
                 :value="value.value"
                 :disabled="checkDisabled(value.value)"
                 :checked="shouldBeChecked(value.value)"
                 @change="updateInput"
                 :ref="'checkbox'"
          />
          <label class="checkbox-list__label" :for="uniqueId(inputId, value.value)">
            {{ value.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseFieldSwitch from './BaseFieldSwitch.vue';
import {mapState} from "vuex";

export default {
  name: "StorageCheckboxList",
  components: {
    BaseFieldSwitch
  },
  props: {
    /*
      Все возможные значения для чекбоксов
      - список объектов с полями 'name' и 'value'. Может еще icon
    */
    possibleValues: {
      required: true
    },
    // Объект для v-model
    currentValues: {
      required: true
    },
    // Склеивается со значениями инпутов
    inputId: {
      type: String,
      required: true
    },
    // Список значений на дисейбл (строки)
    disabledValues: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    checkAll: {
      type: Boolean,
      default: false,
      required: false
    },
    defaultValue: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: 'Выбрать все',
          value: null
        }
      }
    },
  },
  data() {
    return {
      groupByProvider: false,
    };
  },
  computed: {
    ...mapState('targetClient', {
      'managerLoaded': 'loaded'
    }),
    groupedValues() {
      const result = [];
      this.possibleValues
          .sort((a, b) => {
            if (a.delivery === null) {
              return -1;
            }
            if (b.delivery === null) {
              return 1;
            }

            if (a.delivery === b.delivery) {
              return 0;
            }

            return a.delivery > b.delivery ? 1: -1
          })
          .forEach((value) => {
            const groupedParam = this.getGroupedParam(value);
            let current = result.find((item) => item.name === groupedParam);
            if (current) {
              current.values.push(value);
            } else {
              current = {
                name: groupedParam,
                values: [value]
              }
              result.push(current)
            }
          });

      return result;
    },
  },
  methods: {
    getGroupedParam(value) {
      if (this.groupByProvider) {
        return value.provider;
      }
      const param = value.delivery;
      if (param === null) {
        return 'Самовывоз'
      } else if (param === 0) {
        return 'Доставка сегодня';
      } else {
        return `Доставка ${param} д.`;
      }
    },
    uniqueId(inputId, value) {
      return `${inputId}_${value}`;
    },
    // Проверяем есть ли значение в текущих значениях
    shouldBeChecked(value) {
      return this.currentValues ? this.currentValues.includes(value) : false;
    },
    checkDisabled(value) {
      return this.disabledValues ? this.disabledValues.includes(value) : false;
    },
    updateInput(event) {
      const target = event.target;
      // Если инпут стал активным
      if (target.checked) {
        // Добавляем значение в текущие значения
        this.currentValues.push(target.value)
      } else {
        // Иначе убираем от туда
        this.currentValues.splice(this.currentValues.indexOf(target.value), 1)
      }
      // Даем родителю знать, что что-то в списке поменялось
      this.$emit('change', event);
    },
    onSwitch() {
      const switchElement = document.querySelector(`#${this.uniqueId(this.inputId, 'switcher')}`);
      if (switchElement) {
        const accordionElement = switchElement.closest('[data-accordion-element]');
        setTimeout(() => {
          accordionElement.dispatchEvent(new CustomEvent('checkHeight'))
        }, 300);
      }
    },
    changeAll(event) {
      const target = event.target;
      target.checked ? this.all() : this.clear();
      this.$emit('change', event);
    },
    all() {
      this.possibleValues.forEach((item) => {
        const inCurrent = this.currentValues.includes(item.value);
        const isDisable = this.disabledValues.includes(item.value);
        // const isHidden = this.isHidden(item);
        if (!inCurrent && !isDisable) {
          this.currentValues.push(item.value);
        }
      });
    },
    clear() {
      const currentValues = Array.from(this.currentValues);
      currentValues.forEach((value) => {
        // if (!this.hiddenValues.includes(value)) {
          this.currentValues.splice(this.currentValues.indexOf(value), 1);
        // }
      });
    },
  }
}
</script>