<template>
    <div class="tree-condition" v-if="condition">
      <v-select
          class="tree-condition__select"
          v-model="condition.fieldName"
          :reduce="(option) => option.value"
          :options="nameFieldsSelectItems"
          :clearable="false"
          :searchable="false"
          @input="changeNameField"
      ></v-select>

      <v-select
          class="tree-condition__select"
          v-model="condition.condition"
          :reduce="(option) => option.value"
          :options="allowedConditionsFieldsSelectItems"
          :clearable="false"
          :searchable="false"
          @input="changeAllowedConditionsField"
      ></v-select>

      <template v-if="!this.isChanging">
          <input v-if="isScalarValue(this.condition.condition)"
                 v-model="condition.value"
                 class="tree-condition__value-input"
                 type="text"
                 autocomplete="off"
          >

          <div class="tree-condition__search-wrap" v-else>
            <v-select
                class="tree-condition__value-block"
                v-model="condition.value"
                :reduce="(option) => option.id"
                label="name"
                :options="fieldValues"
                :searchable="isLiveSearch"
                @search="debouncedSearch"
                @search:focus="debouncedSearch"
                :noDrop="!fieldValues.length"
                multiple
            ></v-select>
            <div class="lds-ring" v-if="isSearching"><div></div><div></div><div></div><div></div></div>
          </div>
      </template>

      <div class="tree-condition__actions">
        <a href="javascript:void(0)"
           class="tree-condition__split-branch"
           @click.prevent="split"
        >
          <SplitIcon class="tree-condition__split-icon"/>
        </a>

        <a v-if="allowRemove"
           href="javascript:void(0)"
           class="tree-condition__remove-branch"
           @click.prevent="remove"
        >
          &times;
        </a>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import tree from "../../../mixins/tree";
import SplitIcon from "../../icons/SplitIcon.vue";
import {mapActions} from "vuex";
import debounce from "lodash.debounce";

Vue.component('v-select', vSelect)

export default {
  name: "TreeCondition",
  components: {SplitIcon},
  mixins: [
    tree,
  ],
  props: {
    treeId: String,
    condition: Object,
    allowRemove: Boolean,
    parentNodes: Array,
  },
  data() {
    return {
      allowedConditionsSelectItems: [
        {label: 'Входит в (один из)', value: 'in'},
        {label: 'Равно', value: 'eq'},
        {label: 'Не равно', value: 'neq'},
        {label: 'Содержит', value: 'like'},
        {label: 'Меньше', value: 'lt'},
        {label: 'Меньше либо равно', value: 'lte'},
        {label: 'Больше', value: 'gt'},
        {label: 'Больше либо равно', value: 'gte'},
      ],
      isSearching: false,
      searchString: '',
      isChanging: false,
      fieldValues: []
    }
  },
  computed: {
    allowedConditionsByFieldName() {
      return this.treeGetFields().find(field => field.name === this.condition.fieldName).allowedConditions;
    },
    allowedConditionsFieldsSelectItems() {
      return this.allowedConditionsSelectItems.filter((item) => {
        if (this.allowedConditionsByFieldName.includes(item.value)) {
          return item;
        }
      });
    },
    nameFieldsSelectItems() {
      return this.treeGetFields().map((field) => {
        return {
          label: field.title,
          value: field.name,
        }
      })
    },
    isLiveSearch() {
      return this.treeGetFieldMetadata(this.condition.fieldName).hasLiveSearch;
    },
    getInitValues() {
      return this.treeGetFields().find(field => field.name === this.condition.fieldName).initValues;
    },
    hasFieldOptions() {
      return this.condition.preloadedValues && this.condition.preloadedValues.length > 0;
    },
  },
  methods: {
    ...mapActions('tree', [
        'search',
    ]),
    split() {
      this.$emit('split');
    },
    remove() {
      this.$emit('remove');
    },
    getInitFieldValues() {
      if (!this.isLiveSearch && this.getInitValues) {
        return this.getInitValues;
      }
      let newPreloadedValues = this.condition.preloadedValues ? this.condition.preloadedValues : [];
      if (this.getPreloadedFieldValues(this.condition.fieldName)) {
        return this.uniqueValues(newPreloadedValues.concat(this.getPreloadedFieldValues(this.condition.fieldName)))
      }
      return newPreloadedValues;
    },
    changeNameField() {
      this.isChanging = true;
      this.handlePreloadValues();
      this.$set(this.condition, 'condition', this.allowedConditionsFieldsSelectItems[0].value);
      this.changeConditionValueTypeByValue();
      this.isChanging = false;
    },
    handlePreloadValues() {
      this.fieldValues.splice(0, this.fieldValues.length);
      const initFieldValues = this.getInitFieldValues();
      this.fieldValues.push(...initFieldValues);
    },
    changeAllowedConditionsField(e) {
      this.changeConditionValueTypeByValue();
    },
    changeConditionValueTypeByValue() {
      if (this.isScalarValue(this.condition.condition)) {
        this.$set(this.condition, 'value', '');
      } else {
        this.$set(this.condition, 'value', []);
      }
    },
    searchByValueField() {
      if (this.isLiveSearch) {
        this.isSearching = true;
        this.search({
          id: this.treeId,
          fieldName: this.condition.fieldName,
          query: this.searchString,
          parentNodes: this.parentNodes
        }).then((r) => {
          let newData = [];
          if (this.getValueFieldOptionsIncludesConditionValue().length > 0) {
            newData = this.uniqueValues(
              this.getValueFieldOptionsIncludesConditionValue().concat(r)
            );
          } else {
            newData = r;
          }
          this.fieldValues.splice(0, this.fieldValues.length);
          this.fieldValues.push(...newData);
          this.sortValuesById(this.fieldValues);
          this.isSearching = false;
        });
      }
    },
    debouncedSearch: debounce(function (value) {
      if (!this.searchString && value !== undefined) {
        this.searchString = value;
      } else if (this.searchString && value !== undefined) {
        this.searchString = value;
      } else if (!this.searchString && value === undefined) {
        this.searchString = '';
      }
      this.searchByValueField();
    }, 500),
    uniqueValues(array) {
      const uniqueIds = [];

      return array.filter(element => {
        const isDuplicate = uniqueIds.includes(element.id);
        if (!isDuplicate) {
          uniqueIds.push(element.id);
          return true;
        }
        return false;
      });
    },
    sortValuesById(array) {
      return array.sort((a, b) => {
        return (a.id - b.id);
      })
    },
    getValueFieldOptionsIncludesConditionValue() {
      const currentChosenValues = [];
      this.fieldValues.forEach((valueFieldOption) => {
        if (this.condition.value.includes(valueFieldOption.id)) {
          currentChosenValues.push(valueFieldOption);
        }
      });
      return currentChosenValues;
    },
  },
  mounted() {
    this.handlePreloadValues();
  }
}
</script>

<style lang="scss">

</style>