var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "cart-widget-count" }, [
    _vm.count
      ? _c("span", { staticClass: "cart-widget-count__count" }, [
          _vm._v("\n    " + _vm._s(_vm.count) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }