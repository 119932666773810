<template>
  <div class="stat-table _clients">
    <div class="stat-table__title" v-if="name">
      {{ name }}
    </div>
    <form class="stat-table__form">
      <base-field-date
          :label="'От'"
          :input-id="getFieldId('from')"
          @change="update"
          class="stat-table__field"
          v-model="formData.dateFrom"
      />

      <base-field-date
          :label="'До'"
          :input-id="getFieldId('to')"
          @change="update"
          class="stat-table__field"
          v-model="formData.dateTo"
      />

      <base-field-select-multi
          :values="availCategories"
          :field-id="getFieldId('category')"
          :current-values="formData.categories"
          :label="'Категория'"
          @input="(e) => update()"
      />
    </form>

    <div class="stat-table__content">
      <div class="stat-table__headers">
        <div class="stat-table__header-item _product">
          Товар
        </div>
        <div class="stat-table__header-item _count">
          Количество
        </div>
      </div>
      <ul class="stat-table__rows">
        <li class="stat-table__row" v-for="row in rows">
          <a :href="row.url" class="stat-table__link" data-modal>
            {{ row.name }}
          </a>
          <div class="stat-table__count">
            {{ row.count }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseFieldDate from "../../parts/fields/BaseFieldDate.vue";
import BaseFieldSelectMulti from "../../parts/fields/BaseFieldSelectMulti.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "ProductCountTable",
  components: {
    BaseFieldDate,
    BaseFieldSelectMulti
  },
  props: {
    name: {
      type: String,
      required: false
    },
    tableKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formData: {
        dateFrom: null,
        dateTo: null,
        categories: [],
      },

      isLoaded: false,

      rows: [],
      availCategories: [
        {
          value: '10',
          name: 'Легковые шины'
        },
        {
          value: '20',
          name: 'Грузовые шины'
        },
        {
          value: '30',
          name: 'Мото шины'
        },
        {
          value: '40',
          name: 'Легковые диски'
        },
        {
          value: '50',
          name: 'Грузовые диски'
        },
      ]
    }
  },
  methods: {
    ...mapActions('stat', {
      'loadStatData': 'loadStatData'
    }),
    async update() {
      this.isLoaded = true;
      this.rows = await this.loadStatData({
        statDataKey: this.tableKey,
        params: this.formData
      });
      this.isLoaded = false;
    },
    getFieldId(name) {
      return `${this.tableKey}__${name}`;
    },
  },
  mounted() {
    this.update();
  }
}
</script>
