<template>
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_243_3955)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99177 1.99177L5 7L1.09837e-06 2L1.42824 0.571763L4.99765 4.14118L8.56119 0.56589L9.99177 1.99177Z" fill="#CAC9CE"/>
    </g>
    <defs>
      <clipPath id="clip0_243_3955">
        <rect width="10" height="7" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "ArrowSmall"
}
</script>