<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 14L10 3M10 14L6 10M10 14L14 10M2 14L2 17L18 17L18 14" stroke="#FF7A00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon"
}
</script>