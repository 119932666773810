export default function legendCallback(chart) {
  const text = [];
  text.push('<ul class="chart-legend">');
  for (let i = 0; i < chart.data.datasets.length; i++) {
    const meta = chart.getDatasetMeta(i)
    const iconStyle = `background-color:${chart.data.datasets[i].borderColor}; border: 1px solid ${chart.data.datasets[i].borderColor};`;
    const itemClass = meta.hidden ? '_hidden' : '';
    const event = `new CustomEvent('legend-click', { 'detail': { 'index': ${i}, 'chartID': ${chart.id} } })`;
    text.push(`<li class="chart-legend__item ${itemClass}" onclick="document.dispatchEvent(${event});">`);
    text.push(`<span style="${iconStyle}" class="chart-legend__icon"></span> <span>${chart.data.datasets[i].label}</span>`);
    text.push('</li>');
  }
  text.push('</ul>');
  return text.join('');
};