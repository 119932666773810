import axios from 'axios';
import SorterItem from './sorter-item';

class Sorter {
  constructor(list) {
    this.listElement = list;
    this.items = [];
    this.sortUrl = list.dataset.sorterUrl;
    this.modelClass = list.dataset.sorterClass;
    this.init();
  }

  init() {
    this.prepare();
    this.bind();
  }

  prepare() {
    Array.from(this.listElement.children).forEach((item) => {
      this.items.push(new SorterItem(item));
    });
  }

  bind() {
    this.bindList();
  }

  bindList() {
    this.listElement.addEventListener('ItemDrag', (e) => {
      this.handleNear();
    });

    this.listElement.addEventListener('ItemDrop', (e) => {
      this.handleDrop();
    });
  }

  handleDrop() {
    const droped = SorterItem.pickingElement;
    const afterElement = this.items.find((item) => item.isAfter === true);
    const beforeElement = this.items.find((item) => item.isBefore === true);
    if (afterElement) {
      this.insertBefore(afterElement);
    } else if (beforeElement) {
      this.insertAfter(beforeElement);
    }

    this.items.filter((item) => item.isAfter || item.isBefore).forEach((item) => item.unsetBeforeAfter());
    droped.unsetPicking();
  }

  insertAfter(beforeElement) {
    const droped = SorterItem.pickingElement;
    beforeElement.element.after(droped.element);
    this.updatePositionBefore(droped, beforeElement);
  }

  insertBefore(afterElement) {
    const droped = SorterItem.pickingElement;
    afterElement.element.before(droped.element);
    this.updatePositionAfter(droped, afterElement);
  }

  updatePositionAfter(droped, afterElement) {
    const newPosition = afterElement.position;
    const oldPosition = droped.position;

    this.items
      .filter((item) => item.position >= afterElement.position && item.position < droped.position)
      .forEach((item) => item.setPosition(item.position += 1));
    droped.setPosition(newPosition);

    this.request('after', oldPosition, newPosition);
  }

  updatePositionBefore(droped, beforeElement) {
    const newPosition = beforeElement.position;
    const oldPosition = droped.position;

    this.items
      .filter((item) => item.position <= beforeElement.position && item.position > droped.position)
      .forEach((item) => item.setPosition(item.position -= 1));
    droped.setPosition(newPosition);

    this.request('before', oldPosition, newPosition);
  }

  handleNear() {
    const picking = SorterItem.pickingElement;
    const offset = picking.getTop() + picking.dragable.offsetTop;
    const pickingBottom = offset + picking.getHeight();

    let afterItem = null;
    let beforeItem = null;

    if (offset < picking.getTop()) {
      afterItem = this.findAfter(offset);
      if (afterItem && afterItem !== picking) {
        afterItem.setAfter();
      }
    } else {
      beforeItem = this.findBefore(pickingBottom);
      if (beforeItem && beforeItem !== picking) {
        beforeItem.setBefore();
      }
    }

    this.items.filter((item) => item !== afterItem && item !== beforeItem)
      .forEach((item) => item.unsetBeforeAfter());
  }

  findAfter(pickingTop) {
    return this.items.find((item) => item.getBottom() > pickingTop && item.getTop() <= pickingTop);
  }

  findBefore(pickingBottom) {
    return this.items
      .find((item) => item.getTop() < pickingBottom && item.getBottom() >= pickingBottom);
  }

  async request(type, sourcePosition, targetPosition) {
    if (this.sortUrl && this.modelClass) {
      return axios.post(this.sortUrl, {
        model_class: this.modelClass,
        insert_type: type,
        source_position: sourcePosition,
        target_position: targetPosition,
      });
    }
  }
}

export default Sorter;