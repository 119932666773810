var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-quantity-panel" }, [
    _c(
      "button",
      {
        staticClass:
          "cart-quantity-panel__button cart-quantity-panel__button_dec",
        attrs: { type: "button" },
        on: { click: _vm.decrease },
      },
      [_c("minus-icon")],
      1
    ),
    _vm._v(" "),
    _c("input", {
      staticClass: "cart-quantity-panel__input",
      attrs: {
        type: "text",
        autocomplete: "off",
        placeholder: _vm.renderPlaceholder,
      },
      domProps: { value: _vm.renderQuantity },
      on: { input: _vm.inputQuantity },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass:
          "cart-quantity-panel__button cart-quantity-panel__button_inc",
        attrs: { type: "button" },
        on: { click: _vm.increase },
      },
      [_c("plus-icon")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }