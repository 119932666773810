<template>
  <div class="vue-field vue-field_select">
    <label v-if="label" :for="fieldId">
      {{ label }}
    </label>
    <div data-vue-custom-select class="custom-select custom-select_type_single"
         :class="{
            _open: isOpen,
            _disabled: disabled,
            'custom-select_type_filterable': hasFilter
         }"
    >
      <div class="custom-select__wrapper">
        <div class="custom-select__field-wrapper">
          <input type="text" class="custom-select__field"
                 :value="getNameByValue(currentValue)"
                 :id="fieldId"
                 @click="openable"
                 @input="abortInput"
                 :placeholder="placeholder"
                 readonly="readonly"
                 autocomplete="off"
          >
          <div class="custom-select__arrow-icon custom-select__arrow-icon_absolute">
            <ArrowSmall/>
          </div>
        </div>
        <div class="custom-select__content-wrap custom-select__content-wrap_openable">
          <div class="custom-select__filter" v-if="hasFilter">
            <input
                type="text"
                class="custom-select__filter-label"
                autocomplete="off"
                :id="uniqueId(fieldId, 'filter')"
                @input="filter"
            >
          </div>
          <ul class="custom-select__list">
            <li v-if="!noDefault" class="custom-select__item">
              <input
                  class="custom-select__input custom-select__input_radio"
                  type="radio"
                  :value="defaultValue.value"
                  :id="uniqueId(fieldId)"
                  :name="fieldId"
                  @change="change"
              >
              <label :for="uniqueId(fieldId)" class="custom-select__label">
                <span class="custom-select__choice-name">
                  {{ defaultValue.name }}
                </span>
              </label>
            </li>
            <li v-for="item in values" class="custom-select__item" :class="{'_hidden': isHidden(item)}">
              <input
                  class="custom-select__input custom-select__input_radio"
                  type="radio"
                  :name="fieldId"
                  :value="valueAsString(item)"
                  :id="uniqueId(fieldId, valueAsString(item))"
                  :disabled="checkDisabled(valueAsString(item))"
                  :checked="currentValue === item.value"
                  @change="change"
              >
              <label :for="uniqueId(fieldId, valueAsString(item))" class="custom-select__label">
                <span class="custom-select__choice-name">
                  {{ nameAsString(item) }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowSmall from '../../icons/ArrowSmall.vue';

export default {
  name: "BaseFieldSelect",
  components: {
    ArrowSmall
  },
  props: {
    // Уникальный id селекта
    fieldId: {
      type: String,
      required: true
    },
    /*
      Все возможные значения селекта
      - список объектов с полями 'name' и 'value'
    */
    values: {
      type: Array,
      required: true
    },
    currentValue: {
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disableValues: {
      type: Array,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    // true - не добавлять null => 'Все'
    noDefault: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    defaultValue: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: 'Все',
          value: null
        }
      }
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      isOpen: false,
      hiddenValues: [],
    }
  },
  methods: {
    openable(e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
    },
    change(e) {
      this.isOpen = false;
      this.$emit('input', e.target.value);
    },
    getNameByValue(value) {
      if (Array.isArray(value)) {
        return this.getNameByValues(value);
      }
      const item = this.getItemByValue(value);
      return item ? item.name : this.defaultValue.name;
    },
    getNameByValues(values) {
      const names = [];
      const items = this.getItemsByValues(values);
      items.forEach((item) => names.push(item.name));

      return names ? names.join(', ') : this.defaultValue.name;
    },
    getItemByValue(value) {
      return this.values.find((item) => item.value === value);
    },
    getItemsByValues(values) {
      return this.values.filter((item) => values.includes(item.value))
    },
    uniqueId(prefix, value) {
      value = value === undefined ? 'all' : value;
      return `${prefix}_${value}`;
    },
    valueAsString(item) {
      return item.value !== undefined ? item.value : '';
    },
    nameAsString(item) {
      return item.name !== undefined ? item.name : '';
    },
    checkDisabled(value) {
      return this.disableValues ? this.disableValues.includes(value) : false;
    },
    abortInput(e) {
      e.preventDefault();
      e.target.value = '';
    },
    filter(e) {
      const value = e.target.value.trim().toLowerCase();
      if (value === '') {
        this.hiddenValues.splice(0, this.hiddenValues.length);
      } else {
        this.values.forEach((item) => {
          const includes = item.name.toString().trim().toLowerCase().includes(value);
          const alreadyHidden = this.hiddenValues.includes(item.value);
          if (includes && alreadyHidden) {
            this.hiddenValues.splice(this.hiddenValues.indexOf(item.value), 1);
          } else if (!includes && !alreadyHidden) {
            this.hiddenValues.push(item.value)
          }
        })
      }

      // this.checkNeedAllMark();
    },
    isHidden(item) {
      return this.hiddenValues.includes(item.value);
    },
  },
  mounted() {
    const instance = this;
    const el = this.$el.querySelector('[data-vue-custom-select]');
    document.addEventListener('click', (e) => {
      if (instance.isOpen && !(el === e.target || el.contains(e.target))) {
        instance.isOpen = false;
      }
    });
  },
}
</script>