var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-list-header" },
    [
      _vm._l(_vm.columns, function (column) {
        return _c(
          "div",
          { staticClass: "catalog-list-header__column", class: column.key },
          [
            column.sort
              ? _c("BaseFieldSort", {
                  attrs: {
                    fieldKey: column.key,
                    name: "catalog-sort",
                    label: _vm.switchableLabel(column),
                    "current-value": _vm.currentOrder,
                  },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.currentOrder,
                    callback: function ($$v) {
                      _vm.currentOrder = $$v
                    },
                    expression: "currentOrder",
                  },
                })
              : _c("div", { staticClass: "catalog-list-header__label" }, [
                  _vm._v("\n      " + _vm._s(column.name) + "\n    "),
                ]),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.productPreloader ? _c("preloader") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }