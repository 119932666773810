var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.conditions
    ? _c(
        "div",
        {
          class: {
            "tree-condition-set": true,
            "tree-condition-set_multiple": _vm.hasSubConditions,
            "tree-condition-set_root": _vm.rootConditionSet,
          },
        },
        [
          _vm.hasSubConditions
            ? _vm._l(_vm.conditions.subConditions, function (condition, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "tree-condition-set__conditions-wrapper",
                  },
                  [
                    _c("TreeConditionSet", {
                      attrs: {
                        treeId: _vm.treeId,
                        conditions: condition,
                        id: key,
                        "parent-nodes": _vm.parentNodes,
                      },
                      on: {
                        last: _vm.lastCondition,
                        remove: function ($event) {
                          return _vm.removeSubItem(key)
                        },
                      },
                    }),
                    _vm._v(" "),
                    key !== _vm.lastKey
                      ? _c(
                          "div",
                          {
                            staticClass: "tree-condition-set__condition-logic",
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.conditions.subConditionsOperator,
                                    expression:
                                      "conditions.subConditionsOperator",
                                  },
                                ],
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.conditions,
                                      "subConditionsOperator",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "and" } }, [
                                  _vm._v("и"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "or" } }, [
                                  _vm._v("или"),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              })
            : [
                _c(
                  "div",
                  {
                    staticClass:
                      "tree-condition-set__conditions-wrapper tree-condition-set__conditions-wrapper_single",
                  },
                  [
                    _c("TreeCondition", {
                      attrs: {
                        treeId: _vm.treeId,
                        condition: _vm.conditions.treeCondition,
                        allowRemove: _vm.isAllowRemoveCondition,
                        "parent-nodes": _vm.parentNodes,
                      },
                      on: { split: _vm.split, remove: _vm.remove },
                    }),
                  ],
                  1
                ),
              ],
          _vm._v(" "),
          _vm.hasSubConditions || _vm.rootConditionSet
            ? _c("div", { staticClass: "appender" }, [
                _c(
                  "a",
                  {
                    staticClass: "append-condition",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.append.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text" }, [
                      _vm._v("\n          + Добавить условие\n      "),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }