class FieldsPayload {
  static getPayload(fields) {
    const payload = {};

    fields.forEach((field) => {
      if (field.mode_label && field.mode_value) {
        payload[`${field.key}_mode`] = field.mode_value;
      }

      if (field.view_type === 'range') {
        if (!field.disabled) {
          payload[field.key] = {
            from: field.current_value.from !== field.borders.from ? field.current_value.from : null,
            to: field.current_value.to !== field.borders.to ? field.current_value.to : null,
          };
        }

        return;
      }

      if (['checkbox-list', 'select-multi'].includes(field.view_type)) {
        if (field.key === 'eav_prop_3') console.log(field);
        if (field.current_value.length) {
          if (field.data_type === 'data') {
            const currentIds = field.current_value
              .filter((item) => !field.disable_values.includes(item.value));

            payload[field.key] = field.values
              .filter((value) => currentIds.includes(value.value))
              .map((value) => value.data);

            return;
          }

          payload[field.key] = field.current_value
            .filter((item) => !field.disable_values.includes(item.value));
        }

        if (field.current_second_value && field.current_second_value.length) {
          if (field.data_type === 'data') {
            const secondIds = field.current_second_value
              .filter((item) => !field.disable_values.includes(item.value));

            payload[`second_${field.key}`] = field.values
              .filter((value) => secondIds.includes(value.value))
              .map((value) => value.data);

            return;
          }

          payload[`second_${field.key}`] = field.current_second_value
            .filter((item) => !field.disable_values.includes(item.value));
        }

        return;
      }

      if (['checkbox'].includes(field.view_type)) {
        if (field.current_value && !field.disable_values.includes(field.current_value)) {
          payload[field.key] = field.current_value;
        }

        return;
      }

      if (field.view_type === 'dynamic-checkbox-list') {
        if (field.current_value.length) {
          payload[field.key] = field.current_value;
        }
        if (field.contains_filter) {
          payload[`${field.key}_contains`] = field.contains_filter;
        }

        return;
      }

      if (field.current_value !== '') {
        payload[field.key] = field.current_value;
      }
    });

    return payload;
  }
}

export default FieldsPayload;