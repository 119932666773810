<template>
  <div class="catalog-filter__params">
    <TheFilterParamShortEav v-if="isEav"/>
    <TheFilterParamShort v-else-if="isNotEav"/>

    <TheFilterParamExtend
      v-if="extend"
    />

    <preloader v-if="loading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import TheFilterParamShort from "./TheFilterParamShort.vue";
import TheFilterParamShortEav from "./TheFilterParamShortEav.vue";
import TheFilterParamExtend from "./TheFilterParamExtend.vue";
import Preloader from "../parts/Preloader.vue";

export default {
  name: "TheParamFilter",
  components: {
    Preloader,
    TheFilterParamShort,
    TheFilterParamShortEav,
    TheFilterParamExtend
  },
  computed: {
    ...mapState('filterParam', {
      'extend': 'extend',
      'loading': 'loading'
    }),
    ...mapState('catalogFilter', {
      'settings': 'settings'
    }),
    isEav() {
      return this.settings.productType ? this.settings.productType === 'eav' : null;
    },
    isNotEav() {
      return this.isEav === false;
    }

  },
}
</script>
