var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "catalog-filter-panel",
      class: { _param: _vm.isParamFilter, _auto: _vm.isAutoFilter },
    },
    [
      _c(
        "div",
        { staticClass: "catalog-filter-panel__opener-wrap" },
        [
          _vm.isParamFilter
            ? _c("plus-button", {
                staticClass: "catalog-filter-panel__opener",
                attrs: {
                  text: "Расширенный фильтр",
                  "text-alt": "Короткий фильтр",
                  "is-open": _vm.paramExtend,
                },
                on: { click: _vm.handleExpand },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showTotal
        ? _c("div", { staticClass: "catalog-filter-panel__total" }, [
            _vm.totalPreloader
              ? _c(
                  "div",
                  { staticClass: "catalog-filter-panel__total-preloader" },
                  [_c("preloader")],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "catalog-filter-panel__total-inner" },
                  [
                    _vm.totalProducts
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "catalog-filter-panel__total-inner-text",
                          },
                          [
                            _vm._v(
                              "\n        Найдено " +
                                _vm._s(_vm.totalProductsShow) +
                                " товаров\n      "
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "catalog-filter-panel__total-inner-text",
                          },
                          [_vm._v("\n        Не найдено товаров\n      ")]
                        ),
                  ]
                ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("the-filter-collection"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }