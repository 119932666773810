<template>
  <div class="cart-state-block" v-bind:class="{_hide: isHide}">
    <div class="container container_spacer">
      <div class="cart-state-block__inner grid-block">
        <div class="cart-state-block__count-wrapper">
          Выбрано {{ count }} товаров
        </div>

        <div class="cart-state-block__total-wrapper">
          <div class="cart-state-block__total-text">
            Сумма заказа:
          </div>
          <div class="cart-state-block__total-number">
            {{ formatPrice(total) }} ₽
          </div>
        </div>

        <div class="cart-state-block__button-wrapper">
          <a href="/cart" class="cart-state-block__button button">
            <span class="cart-state-block__button-icon">
              <cart-icon/>
            </span>

            <span class="cart-state-block__button-text">
              Перейти в корзину
            </span>

            <span class="cart-state-block__button-arrow">
              <arrow-right/>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CartIcon from "../icons/CartIcon.vue";
import ArrowRight from "../icons/ArrowRight.vue";
export default {
  name: "TheCartStateBlock",
  components: {
    CartIcon,
    ArrowRight
  },
  methods: {
    ...mapActions({
      'loadCheckout': 'cart/loadCheckout',
    }),
    formatPrice(rawValue) {
      return this.$root.formatPrice(rawValue);
    }
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout'
    }),
    ...mapGetters('cart', {
      'total': 'total',
      'count': 'countPositions'
    }),
    isHide() {
      return !this.checkout || !this.total;
    }
  },
  mounted() {
    this.loadCheckout();
  }
}
</script>
