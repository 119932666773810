var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "46",
        height: "12",
        viewBox: "0 0 46 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.46967 5.79096C0.176777 6.08385 0.176777 6.55873 0.46967 6.85162L5.24264 11.6246C5.53553 11.9175 6.01041 11.9175 6.3033 11.6246C6.59619 11.3317 6.59619 10.8568 6.3033 10.5639L2.06066 6.32129L6.3033 2.07865C6.59619 1.78576 6.59619 1.31088 6.3033 1.01799C6.01041 0.725095 5.53553 0.725095 5.24264 1.01799L0.46967 5.79096ZM46 5.57129H1V7.07129H46V5.57129Z",
          fill: "#FF7A00",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }