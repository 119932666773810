<template>
  <div class="stat-graph">
    <div class="stat-graph__title" v-if="title">
      {{ title }}
    </div>
    <form class="stat-graph__form">
      <base-field-date
          :label="'От'"
          class="stat-graph__field _date"
          :input-id="getFieldId('from')"
          @change="change"
          v-model="formData.dateFrom"
          :value="formData.dateFrom"
      />

      <base-field-date
          :label="'До'"
          class="stat-graph__field _date"
          :input-id="getFieldId('to')"
          @change="change"
          v-model="formData.dateTo"
          :value="formData.dateTo"
      />

      <base-field-select-multi
          :values="providers()"
          :field-id="getFieldId('provider')"
          :label="'Поставщики'"
          :current-values="formData.selectedProviders"
          @input="rerender"
      />
    </form>

      <div class="stat-graph__content">
        <div class="stat-graph__graph-wrap" v-if="graphData.filteredData">

          <div class="stat-graph__graph">
            <stat-graph-line
                :data="graphData.filteredData"
                :options="graphData.options"
                :bus="bus"
                @updateLegend="updateLegend"
            />
          </div>

          <div class="stat-graph__legend-wrap">
            <div class="stat-graph__legend" v-html="graphData.legend"></div>
          </div>

        </div>

        <Preloader v-else-if="graphData.isLoading" class="stat-graph__preloader"/>

        <div v-else class="stat-graph__empty">
          Нет данных за указанный период
        </div>
      </div>
  </div>
</template>

<script>
import BaseFieldDate from "../../parts/fields/BaseFieldDate.vue";
import getLastMonthRange from "../../../helpers/dates";
import Preloader from "../../parts/Preloader.vue";
import legendCallback from "../../../helpers/chart-legend";
import Vue from "vue";
import StatGraphLine from "./StatGraphLine.vue";
import { mapActions } from "vuex";
import BaseFieldSelectMulti from "./../../parts/fields/BaseFieldSelectMulti.vue";


export default {
  name: "PriceMoveGraph",
  components: {
    BaseFieldDate,
    StatGraphLine,
    Preloader,
    BaseFieldSelectMulti
  },
  props: {
    graphKey: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    product: {
      type: Object,
      required: true,
      default: {
        productClass: '',
        productId: '',
      }
    }
  },
  data(){
    return {
      bus: new Vue,
      formData: {
        dateFrom: null,
        dateTo: null,
        selectedProviders: [],
      },
      graphData: {
        isLoading: false,
        legend: '',

        data: null,
        filteredData: null,

        options: {
          type: 'line',
          steppedLine: true,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'day',
              },
            }],
          },
          legend: false,
          legendCallback
        }
      }

    }
  },
  methods: {
    ...mapActions('priceMoves', {
      'loadStatData': 'loadStatData'
    }),
    getFieldId(fieldKey) {
      return `${this.graphKey}_${fieldKey}`;
    },
    change(e) {
      this.initGraph()
    },
    updateLegend(legend) {
      this.graphData.legend = legend;
    },
    async initGraph() {
      this.graphData.isLoading = true;
      this.graphData.data = null;

      const chartData = await this.update();

      if (chartData && chartData.length) {
        this.setGraphDatasets(chartData);
        this.filteredChartData();
      }

      this.graphData.isLoading = false;
    },
    async update() {
      return await this.loadStatData({
        statDataKey: this.graphKey,
        productData: this.product,
        formData: this.formData
      });
    },
    async rerender() {
      await this.filteredChartData();
      if (this.bus) {
        this.bus.$emit('update');
      }
    },
    setGraphDatasets(chartData) {
      if (chartData) {
        this.graphData.data = {
          datasets: chartData.map((line) => {
            return {
              label: line.label,
              provider_id: line.provider_id,
              provider: line.provider,
              data: line.data.map((point) => {
                return {
                  x: new Date(point.x),
                  y: point.y
                };
              })
            };
          })
        }
      } else {
        this.graphData.data = null;
      }
    },

    providers() {
      const providers = [];
      const providerIds = [];
      if (this.graphData.data && this.graphData.data.datasets) {
        this.graphData.data.datasets.forEach((item) => {
          if (item.provider && !providerIds.includes(item.provider_id)) {
            providers.push({'name': item.provider, 'value': item.provider_id});
            providerIds.push(item.provider_id);
          }
        });
      }

      return providers;
    },
    async filteredChartData() {

      if (this.graphData.data && this.graphData.data.datasets) {
        const chartData = {...this.graphData.data};
        if (this.formData.selectedProviders.length) {
          chartData.datasets = chartData.datasets
              .filter((item) => this.formData.selectedProviders.includes(item.provider_id));
        }

        this.graphData.filteredData = chartData;
      } else {
        this.graphData.filteredData = false;
      }
      this.updateLegend()
    },
  },
  mounted() {
    const range = getLastMonthRange();

    this.formData.dateFrom = range.from;
    this.formData.dateTo = range.to;

    const inputFrom = document.querySelector(`#${this.getFieldId('from')}`);
    const inputTo = document.querySelector(`#${this.getFieldId('to')}`);

    inputFrom.value = range.from;
    inputTo.value = range.to;
    this.initGraph();
  }
}
</script>
