<template>
  <div class="stat-client-actions">
    <h3 class="stat-client-actions__title">
      Действия клиента по товарам
    </h3>

    <form class="stat-client-actions__form">
      <div class="stat-client-actions__fields">
        <base-field-date
            :label="'От'"
            :input-id="'client_actions_date_from'"
            :enable-time="true"
            v-model="dateFrom"
            @change="update"
        />

        <base-field-date
            :label="'До'"
            :input-id="'client_actions_date_to'"
            :enable-time="true"
            v-model="dateTo"
            @change="update"
        />

        <base-field-select-multi
            :field-id="'client_actions_date_type'"
            :values="availTypes"
            :label="'Тип'"
            :no-default="true"
            :current-values="currentTypes"
            @input="(e) => update()"
        />
      </div>
    </form>

    <div class="stat-client-actions__table">
      <div class="stat-client-actions__headers">
        <div class="stat-client-actions__header-item _date">
          Дата
        </div>
        <div class="stat-client-actions__header-item _time">
          Время
        </div>
        <div class="stat-client-actions__header-item _type">
          Тип
        </div>
        <div class="stat-client-actions__header-item _product">
          Товар
        </div>
      </div>

      <preloader v-if="isLoaded"/>

      <ul class="stat-client-actions__list" v-else-if="actions">
        <li class="stat-client-actions__item" v-for="action in actions">
          <div class="stat-client-actions__item-value _date">
            {{ action.date}}
          </div>
          <div class="stat-client-actions__item-value _time">
            {{ action.time}}
          </div>
          <div class="stat-client-actions__item-value _type" :class="getTypeClass(action.typeEnum)">
            {{ action.type}}
          </div>
          <div class="stat-client-actions__item-value _product">
            <a :href="action.url" data-modal class="stat-client-actions__product" v-if="action.url">
              {{ action.name }}
            </a>
            <span class="stat-client-actions__product" v-else>
              {{ action.name }}
            </span>
          </div>
        </li>
      </ul>

      <div class="stat-client-actions__empty" v-else>
        Нет данных по данному запросу
      </div>
    </div>

    <div class="stat-client-actions__total" v-if="total && total > actions.length">
      Показано {{ actions.length }} строк из {{ total }}
    </div>
  </div>
</template>

<script>
import Preloader from "../parts/Preloader.vue";
import BaseFieldDate from "../parts/fields/BaseFieldDate.vue";
import BaseFieldSelectMulti from "../parts/fields/BaseFieldSelectMulti.vue";

export default {
  name: "TheStatClientActions",
  components: {
    Preloader,
    BaseFieldDate,
    BaseFieldSelectMulti
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      currentTypes: [],
      page: 1,
      availTypes: [
        {
          name: 'Просмотр товара',
          value: '10',
        },
        {
          name: 'Добавление в корзину',
          value: '20',
        },
        {
          name: 'Оформление заказа',
          value: '30',
        }
      ]
    }
  },
  props: {
    isLoaded: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      required: false,
      default: []
    },
    total: {
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    getTypeClass(typeEnum) {
      switch (typeEnum) {
        case 10: return '_view';
        case 20: return '_cart';
        case 30: return '_order';
        default: return '';
      }
    },
    update() {
      this.$emit('update', {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        types: this.currentTypes
      });
    }
  },
  mounted() {
    this.update();
  }
}
</script>