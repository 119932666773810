<template>
  <div class="cart-quantity-panel">
    <button
        type="button"
        class="cart-quantity-panel__button cart-quantity-panel__button_dec"
        @click="decrease"
    >
      <minus-icon/>
    </button>

    <input
        type="text"
        autocomplete="off"
        class="cart-quantity-panel__input"
        :value="renderQuantity"
        :placeholder="renderPlaceholder"
        @input="inputQuantity"
    >

    <button
        type="button"
        class="cart-quantity-panel__button cart-quantity-panel__button_inc"
        @click="increase"
    >
      <plus-icon/>
    </button>
  </div>
</template>

<script>
import PlusIcon from '../icons/PlusIcon.vue';
import MinusIcon from '../icons/MinusIcon.vue';

export default {
  name: "CartQuantityPanel",
  components: {
    MinusIcon,
    PlusIcon
  },
  props: {
    renderQuantity: {
      type: String
    },
    placeholderValue: {
      type: String,
      required: false
    },
    cartKey: {
      type: String,
      required: false
    },
    availZero: {
      type: Boolean,
      required: false,
      default: false
    },
    maxValue: {
      type: Number,
      required: false
    }
  },
  computed: {
    renderPlaceholder() {
      return this.placeholderValue ? this.placeholderValue : '';
    }
  },
  methods: {
    increase() {
      this.$emit('increase', {
        key: this.cartKey ? this.cartKey : null,
        amount: 1
      });
    },
    decrease() {
      this.$emit('decrease', {
        key: this.cartKey ? this.cartKey : null,
        amount: 1
      });
    },
    inputQuantity(e) {
      const value = this.getClearValue(e);
      this.$emit('inputQuantity', {
        key: this.cartKey ? this.cartKey : null,
        amount: value,
        sender: e.target
      });
    },
    getClearValue(e) {
      let value = e.target.value;
      if (value !== '') {
        value = value.replace(/[^0-9]/, '')
        value = parseInt(value, 10);
        if (value) {
          if (this.maxValue && this.maxValue < value) {
            value = this.maxValue;
          }
        } else {
          value = '';
        }
      }

      // e.target.value = value;
      return value;
    }
  }
}
</script>

<style lang="scss">

</style>