var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-position" }, [
    _c("div", { staticClass: "cart-position__wrapper" }, [
      _vm.isAvailPosition
        ? _c("div", { staticClass: "cart-position__prices-wrap" }, [
            _c("div", { staticClass: "cart-position__storage text__small" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.position.storage_name) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cart-position__price-block" },
              [
                _vm.isCustomPrice
                  ? _c("input", {
                      staticClass: "cart-position__price-input",
                      attrs: {
                        type: "text",
                        autocomplete: "off",
                        placeholder: _vm.position.base_item_price,
                      },
                      domProps: { value: _vm.renderPrice },
                      on: { input: _vm.inputPrice },
                    })
                  : _c(
                      "div",
                      {
                        staticClass: "cart-position__price-number text__small",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.renderPrice) +
                            " \n        "
                        ),
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cart-position__price-measure text__small" },
                  [_vm._v("\n          ₽/шт.\n        ")]
                ),
                _vm._v(" "),
                _vm.isForInternalManager
                  ? _c("additional-prices", {
                      staticClass: "cart-position__additional_prices",
                      attrs: { prices: _vm.otherPrices },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cart-position__panel" },
              [
                _c("cart-quantity-panel", {
                  attrs: {
                    cartKey: _vm.position.key,
                    renderQuantity: _vm.renderQuantity,
                    placeholderValue: _vm.renderQuantity,
                  },
                  on: {
                    increase: _vm.increase,
                    decrease: _vm.decrease,
                    inputQuantity: _vm.inputQuantity,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "cart-position__total text__small" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.formatPrice(_vm.position.base_price)) +
                  " ₽\n      "
              ),
            ]),
          ])
        : _c(
            "div",
            { staticClass: "cart-position__blocked" },
            [
              _c("WarningIcon"),
              _vm._v(" "),
              _c("div", { staticClass: "cart-position__blocked-text" }, [
                _vm._v("\n        Позиция недоступна для заказа\n      "),
              ]),
            ],
            1
          ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cart-position__remove-wrap" }, [
      _c(
        "a",
        {
          staticClass: "cart-position__remove-icon",
          attrs: { href: "javascript:void(0);" },
          on: { click: _vm.remove },
        },
        [_c("RemoveIcon")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }