const ColorService = {
  getColors() {
    return [
      '#79CA48',
      '#30A8DB',
      '#BE7CFF',
      '#FF7676',
      '#FFCB60',
      '#ff6092',
      '#60ffe7',
      '#fc6c47',
      '#5464ff',
      '#ff5454',
      '#c3f663',
      '#63f694',
      '#a063f6',
      '#f663c5',
      '#fa7a6a',
      '#dec290',
      '#8096b7',
      '#9d80b7',
      '#b78080',
      '#86b7b7',
      '#9db786',
      '#15B56C',
      '#009B82',
      '#008086',
      '#006377',
      '#2F4858',
      '#006FAE',
      '#E3F7D3',
      '#00D3FF',
      '#009BD2',
      '#A76926',
      '#FF652F',
      '#FF1D75',
      '#002200',
      '#934FA5',
      '#32537C',
      '#FFE3EC',
      '#F1A6B3',
      '#8B66B9',
      '#C29AF1',
      '#9BDEAC',
      '#F7F5DD',
    ];
  },
};

export default ColorService;