var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-graph" }, [
    _vm.title
      ? _c("div", { staticClass: "stat-graph__title" }, [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "stat-graph__form" },
      [
        _c("base-field-date", {
          staticClass: "stat-graph__field _date",
          attrs: {
            label: "От",
            "input-id": _vm.getFieldId("from"),
            value: _vm.formData.dateFrom,
          },
          on: { change: _vm.change },
          model: {
            value: _vm.formData.dateFrom,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "dateFrom", $$v)
            },
            expression: "formData.dateFrom",
          },
        }),
        _vm._v(" "),
        _c("base-field-date", {
          staticClass: "stat-graph__field _date",
          attrs: {
            label: "До",
            "input-id": _vm.getFieldId("to"),
            value: _vm.formData.dateTo,
          },
          on: { change: _vm.change },
          model: {
            value: _vm.formData.dateTo,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "dateTo", $$v)
            },
            expression: "formData.dateTo",
          },
        }),
        _vm._v(" "),
        _c("base-field-select-multi", {
          attrs: {
            values: _vm.providers(),
            "field-id": _vm.getFieldId("provider"),
            label: "Поставщики",
            "current-values": _vm.formData.selectedProviders,
          },
          on: { input: _vm.rerender },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-graph__content" },
      [
        _vm.graphData.filteredData
          ? _c("div", { staticClass: "stat-graph__graph-wrap" }, [
              _c(
                "div",
                { staticClass: "stat-graph__graph" },
                [
                  _c("stat-graph-line", {
                    attrs: {
                      data: _vm.graphData.filteredData,
                      options: _vm.graphData.options,
                      bus: _vm.bus,
                    },
                    on: { updateLegend: _vm.updateLegend },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "stat-graph__legend-wrap" }, [
                _c("div", {
                  staticClass: "stat-graph__legend",
                  domProps: { innerHTML: _vm._s(_vm.graphData.legend) },
                }),
              ]),
            ])
          : _vm.graphData.isLoading
          ? _c("Preloader", { staticClass: "stat-graph__preloader" })
          : _c("div", { staticClass: "stat-graph__empty" }, [
              _vm._v("\n        Нет данных за указанный период\n      "),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }