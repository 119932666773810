import LiveEvent from '../../components/live/live';
import Jax from '../../components/jax/jax';
import { onDomChanges } from '../../components/dynamic/observer';

onDomChanges(() => {
  document.querySelectorAll('[data-endless-action]:not([data-pagination-ready="true"])').forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      let key = link.closest('[data-pagination-nav]').dataset.paginationNav;
      if (!link.dataset.loading) {
        link.dataset.loading = "true";
        let jax = new Jax(link.getAttribute('href'));
        jax.send().then((html) => {
          let page = document.createElement('div');
          page.innerHTML = html;
          let newData = page.querySelector(`[data-pagination-data="${key}"]`);
          let currentData = document.querySelector(`[data-pagination-data="${key}"]`);

          Array.from(newData.children).forEach((child) => {
            currentData.appendChild(child);
          });

          link.dataset.loading = "";

          let newPagination = page.querySelector(`[data-pagination-nav="${key}"]`);
          let currentPagination = document.querySelector(`[data-pagination-nav="${key}"]`);

          currentPagination.parentNode.replaceChild(newPagination, currentPagination);

          let event = new Event('DOMContentMutated');
          document.dispatchEvent(event);
        })
      }
    });
    link.dataset.paginationReady = "true";
  });
})