<template>
  <div class="date-field">
    <label class="date-field__label" :for="inputId">{{ label }}</label>
    <input
        :id="inputId"
        :type="enableTime ? 'datetime-local' : 'date'"
        class="date-field__input"
        @change="change"
        v-model="value"
        :disabled="disabled"
        :value="currentValue"
    >
  </div>
</template>

<script>
export default {
  name: "BaseFieldDate",
  model: {
    prop: 'currentValue',
    event: 'change'
  },
  props: {
    inputId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    currentValue: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false
    },
    enableTime: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      value: this.currentValue
    }
  },
  methods: {
    change(event) {
      this.$emit('change', this.value);
    },
  },
  watch: {
    currentValue: {
      deep: true,
      handler() {
        this.value = this.currentValue;
      }
    }
  },
}
</script>
