var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-auto-filter" },
    [
      _c(
        "form",
        {
          staticClass: "catalog-auto-filter__top",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.fetchSizes.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "catalog-auto-filter__fields" },
            _vm._l(_vm.arrayAutoFields, function (field) {
              return _c(
                "div",
                { staticClass: "catalog-auto-filter__field" },
                [
                  _c("BaseFieldSelectFilterable", {
                    attrs: {
                      values: field.values,
                      "field-id": field.key,
                      currentValue: field.current_value,
                      disabled: !field.values.length,
                      label: field.label,
                      "no-default": true,
                      "default-value": { name: "", value: null },
                      placeholder: field.placeholder,
                      "has-filter": true,
                    },
                    on: {
                      input: function (e) {
                        return _vm.change(field.key, e)
                      },
                    },
                    model: {
                      value: field.current_value,
                      callback: function ($$v) {
                        _vm.$set(field, "current_value", $$v)
                      },
                      expression: "field.current_value",
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "catalog-auto-filter__button-wrapper" }, [
            _c(
              "button",
              {
                staticClass: "catalog-auto-filter__button",
                attrs: { disabled: !_vm.enableButton },
              },
              [_vm._v("\n        Подобрать\n      ")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.extend ? _c("TheFilterAutoParam") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }