<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 21C9.10457 21 10 20.1046 10 19C10 17.8954 9.10457 17 8 17C6.89543 17 6 17.8954 6 19C6 20.1046 6.89543 21 8 21Z" stroke="#7D7984" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21Z" stroke="#7D7984" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 17H8L5 3H3" stroke="#7D7984" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 5L21 6L19 13H8" stroke="#7D7984" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CartGrayIcon"
}
</script>