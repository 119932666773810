<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.99967 3.33301H13.333L16.6663 6.66634V14.9997C16.6663 15.4417 16.4907 15.8656 16.1782 16.1782C15.8656 16.4907 15.4417 16.6663 14.9997 16.6663H4.99967C4.55765 16.6663 4.13372 16.4907 3.82116 16.1782C3.5086 15.8656 3.33301 15.4417 3.33301 14.9997V4.99967C3.33301 4.55765 3.5086 4.13372 3.82116 3.82116C4.13372 3.5086 4.55765 3.33301 4.99967 3.33301" stroke="#FF7A00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.99967 13.3333C10.9201 13.3333 11.6663 12.5871 11.6663 11.6667C11.6663 10.7462 10.9201 10 9.99967 10C9.0792 10 8.33301 10.7462 8.33301 11.6667C8.33301 12.5871 9.0792 13.3333 9.99967 13.3333Z" stroke="#FF7A00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.666 3.33301V6.66634H6.66602V3.33301" stroke="#FF7A00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "SaveIcon"
}
</script>