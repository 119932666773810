var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__vue" }, [
    _c(
      "ul",
      { staticClass: "stat-page__tabs tabs tabs_main" },
      [
        _c(
          "li",
          {
            staticClass: "tabs__item",
            class: { _active: _vm.currentTab === "general" },
          },
          [
            _c(
              "a",
              {
                staticClass: "tabs__link",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.setCurrentTab("general")
                  },
                },
              },
              [_vm._v("\n        Общая\n      ")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "tabs__item",
            class: { _active: _vm.currentTab === "client" },
          },
          [
            _c(
              "a",
              {
                staticClass: "tabs__link",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.setCurrentTab("client")
                  },
                },
              },
              [_vm._v("\n        Пользователи\n      ")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.productTypes, function (productType) {
          return _c(
            "li",
            {
              staticClass: "tabs__item",
              class: { _active: _vm.currentTab === productType.key },
            },
            [
              _c(
                "a",
                {
                  staticClass: "tabs__link",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.setCurrentTab(productType.key)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(productType.name) + "\n      ")]
              ),
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "tabs__item",
            class: { _active: _vm.currentTab === "sync" },
          },
          [
            _c(
              "a",
              {
                staticClass: "tabs__link",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.setCurrentTab("sync")
                  },
                },
              },
              [_vm._v("\n        Синхронизация\n      ")]
            ),
          ]
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-page__contents" },
      [
        _vm.currentTab === "general" ? _c("TheStatGeneral") : _vm._e(),
        _vm._v(" "),
        _vm.currentTab === "client" ? _c("TheStatClient") : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.productTypes, function (productType) {
          return _c("div", [
            _vm.currentTab === productType.key
              ? _c(
                  "div",
                  { staticClass: "stat-page__content-wrap" },
                  [
                    _c("stat-category", {
                      attrs: { "category-key": productType.key },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
        _vm._v(" "),
        _vm.currentTab === "sync" ? _c("TheSyncPage") : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }