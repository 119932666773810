var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "11",
          cy: "11",
          r: "10.5",
          stroke: "#70A500",
          "stroke-opacity": "0.3",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M7.26267 11.8818L10.1805 14.3868L14.9401 8.52302",
          stroke: "#70A500",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }