var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.condition
    ? _c(
        "div",
        { staticClass: "tree-condition" },
        [
          _c("v-select", {
            staticClass: "tree-condition__select",
            attrs: {
              reduce: function (option) {
                return option.value
              },
              options: _vm.nameFieldsSelectItems,
              clearable: false,
              searchable: false,
            },
            on: { input: _vm.changeNameField },
            model: {
              value: _vm.condition.fieldName,
              callback: function ($$v) {
                _vm.$set(_vm.condition, "fieldName", $$v)
              },
              expression: "condition.fieldName",
            },
          }),
          _vm._v(" "),
          _c("v-select", {
            staticClass: "tree-condition__select",
            attrs: {
              reduce: function (option) {
                return option.value
              },
              options: _vm.allowedConditionsFieldsSelectItems,
              clearable: false,
              searchable: false,
            },
            on: { input: _vm.changeAllowedConditionsField },
            model: {
              value: _vm.condition.condition,
              callback: function ($$v) {
                _vm.$set(_vm.condition, "condition", $$v)
              },
              expression: "condition.condition",
            },
          }),
          _vm._v(" "),
          !this.isChanging
            ? [
                _vm.isScalarValue(this.condition.condition)
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.condition.value,
                          expression: "condition.value",
                        },
                      ],
                      staticClass: "tree-condition__value-input",
                      attrs: { type: "text", autocomplete: "off" },
                      domProps: { value: _vm.condition.value },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.condition, "value", $event.target.value)
                        },
                      },
                    })
                  : _c(
                      "div",
                      { staticClass: "tree-condition__search-wrap" },
                      [
                        _c("v-select", {
                          staticClass: "tree-condition__value-block",
                          attrs: {
                            reduce: function (option) {
                              return option.id
                            },
                            label: "name",
                            options: _vm.fieldValues,
                            searchable: _vm.isLiveSearch,
                            noDrop: !_vm.fieldValues.length,
                            multiple: "",
                          },
                          on: {
                            search: _vm.debouncedSearch,
                            "search:focus": _vm.debouncedSearch,
                          },
                          model: {
                            value: _vm.condition.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.condition, "value", $$v)
                            },
                            expression: "condition.value",
                          },
                        }),
                        _vm._v(" "),
                        _vm.isSearching
                          ? _c("div", { staticClass: "lds-ring" }, [
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "tree-condition__actions" }, [
            _c(
              "a",
              {
                staticClass: "tree-condition__split-branch",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.split.apply(null, arguments)
                  },
                },
              },
              [_c("SplitIcon", { staticClass: "tree-condition__split-icon" })],
              1
            ),
            _vm._v(" "),
            _vm.allowRemove
              ? _c(
                  "a",
                  {
                    staticClass: "tree-condition__remove-branch",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.remove.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n      ×\n    ")]
                )
              : _vm._e(),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }