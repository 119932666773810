var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__content-inner" }, [
    _c(
      "div",
      { staticClass: "stat-page__row" },
      [
        _c("key-value-table", {
          staticClass: "stat-page__table",
          attrs: {
            name: "Количество предложений по поставщикам",
            tableKey: "offers_by_providers_table",
            extra: { type: this.productType },
          },
        }),
        _vm._v(" "),
        _c("key-value-table", {
          staticClass: "stat-page__table",
          attrs: {
            name: "Количество предложений на складах",
            tableKey: "offers_by_storages_table",
            extra: { type: this.productType },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }