<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10.5" stroke="#70A500" stroke-opacity="0.3"/>
    <path d="M7.26267 11.8818L10.1805 14.3868L14.9401 8.52302" stroke="#70A500" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "SuccessGreenIcon"
}
</script>