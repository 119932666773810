import _ from 'lodash';
import Jax from '../../components/jax/jax';
import { onDomReady } from '../../components/dynamic/observer';

class Filter {
  constructor(form) {
    this.form = form;
    this.debounce = _.debounce(() => this.request(this.form), 1000);
    this.init();
  }

  init() {
    this.initInputs();
    this.bind();
  }

  initInputs() {
    this.checkedInputs = Array.from(this.form.querySelectorAll('input[type="checkbox"], input[type="radio"]'));
    this.rangeInputs = this.form.querySelectorAll('.range-input input');
    this.selectInputs = this.form.querySelectorAll('select');
    this.dateInputs = Array.from(this.form.querySelectorAll('input[type="date"]'));
    this.textInputs = Array.from(this.form.querySelectorAll('input[type="text"]'));
  }

  bind() {
    this.bindChecked();
    this.bindRanges();
    this.bindSelects();
    this.bindDates();
    this.bindTexts();
  }

  request(form) {
    const instance = this;
    const jax = new Jax(form.getAttribute('action'), form.getAttribute('method'));
    jax.send(new FormData(form)).then((html) => instance.responseHandler(html));
  }

  responseHandler(html) {
    const instance = this;
    const page = document.createElement('div');
    page.innerHTML = html;

    instance.replaceContent(page);

    document.dispatchEvent(new Event('DOMContentMutated'));
    document.dispatchEvent(new Event('CatalogReplaced'));
  }

  replaceContent(page) {
    const newData = page.querySelector('[data-filter-content]');
    const currentData = document.querySelector('[data-filter-content]');
    if (newData && currentData) {
      currentData.innerHTML = newData.innerHTML;
    }
  }

  bindChecked() {
    const instance = this;
    this.checkedInputs.forEach((input) => {
      input.addEventListener('change', instance.debounce);
    });
  }

  bindRanges() {
    const instance = this;
    this.rangeInputs.forEach((input) => {
      input.addEventListener('change', instance.debounce);
    });
  }

  bindSelects() {
    const instance = this;
    this.selectInputs.forEach((input) => {
      input.addEventListener('change', instance.debounce);
    });
  }

  bindDates() {
    const instance = this;
    this.dateInputs.forEach((input) => {
      input.addEventListener('change', instance.debounce);
    });
  }

  bindTexts() {
    const instance = this;
    this.textInputs.forEach((input) => {
      input.addEventListener('keyup', instance.debounce);
    });
  }

  getRadioInputs() {
    return this.checkedInputs.filter((input) => input.type === 'radio');
  }

  getCheckboxInputs() {
    return this.checkedInputs.filter((input) => input.type === 'checkbox');
  }

  getSelectInputs() {
    return this.selectInputs;
  }

  resetRadioInputs() {
    this.getRadioInputs()
      .filter((input) => input.value === '0')
      .forEach((input) => {
        input.checked = 'checked';
        input.dispatchEvent(new Event('change'));
      });
  }

  resetCheckboxInputs() {
    this.getCheckboxInputs().forEach((input) => {
      input.checked = input.value === '0' ? 'checked' : '';
      input.dispatchEvent(new Event('change'));
    });
  }

  resetSelectInputs() {
    this.getSelectInputs().forEach((input) => {
      input.value = 0;
      if (input.id === 'FilterForm_sort') {
        input.value = 10;
      }
      input.dispatchEvent(new Event('change'));
    });
  }

  resetRangeInputs() {
    this.rangeInputs.forEach((input) => {
      const parent = input.closest('[data-range-input]');
      if (parent) {
        if (input.dataset.rangeFromInput !== undefined) {
          input.value = parent.dataset.min;
        } else if (input.dataset.rangeToInput !== undefined) {
          input.value = parent.dataset.max;
        }
      }
      input.dispatchEvent(new Event('hardSet'));
    });
  }
}

onDomReady(() => {
  const form = document.querySelector('[data-filter-form]');
  if (form) {
    new Filter(form);
  }
});