<template>
  <div class="catalog-order-block">
    <cart-quantity-panel
      :render-quantity="renderQuantity"
      :placeholderValue="renderQuantityInCart"
      :cartKey="keyInCart"
      :maxValue="maxQuantity"
      @increase="increase"
      @decrease="decrease"
      @inputQuantity="inputQuantity"
    />

    <div class="catalog-order-block__button-wrap">
      <button
          v-if="showOrderButton"
          type="button"
          class="catalog-order-block__action catalog-order-block__action_add button button__cart"
          @click="addOrSet"
      >
        <cart-icon/>
      </button>

      <button
          v-if="showAlreadyButton"
          type="button"
          class="catalog-order-block__action catalog-order-block__action_already"
      >
        <check-icon/>
      </button>

      <button
          v-if="showRemoveButton"
          type="button"
          class="catalog-order-block__action catalog-order-block__action_remove"
          @click="remove"
      >
        <remove-icon/>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import CartQuantityPanel from './CartQuantityPanel.vue';
import CartIcon from '../icons/CartIcon.vue';
import RemoveIcon from '../icons/RemoveIcon.vue';
import CheckIcon from '../icons/CheckIcon.vue';
import PlusIcon from '../icons/PlusIcon.vue';
import MinusIcon from '../icons/MinusIcon.vue';

export default {
  name: "CatalogOrderBlock",
  components: {
    CartQuantityPanel,
    PlusIcon,
    MinusIcon,
    CheckIcon,
    CartIcon,
    RemoveIcon
  },
  data: () => {
    return {
      maxQuantity: 1,
      buyable: '',
      buyableId: 0,
    };
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout',
    }),
    showRemoveButton: function () {
      return !!this.quantityInCart;
    },
    showOrderButton: function () {
      return this.currentQuantity && this.currentQuantity !== this.quantityInCart;
    },
    showAlreadyButton: function () {
      return this.currentQuantity && this.currentQuantity === this.quantityInCart;
    },
    quantityInCart: function () {
      const currentCheckout = this.currentCheckout;
      return currentCheckout ? currentCheckout.quantity : 0;
    },
    renderQuantityInCart: function () {
      return this.quantityInCart.toString();
    },
    keyInCart: function () {
      const currentCheckout = this.currentCheckout;
      return currentCheckout ? currentCheckout.key : null;
    },
    currentCheckout: function () {
      let current = null;
      if (this.checkout) {
        this.checkout.items.forEach((item) => {
          item.positions.forEach((position) => {
            if (position.buyable_id === this.buyableId && position.buyable_class === this.buyable) {
              current = position;
            }
          });
        });
      }

      return current;
    },
    renderQuantity() {
      return this.currentQuantity === null ? '' : this.currentQuantity.toString();
    },
    getQuantity() {
      return this.currentQuantity || 0;
    },
  },
  methods: {
    ...mapActions({
      'cartAdd': 'cart/add',
      'cartSetQuantity': 'cart/setQuantity',
      'cartAddOrSet': 'cart/addOrSet',
      'cartRemove': 'cart/remove',
    }),
    initCurrentQuantity() {
      this.setCurrentQuantity(this.quantityInCart);
    },
    setCurrentQuantity(quantity) {
      if (quantity > this.maxQuantity) {
        quantity = this.maxQuantity;
      } else if (quantity < 0) {
        quantity = 0;
      }

      this.currentQuantity = quantity;
    },
    add() {
      this.cartAdd( {
        buyable: this.buyable,
        id: this.buyableId,
        quantity: this.currentQuantity,
      });
    },
    remove() {
      const currentCheckout = this.currentCheckout;
      if (currentCheckout) {
        this.cartRemove({ key: currentCheckout.key });
        this.setCurrentQuantity(0);
      } else {
      //  TODO ERROR
      }
    },
    addOrSet() {
      const currentCheckout = this.currentCheckout;
      if (currentCheckout) {
        this.applyQuantity();
      } else {
        this.add();
      }
    },
    applyQuantity() {
      const currentCheckout = this.currentCheckout;
      if (currentCheckout) {
        this.cartSetQuantity({
          key: currentCheckout.key,
          quantity: this.currentQuantity
        });
      } else {
      //  TODO ЕРРОР
      }
    },
    inputQuantity(e) {
      let value = e.amount;
      if (value) {
        this.setCurrentQuantity(value);
      } else {
        this.currentQuantity = null;
      }
    },
    increase() {
      this.setCurrentQuantity(this.currentQuantity + 1);
    },
    decrease() {
      this.setCurrentQuantity(this.currentQuantity - 1);
    },
  },
  mounted() {
    this.initCurrentQuantity();
  }
}
</script>