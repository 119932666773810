<template>
  <div class="sort-field" @click="handleClick()">
    <div class="sort-field__inputs">
      <div class="sort-field__input-wrap">
        <input
            type="radio"
            class="sort-field__input"
            :name="getInputName"
            :id="upId"
            :value="upValue"
        >
        <div class="sort-field__arrow sort-field__arrow_up"></div>
      </div>
      <div class="sort-field__input-wrap">
        <input
            type="radio"
            class="sort-field__input"
            :name="getInputName"
            :id="downId"
            :value="upValue"
        >
        <div class="sort-field__arrow sort-field__arrow_down"></div>
      </div>
    </div>

    <div class="sort-field__label">
      {{ label }}
    </div>
  </div>
</template>

<script>

export default {
  name: "BaseFieldSort",
  components: {

  },
  data: () => {
    return {
      up: false,
      down: false,
    };
  },
  props: {
    // Название группы радио кнопок
    name: {
      type: String,
      required: true
    },
    // Ключ для value
    fieldKey: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    currentValue: {
      type: String,
      required: false
    }
  },
  computed: {
    getInputName() {
      return `sort_${this.name}`;
    },
    upValue() {
      return `${this.fieldKey}_up`;
    },
    downValue() {
      return `${this.fieldKey}_down`;
    },
    upId() {
      return `${this.getInputName}_${this.upValue}`;
    },
    downId() {
      return `${this.getInputName}_${this.downValue}`;
    }
  },
  methods: {
    handleClick() {
      if (this.currentValue === this.upValue) {
        this.$emit('change', this.downValue);
        document.querySelector('#' + this.downId).checked = 'checked';
      } else if (this.currentValue === this.downValue) {
        this.$emit('change', null);
        document.querySelector('#' + this.downId).checked = '';
      } else {
        this.$emit('change', this.upValue);
        document.querySelector('#' + this.upId).checked = 'checked';
      }
    }
  }
}
</script>
