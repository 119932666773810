import { onDomChanges } from '../../components/dynamic/observer';

class AllowEditableInput {
  constructor(element) {
    element.dataset.initialized = 'true';
    this.isEditable = false;
    this.element = element;
    this.link = this.element.querySelector('[data-allow-editable-link]');

    this.bind();
  }

  bind() {
    this.bindAllowLink();
  }

  bindAllowLink() {
    if (this.link) {
      this.link.addEventListener('click', () => {
        this.element.classList.remove('_disabled');
        this.element.getElementsByTagName('input')[0].focus();
      });
    }
  }
}

function initAllowEditableInputs() {
  const fields = document.querySelectorAll('[data-allow-editable-input]:not([data-initialized="true"])');
  fields.forEach((field) => new AllowEditableInput(field));
}

onDomChanges(() => {
  setTimeout(initAllowEditableInputs, 400)
});