var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-state-block", class: { _hide: _vm.isHide } },
    [
      _c("div", { staticClass: "container container_spacer" }, [
        _c("div", { staticClass: "cart-state-block__inner grid-block" }, [
          _c("div", { staticClass: "cart-state-block__count-wrapper" }, [
            _vm._v(
              "\n        Выбрано " + _vm._s(_vm.count) + " товаров\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cart-state-block__total-wrapper" }, [
            _c("div", { staticClass: "cart-state-block__total-text" }, [
              _vm._v("\n          Сумма заказа:\n        "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cart-state-block__total-number" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.formatPrice(_vm.total)) +
                  " ₽\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cart-state-block__button-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "cart-state-block__button button",
                attrs: { href: "/cart" },
              },
              [
                _c(
                  "span",
                  { staticClass: "cart-state-block__button-icon" },
                  [_c("cart-icon")],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "cart-state-block__button-text" }, [
                  _vm._v("\n            Перейти в корзину\n          "),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "cart-state-block__button-arrow" },
                  [_c("arrow-right")],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }