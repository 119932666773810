var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "10",
        height: "10",
        viewBox: "0 0 10 10",
        fill: "none",
      },
    },
    [
      _c("circle", { attrs: { cx: "5", cy: "5", r: "5", fill: "#CAC9CE" } }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "4.57141",
          y: "2",
          width: "0.857143",
          height: "6",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "8",
          y: "4.57141",
          width: "0.857143",
          height: "6",
          transform: "rotate(90 8 4.57141)",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }