<template>
  <div class="stat-graph">
    <div class="stat-graph__title" v-if="title">
      {{ title }}
    </div>
    <form class="stat-graph__form">
      <base-field-date
          :label="'От'"
          class="stat-graph__field _date"
          :input-id="getFieldId('from')"
          @change="change"
          v-model="formData.dateFrom"
          :value="formData.dateFrom"
      />

      <base-field-date
          :label="'До'"
          class="stat-graph__field _date"
          :input-id="getFieldId('to')"
          @change="change"
          v-model="formData.dateTo"
          :value="formData.dateTo"
      />

      <base-field-select
          :values="productTypes"
          :field-id="getFieldId('product_type')"
          :label="'Категория'"
          :noDefault="true"
          v-model="formData.productType"
          :current-value="formData.productType"
          @input="change"
      />
    </form>

      <div class="stat-graph__content">
        <div class="stat-graph__graph-wrap" v-if="graphData.data">

          <div class="stat-graph__graph">
            <stat-graph-line
                :data="graphData.data"
                :options="graphData.options"
                :bus="bus"
                @updateLegend="updateLegend"
            />
          </div>

          <div class="stat-graph__legend-wrap">
            <div class="stat-graph__legend" v-html="graphData.legend"></div>
          </div>

        </div>

        <Preloader v-else-if="graphData.isLoading" class="stat-graph__preloader"/>

        <div v-else class="stat-graph__empty">
          Нет данных за указанный период
        </div>
      </div>
  </div>
</template>

<script>
import BaseFieldDate from "../../parts/fields/BaseFieldDate.vue";
import getLastMonthRange from "../../../helpers/dates";
import Preloader from "../../parts/Preloader.vue";
import legendCallback from "../../../helpers/chart-legend";
import Vue from "vue";
import StatGraphLine from "./StatGraphLine.vue";
import {mapActions} from "vuex";
import {object} from "underscore";
import BaseFieldSelect from "../../parts/fields/BaseFieldSelect.vue";

export default {
  name: "ProductTypeGraph",
  components: {
    BaseFieldDate,
    StatGraphLine,
    Preloader,
    BaseFieldSelect
  },
  props: {
    graphKey: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    bus: {
      type: Vue,
      required: false
    },
    initialRange: {
      type: Object,
      required: false
    }
  },
  data(){
    return {
      formData: {
        dateFrom: null,
        dateTo: null,
        productType: '10'
      },
      graphData: {
        isLoading: false,
        legend: '',
        data: null,
        options: {
          type: 'line',
          steppedLine: true,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'day',
              },
            }],
          },
          legend: false,
          legendCallback
        }
      },
      productTypes: [
        {
          name: 'Легковые шины',
          value: '10'
        },
        {
          name: 'Грузовые шины',
          value: '20'
        },
        {
          name: 'Мото шины',
          value: '30'
        },
        {
          name: 'Легковые диски',
          value: '40'
        },
        {
          name: 'Грузовые диски',
          value: '50'
        },
        {
          name: 'Прочее',
          value: '60'
        },
      ]

    }
  },
  methods: {
    ...mapActions('stat', {
      'loadStatData': 'loadStatData'
    }),
    getFieldId(fieldKey) {
      return `${this.graphKey}_${fieldKey}`;
    },
    change(e) {
      this.initGraph()
    },
    updateLegend(legend) {
      this.graphData.legend = legend;
    },
    async initGraph() {
      this.graphData.isLoading = true;
      this.graphData.data = null;

      const chartData = await this.update();

      if (chartData && chartData.length) {
        this.setGraphDatasets(chartData);
      }

      this.graphData.isLoading = false;
    },
    async update() {
      return await this.loadStatData({
        statDataKey: this.graphKey,
        params: this.formData
      });
    },
    setGraphDatasets(chartData) {
      if (chartData) {
        this.graphData.data = {
          datasets: chartData.map((line) => {
            return {
              label: line.label,
              data: line.data.map((point) => {
                return {
                  x: new Date(point.x),
                  y: point.y
                };
              })
            };
          })
        }
      } else {
        this.graphData.data = null;
      }

    }
  },
  mounted() {
    let range = null;
    if (this.initialRange) {
      range = this.initialRange;
    } else {
      range = getLastMonthRange();
    }

    this.formData.dateFrom = range.from;
    this.formData.dateTo = range.to;

    const inputFrom = document.querySelector(`#${this.getFieldId('from')}`);
    const inputTo = document.querySelector(`#${this.getFieldId('to')}`);

    inputFrom.value = range.from;
    inputTo.value = range.to;

    this.initGraph();
  }
}
</script>
