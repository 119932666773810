var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M4.4 10a.8.8 0 0 0-.8-.8h-.8a.8.8 0 0 0 0 1.6h.8a.8.8 0 0 0 .8-.8Zm.512 4-.568.568a.8.8 0 0 0 0 1.128.8.8 0 0 0 1.128 0l.568-.568A.8.8 0 0 0 4.912 14ZM10 4.4a.8.8 0 0 0 .8-.8v-.8a.8.8 0 0 0-1.6 0v.8a.8.8 0 0 0 .8.8Zm4.528 1.872a.8.8 0 0 0 .56-.232l.568-.568a.8.8 0 1 0-1.128-1.128L14 4.912a.8.8 0 0 0 0 1.128.8.8 0 0 0 .528.232Zm-9.6-.232a.8.8 0 0 0 1.128 0 .8.8 0 0 0 0-1.128l-.568-.568a.803.803 0 1 0-1.144 1.128l.584.568ZM17.2 9.2h-.8a.8.8 0 1 0 0 1.6h.8a.8.8 0 1 0 0-1.6ZM15.088 14A.8.8 0 0 0 14 15.088l.568.568a.8.8 0 0 0 1.128 0 .8.8 0 0 0 0-1.128L15.088 14ZM10 5.6a4.4 4.4 0 1 0 4.4 4.4A4.408 4.408 0 0 0 10 5.6Zm0 7.2a2.8 2.8 0 1 1 0-5.6 2.8 2.8 0 0 1 0 5.6Zm0 2.8a.8.8 0 0 0-.8.8v.8a.8.8 0 1 0 1.6 0v-.8a.8.8 0 0 0-.8-.8Z",
          fill: "#FFB905",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }