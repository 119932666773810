import { render, staticRenderFns } from "./ArrowLongLeft.vue?vue&type=template&id=0852984a&"
import script from "./ArrowLongLeft.vue?vue&type=script&lang=js&"
export * from "./ArrowLongLeft.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/rclass/crm_shlepakov/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0852984a')) {
      api.createRecord('0852984a', component.options)
    } else {
      api.reload('0852984a', component.options)
    }
    module.hot.accept("./ArrowLongLeft.vue?vue&type=template&id=0852984a&", function () {
      api.rerender('0852984a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "static/vue/components/icons/ArrowLongLeft.vue"
export default component.exports