<template>
  <div class="notify-settings">
    <a href="javascript:void(0);"
       title="Настроить оповещения"
       class="notify-settings__link"
       @click="openSettings"
    >
      <span class="notify-settings__icon">
          <GearIcon/>
      </span>
      <span class="notify-settings__title h2">
        Настроить оповещения
      </span>
    </a>

    <popup
      :opened="isModalOpened('notify_settings')"
      :is-processing="isProcessing('notify_settings')"
      @close="closeModal('notify_settings')"
    >
      <div class="notify-settings-page">
        <h2 class="notify-settings-page__title">
          Настройка оповещений
        </h2>

        <form action="" class="notify-settings-page__form notify-settings-page__form_email">
          <div class="notify-settings-page__grid">
            <div class="notify-settings-page__label text__main">
              Укажите e-mail на который вы хотите получать оповещения
            </div>

            <div class="notify-settings-page__fields">
              <input
                type="text"
                placeholder="E-mail"
                class="notify-settings-page__text-input"
                v-model="email"
              >
            </div>
          </div>
        </form>

        <form action="" class="notify-settings-page__form notify-settings-page__form_order" @submit.prevent="saveOrderStatuses">
          <div class="notify-settings-page__grid">
            <div class="notify-settings-page__form-name">
              Оповещения по заказам
            </div>
            <div class="notify-settings-page__fields">
              <base-field-switch
                label="Получать оповещения о смене статуса заказа"
                input-id="enable-order-notify"
                v-model="orders.enable"
              />

              <base-field-checkbox-list
                :possible-values="orders.avail_statuses"
                :current-values="orders.current_statuses"
                v-model="orders.current_statuses"
                input-id="order-statuses"
                :disabled-values="disableStatuses"
              />
            </div>
          </div>

          <div class="notify-settings-page__grid">
            <button class="notify-settings-page__button button">
              Сохранить настройки
            </button>
          </div>
        </form>

        <form action="" class="notify-settings-page__form notify-settings-page__form_product" @submit.prevent="saveProductsEvents">
          <div class="notify-settings-page__grid">
            <div class="notify-settings-page__form-name">
              Оповещения по товарам
            </div>
          </div>

          <div class="notify-settings-page__grid">
            <div class="notify-settings-page__point">
              <div class="notify-settings-page__point-number">
                1
              </div>
              <div class="notify-settings-page__point-text">
                Выберите фильтр
              </div>
            </div>

            <div class="notify-settings-page__fields">
              <ul class="notify-settings-page__tabs tabs tabs_small">
                <li class="tabs__tab" :class="{'_active': personalIsActive}">
                  <a href="javascript:void(0);" class="tabs__tab-link" @click="setCurrentTab('personal')">
                    Мои фильтры
                  </a>
                </li>
                <li class="tabs__tab" :class="{'_active': generalIsActive}">
                  <a href="javascript:void(0);" class="tabs__tab-link" @click="setCurrentTab('general')">
                    Общие фильтры
                  </a>
                </li>
              </ul>

              <base-field-select
                  v-if="currentList && currentList.length"
                  :values="currentList"
                  :current-value="selectedFilter"
                  v-model="selectedFilter"
                  field-id="filter_list"
                  :no-default="true"
                  @input="handleSelect"
              />
              <div class="notify-settings-page__empty" v-else>
                Нет доступных фильтров
              </div>
            </div>
          </div>

          <div class="notify-settings-page__grid" v-if="selectedFilter">
            <div class="notify-settings-page__point">
              <div class="notify-settings-page__point-number">
                2
              </div>
              <div class="notify-settings-page__point-text">
                Укажите какие оповещения вы хотели бы получать
              </div>
            </div>

            <div class="notify-settings-page__fields">
              <base-field-checkbox-list
                  :possible-values="products.avail_events"
                  :currentValues="products.current_events"
                  input-id="event_list"
                  :no-default="true"
                  v-model="products.current_events"
              />
            </div>
          </div>

          <div class="notify-settings-page__grid" v-if="selectedFilter">
            <div class="notify-settings-page__point">
              <div class="notify-settings-page__point-number">
                3
              </div>
              <div class="notify-settings-page__point-text">
                Укажите дату, до которой нужно получать оповещения
              </div>
            </div>
            <div class="notify-settings-page__fields">
              <BaseFieldDate
                  label=""
                  input-id="end_at"
                  v-model="products.end_at"
                  :current-value="products.end_at"
                  @change="changeDate"
              />
            </div>
          </div>

          <div class="notify-settings-page__grid" v-if="selectedFilter && availReserve">
            <div class="notify-settings-page__point">
              <div class="notify-settings-page__point-number">
                4
              </div>
              <div class="notify-settings-page__point-text">
                Укажите количество для автоматического резервирования
              </div>
            </div>
            <div class="notify-settings-page__fields">
              <input
                  type="text"
                  placeholder="0"
                  class="notify-settings-page__text-input"
                  v-model="products.reserve_count"
              >
            </div>
          </div>

          <div class="notify-settings-page__grid" v-if="selectedFilter">
            <button class="notify-settings-page__button button">
              Сохранить настройки
            </button>
          </div>
        </form>
      </div>
    </popup>
  </div>
</template>

<script>
import GearIcon from '../icons/GearIcon.vue';
import modalMixin from '../../mixins/modal';
import Popup from '../common/Popup.vue';
import BaseFieldSwitch from '../parts/fields/BaseFieldSwitch.vue';
import BaseFieldCheckboxList from '../parts/fields/BaseFieldCheckboxList.vue';
import BaseFieldSelect from '../parts/fields/BaseFieldSelect.vue';
import {mapActions, mapMutations, mapState} from "vuex";
import BaseFieldDate from "../parts/fields/BaseFieldDate.vue";

export default {
  name: "TheNotifySettings",
  components: {
    BaseFieldCheckboxList,
    GearIcon,
    Popup,
    BaseFieldSwitch,
    BaseFieldSelect,
    BaseFieldDate,
  },
  data: () => {
    return {
      currentTab: 'personal',
      selectedFilter: null,
    }
  },
  mixins: [
    modalMixin,
  ],
  computed: {
    ...mapState('notifySettings', {
      'email': 'email',
      'orders': 'orders',
      'products': 'products',
    }),
    ...mapState('targetClient', {
      'managerLoaded': 'loaded'
    }),
    disableStatuses() {
      return this.orders.enable ? [] : this.orders.avail_statuses.map((status) => status.value);
    },
    hasPersonal() {
      return !!this.products.filters.personal.length;
    },
    hasGeneral() {
      return !!this.products.filters.general.length;
    },
    personalIsActive() {
      return this.currentTab === 'personal';
    },
    generalIsActive() {
      return this.currentTab === 'general';
    },
    currentList() {
      return this.currentTab ? this.products.filters[this.currentTab] : null;
    },
    currentFilter() {
      return this.currentList.find((filter) => filter.value === this.selectedFilter);
    },
    availReserve() {
      return this.managerLoaded && this.products.current_events.includes('50');
    }
  },
  methods: {
    ...mapActions('notifySettings', {
      'loadInitialState': 'loadInitialState',
      'loadCurrentProductsEvents': 'loadCurrentProductsEvents',
      'saveCurrentProductsEvents': 'saveCurrentProductsEvents',
      'saveCurrentOrderStatuses': 'saveCurrentOrderStatuses',
    }),
    ...mapMutations('notifySettings', {
      'setEndAt': 'setEndAt'
    }),
    async changeDate(value) {
      this.setEndAt(value);
    },
    async saveProductsEvents() {
      this.setProcessing('notify_settings');
      const currentFilter = this.currentFilter;
      if (currentFilter) {
        await this.saveCurrentProductsEvents(currentFilter);
      }
      this.unsetProcessing('notify_settings');
    },
    async saveOrderStatuses() {
      this.setProcessing('notify_settings');
      await this.saveCurrentOrderStatuses();
      this.unsetProcessing('notify_settings');
    },
    async openSettings() {
      this.openModal('notify_settings');
      this.setProcessing('notify_settings');

      await this.loadInitialState();
      this.defineCurrentTab();

      this.unsetProcessing('notify_settings');
    },
    defineCurrentTab() {
      if (this.hasPersonal) {
        this.setCurrentTab('personal');
      } else if (this.hasGeneral) {
        this.setCurrentTab('general');
      } else {
        this.setCurrentTab(null);
      }
    },
    setCurrentTab(tab) {
      this.currentTab = tab;
      if (tab) {
        this.defineCurrentSelect();
      }
    },
    async defineCurrentSelect() {
      if (this.currentList && this.currentList.length) {
        this.selectedFilter = this.currentList[0].value;
      } else {
        this.selectedFilter = null;
      }

      await this.handleSelect();
    },
    async handleSelect() {
      if (this.selectedFilter) {
        const currentFilter = this.currentFilter;
        if (currentFilter) {
          this.setProcessing('notify_settings');
          await this.loadCurrentProductsEvents(currentFilter);
          this.unsetProcessing('notify_settings');
        }
      }
    }
  }
}
</script>