import axios from 'axios';

const CartService = {
  async fetchCheckout() {
    return axios.get('/cart/api/index');
  },
  async add(buyable, id, quantity) {
    return axios.post('/cart/api/add', {
      buyable,
      id,
      quantity,
    });
  },
  async addOrSet(buyable, id, quantity) {
    return axios.post('/cart/api/add_or_set', {
      buyable,
      id,
      quantity,
    });
  },
  async remove(key) {
    return axios.post('/cart/api/remove', {
      key,
    });
  },
  async increase(key, amount) {
    return axios.post('/cart/api/increase', {
      key,
      amount,
    });
  },
  async decrease(key, amount) {
    return axios.post('/cart/api/decrease', {
      key,
      amount,
    });
  },
  async setQuantity(key, quantity) {
    return axios.post('/cart/api/quantity', {
      key,
      quantity,
    });
  },
  async setPrice(key, price) {
    return axios.post('/cart/api/price', {
      key,
      price,
    });
  },
};

export default CartService;