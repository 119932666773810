var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("picture", { class: _vm.pictureClass }, [
    _c("source", {
      attrs: {
        srcset:
          _vm.getImageUrl("_webp") +
          " 1x, " +
          _vm.getImageUrl("_webp_2x") +
          " 2x",
        type: "image/webp",
      },
    }),
    _vm._v(" "),
    _c("img", {
      class: _vm.imageClass,
      attrs: {
        srcset: _vm.getImageUrl("_2x") + " 2x, " + _vm.getImageUrl("") + " 1x",
        alt: _vm.alt,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }