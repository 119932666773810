<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.23047 8.23047L8.65904 13.159L15.2305 4.23047" stroke="#70A500" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: "CheckIcon"
}
</script>