<template>
  <div class="catalog-auto-filter-extend">
    <div class="catalog-auto-filter-extend__info-wrap">
      <div class="catalog-auto-filter-extend__modification-name">
        {{ currentModification.name }}
      </div>
      <div class="catalog-auto-filter-extend__info-list">
        <div class="catalog-auto-filter-extend__info-item" v-for="info in currentModification.info">
          <div class="catalog-auto-filter-extend__info-name">
            {{ info.name }}
          </div>
          <div class="catalog-auto-filter-extend__info-value">
            {{ info.value }}
          </div>
        </div>
      </div>
    </div>
    <form class="catalog-auto-filter-extend__fields-wrap" @submit="submit">
      <div class="catalog-auto-filter-extend__top">
        <div class="catalog-auto-filter-extend__field" v-for="field in getTopFields">
          <BaseFieldSelectMulti
              v-if="field.view_type === 'select-multi'"
              :values="field.values"
              :field-id="field.key"
              :label="field.label"
              :currentValues="field.current_value"
              :disable-values="field.disable_values"
              :has-filter="field.has_filter"
              @input="debounceChange"
          />

          <BaseRangeSlider
              v-else-if="field.view_type === 'range'"
              :field-id="field.key"
              :label="field.label"
              :borders="field.borders"
              :state-value="field.current_value"
              :disabled="field.disabled"
              @change="change"
          />
        </div>
      </div>

      <div class="catalog-auto-filter-extend__middle">
        <div class="catalog-auto-filter-extend__field" v-for="field in getMiddleFields" :class="'_' + field.key">
          <BaseFieldSelectMulti
              v-if="field.view_type === 'select-multi'"
              :values="field.values"
              :field-id="field.key"
              :label="field.label"
              :currentValues="field.current_value"
              :disable-values="field.disable_values"
              :has-filter="field.has_filter"
              @input="debounceChange"
          />
          <BaseFieldCheckboxList
              v-if="field.view_type === 'checkbox-list'"
              :input-id="field.key"
              :current-values="field.current_value"
              :possible-values="field.values"
              :disabled-values="field.disable_values"
              @change="debounceChange"
          />
          <BaseFieldCheckbox
              v-if="field.view_type === 'checkbox'"
              :label="field.label"
              :input-id="field.key"
              :value="field.current_value"
              v-model="field.current_value"
              :disabled="field.disabled"
              @change="change(field)"
          />
          <BaseRangeSlider
              v-else-if="field.view_type === 'range'"
              :field-id="field.key"
              :label="field.label"
              :borders="field.borders"
              :state-value="field.current_value"
              :disabled="field.disabled"
              @change="change"
          />
        </div>
      </div>

      <div class="catalog-auto-filter-extend__bottom">
        <div class="catalog-auto-filter-extend__bottom-fields">
          <div class="catalog-auto-filter-extend__field" v-for="field in getBottomFields">
            <BaseFieldCheckbox
                v-if="field.view_type === 'checkbox'"
                :label="field.label"
                :input-id="field.key"
                :value="field.current_value"
                v-model="field.current_value"
                :disabled="field.disabled"
                @change="change(field)"
            />
          </div>
        </div>

        <div class="catalog-auto-filter-extend__buttons">
          <button @click="reset" class="catalog-auto-filter-extend__button button__secondary">
            Сбросить фильтры
          </button>
          <button
              type="submit"
              class="catalog-auto-filter-extend__button"
              :disabled="disableSubmit"
          >
            Применить фильтры
          </button>
        </div>
      </div>

    </form>

    <preloader v-if="loading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BaseFieldSelectMulti from "../parts/fields/BaseFieldSelectMulti.vue";
import BaseFieldCheckboxList from '../parts/fields/BaseFieldCheckboxList.vue';
import BaseFieldCheckbox from '../parts/fields/BaseFieldCheckbox.vue';
import BaseRangeSlider from '../parts/fields/BaseRangeSlider.vue';
import Preloader from "../parts/Preloader.vue";


export default {
  name: "TheFilterAutoParam",
  components: {
    Preloader,
    BaseFieldCheckboxList,
    BaseFieldCheckbox,
    BaseFieldSelectMulti,
    BaseRangeSlider
  },
  computed: {
    ...mapState('autoParam', {
      'currentModification': 'currentModification',
      'fields': 'paramFields',
      'loading': 'loading'
    }),
    ...mapState('catalogFilter', {
      'totalProducts': 'totalProducts'
    }),
    ...mapGetters('catalogFilter', {
      'hideStorages': 'hideStorages'
    }),
    disableSubmit() {
      return !this.totalProducts;
    },
    getTopFields() {
      return this.fields.filter((field) => field.group === 'main-top');
    },
    getMiddleFields() {
      return this.fields
          .filter((field) => {
            if (field.group !== 'main-bottom') {
              return false;
            }

            if (field.view_type === 'checkbox-list' && field.values.length <= 1) {
              return false;
            }

            if (field.key === 'storage' && this.hideStorages) {
              return false;
            }

            // if (field.view_type === 'range')
            return true;
          });
          // .filter((field) => field.group === 'main-bottom')
          // .filter((field) => (field.view_type !== 'checkbox-list' || field.values.length > 1));
    },
    getBottomFields() {
      return this.fields.filter((field) => field.group === 'main-additional')
    }
  },
  methods: {
    ...mapActions('autoParam', {
      'updatePossibleFields': 'updatePossibleFields',
      'debounceUpdatePossibleFields': 'debounceUpdatePossibleFields',
      'fetchProducts': 'fetchProducts',
      'resetFields': 'resetFields',
      'handleCustomField': 'handleCustomField',
    }),
    async reset() {
      await this.resetFields();
      await this.fetchProducts();
    },
    change(field) {
      if (field.data_type === 'custom') {
        this.handleCustomField(field);
      } else {
        this.updatePossibleFields();
      }
    },
    debounceChange(e) {
      this.debounceUpdatePossibleFields();
    },
    submit(e) {
      e.preventDefault();
      if (this.totalProducts) {
        this.fetchProducts();
      }
    },
  }
}
</script>

<style lang="scss">

</style>