var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "catalog-filter-extend",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "ul",
        {
          staticClass: "catalog-filter-extend__accordion-list",
          attrs: {
            "data-accordion-container": "",
            "data-accordion-mode": "multi",
          },
        },
        _vm._l(_vm.accordionFields, function (field) {
          return _c(
            "li",
            {
              staticClass: "catalog-filter-extend__accordion-item",
              attrs: {
                "data-accordion-element": "",
                "data-accordion-field": field.key,
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "catalog-filter-extend__accordion-link",
                  attrs: {
                    href: "javascript:void(0);",
                    "data-accordion-link": "",
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "catalog-filter-extend__accordion-text" },
                    [
                      _vm._v(
                        "\n          " + _vm._s(field.label) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(0, true),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "catalog-filter-extend__accordion-block",
                  attrs: { "data-accordion-block": "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "catalog-filter-extend__accordion-block-inner",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "catalog-filter-extend__filter-values-block",
                        },
                        [
                          field.view_type === "dynamic-checkbox-list"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: field.contains_filter,
                                    expression: "field.contains_filter",
                                  },
                                ],
                                staticClass:
                                  "catalog-filter-extend__text-input",
                                attrs: {
                                  id: field.key,
                                  type: "text",
                                  autocomplete: "off",
                                  placeholder: "Поиск",
                                },
                                domProps: { value: field.contains_filter },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        field,
                                        "contains_filter",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.debounceChangeWithReaccordion(
                                        field.key
                                      )
                                    },
                                  ],
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          field.view_type === "dynamic-checkbox-list" &&
                          field.filtered_count > 50
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "catalog-filter-extend__filter-values-count",
                                },
                                [
                                  _vm._v(
                                    "\n              50 из " +
                                      _vm._s(field.filtered_count) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          field.mode_label
                            ? _c("BaseFieldSwitch", {
                                attrs: {
                                  "input-id": field.key + "_mode",
                                  label: field.mode_label,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.activateSwitch(field.key)
                                  },
                                },
                                model: {
                                  value: field.mode_value,
                                  callback: function ($$v) {
                                    _vm.$set(field, "mode_value", $$v)
                                  },
                                  expression: "field.mode_value",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      field.key === "storage"
                        ? _c("StorageCheckboxList", {
                            attrs: {
                              "input-id": field.key,
                              "current-values": field.current_value,
                              "possible-values": field.values,
                              "disabled-values": field.disable_values,
                              "check-all": true,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.debounceChange()
                              },
                            },
                          })
                        : _vm.isAccordionCheckBoxList(field)
                        ? _c("BaseFieldCheckboxListChunked", {
                            attrs: {
                              "input-id": field.key,
                              "current-values": field.current_value,
                              "possible-values": field.values,
                              "disabled-values": field.disable_values,
                              "has-range": field.has_range,
                              bus: _vm.bus,
                            },
                            on: {
                              change: function ($event) {
                                field.key === "technology"
                                  ? _vm.change()
                                  : _vm.debounceChange()
                              },
                              recomputedColumns: _vm.reaccordionField,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.totalPreloader ? _c("preloader") : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "catalog-filter-extend__ending-wrapper" }, [
        _c(
          "div",
          { staticClass: "catalog-filter-extend__ending-fields" },
          _vm._l(_vm.endingFields, function (field) {
            return _c(
              "div",
              {
                staticClass: "catalog-filter-extend__ending-field",
                class: { _checkbox: field.view_type === "checkbox" },
              },
              [
                field.view_type === "range"
                  ? _c("BaseRangeSlider", {
                      attrs: {
                        "field-id": field.key,
                        label: field.label,
                        borders: field.borders,
                        "state-value": field.current_value,
                        disabled: field.disabled,
                      },
                      on: { change: _vm.change },
                    })
                  : _vm._e(),
                _vm._v(" "),
                field.view_type === "checkbox"
                  ? _c("BaseFieldCheckbox", {
                      attrs: {
                        label: field.label,
                        "input-id": field.key,
                        value: field.current_value,
                        disabled: field.disabled,
                      },
                      on: { change: _vm.change },
                      model: {
                        value: field.current_value,
                        callback: function ($$v) {
                          _vm.$set(field, "current_value", $$v)
                        },
                        expression: "field.current_value",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "catalog-filter-extend__button-wrapper" }, [
          _c(
            "button",
            {
              staticClass:
                "catalog-filter-extend__button button button__secondary",
              attrs: { type: "button" },
              on: { click: _vm.resetFilters },
            },
            [_vm._v("\n        Сбросить фильтры\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "catalog-filter-extend__button button",
              attrs: { type: "submit", disabled: _vm.disableSubmit },
            },
            [_vm._v("\n        Применить фильтры\n      ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "catalog-filter-extend__accordion-icon" },
      [
        _c("span", { staticClass: "catalog-filter-extend__icon-line" }),
        _vm._v(" "),
        _c("span", { staticClass: "catalog-filter-extend__icon-line" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }