import Vue from 'vue';
import { onDomChanges } from '../components/dynamic/observer';
import store from './store';

import CatalogOrderBlock from './components/cart/CatalogOrderBlock.vue';
import TheCartStateBlock from './components/cart/TheCartStateBlock.vue';
import TheCartWidget from './components/cart/TheCartWidget.vue';
import TheCartCountWidget from './components/cart/TheCartCountWidget.vue';
import TheCartPage from './components/cart/TheCartPage.vue';
import TheCatalogFilter from './components/catalog/TheCatalogFilter.vue';
import TheCatalogClientSelector from './components/catalog/TheCatalogClientSelector.vue';
import TheCatalogListHeader from './components/catalog/TheCatalogListHeader.vue';
import TheNotifications from './components/notification/TheNotifications.vue';
import TheNotifySettings from './components/notification/TheNotifySettings.vue';
import MarkupTree from './components/tree/Markup/MarkupTree.vue';
import IncomingTree from './components/tree/Incoming/IncomingTree.vue';
import TheStatPage from './components/stat/TheStatPage.vue';
import PriceMove from './components/stat/PriceMove.vue';

function createCatalogOrderBlocks(element) {
  element.dataset.initialize = 'true';

  const data = {
    currentQuantity: 0,
    maxQuantity: parseInt(element.dataset.max, 10) || 1,
    buyable: element.dataset.buyable,
    buyableId: parseInt(element.dataset.id, 10),
  };

  const template = { ...CatalogOrderBlock };
  template.data = () => data;

  return new Vue({
    el: element,
    store,
    render: (h) => h(template),
  });
}

function createPriceMovieBlock(element) {
  element.dataset.initialize = 'true';
  const template = { ...PriceMove };
  const templateData = template.data();

  const data = {
    productData: {
      productClass: element.dataset.productClass,
      productId: parseInt(element.dataset.productId, 10),
    },
  };

  template.data = () => ({ ...templateData, ...data });

  return new Vue({
    el: element,
    store,
    render: (h) => h(template),
  });
}

function formatPrice(rawValue) {
  const value = parseFloat(rawValue);
  // const value = parseInt(rawValue, 10);
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

// Плашка в каталоге с данными по корзине
const cartStateBlockElement = document.querySelector('[data-cart-state-block]');
if (cartStateBlockElement) {
  const cartStateBlockVm = new Vue({
    el: cartStateBlockElement,
    store,
    render: (h) => h(TheCartStateBlock),
    methods: {
      formatPrice,
    },
  });
}

// Иконка корзины в шапке
const cartWidgets = document.querySelectorAll('[data-cart-widget]');
if (cartWidgets.length) {
  cartWidgets.forEach((element) => {
    new Vue({
      store,
      el: element,
      render: (h) => h(TheCartWidget),
    });
  });
}

// Счетчики в корзины в меню
const cartCountWidgets = document.querySelectorAll('[data-cart-count-widget]');
if (cartCountWidgets.length) {
  cartCountWidgets.forEach((element) => {
    new Vue({
      store,
      el: element,
      render: (h) => h(TheCartCountWidget),
    });
  });
}

// Страница корзины
if (document.querySelector('#cart-page')) {
  new Vue({
    store,
    el: '#cart-page',
    render: (h) => h(TheCartPage),
    methods: {
      formatPrice,
    },
  });
}

if (document.querySelector('#notifications')) {
  new Vue({
    store,
    el: '#notifications',
    render: (h) => h(TheNotifications),
  });
}

// Заголовки колонок в каталоге. Сортировки
const headerElement = document.querySelector('#catalog-list-header');
if (headerElement) {
  new Vue({
    store,
    el: '#catalog-list-header',
    render: (h) => h(TheCatalogListHeader),
  });
}

const catalogClientSelectorElement = document.querySelector('#catalog-client-selector');
if (catalogClientSelectorElement) {
  new Vue({
    store,
    el: catalogClientSelectorElement,
    render: (h) => h(TheCatalogClientSelector),
  });
}

// Фильтр в каталоге
const filterElement = document.querySelector('#catalog-filter');
if (filterElement) {
  const TheTemplate = TheCatalogFilter;
  TheTemplate.data = () => ({
    settings: {
      hasAuto: filterElement.dataset.hasAuto !== undefined,
      productType: filterElement.dataset.productType,
      filterMode: filterElement.dataset.filterMode,
      categoryId: filterElement.dataset.categoryId,
      hideStorages: filterElement.dataset.hideStorages === 'true',
      prevFilterType: filterElement.dataset.prevFilterType,
    },
  });

  new Vue({
    store,
    el: '#catalog-filter',
    render: (h) => h(TheTemplate),
  });
}

const statPageElement = document.querySelector('#stat-page');
if (statPageElement) {
  new Vue({
    store,
    el: statPageElement,
    render: (h) => h(TheStatPage),
  });
}

const notifySettingsElement = document.querySelector('#notify-settings');
if (notifySettingsElement) {
  new Vue({
    store,
    el: notifySettingsElement,
    render: (h) => h(TheNotifySettings)
  });
}

// Деревья условий наценок
const markupTreeElements = document.querySelectorAll('[data-markup-tree]');
if (markupTreeElements.length > 0) {
  markupTreeElements.forEach((treeElement) => {
    new Vue({
      store,
      el: treeElement,
      render: (h) => {
        return h(MarkupTree, {
          props: {
            treeId: treeElement.dataset.markupTree,
            context: JSON.parse(treeElement.dataset.markupTreeContext),
            baseUrl: treeElement.dataset.markupTreeUrl,
          },
        });
      },
    });
  });
}
const incomingTreeElements = document.querySelectorAll('[data-incoming-tree]');
if (incomingTreeElements.length > 0) {
  incomingTreeElements.forEach((treeElement) => {
    new Vue({
      store,
      el: treeElement,
      render: (h) => {
        return h(IncomingTree, {
          props: {
            treeId: treeElement.dataset.incomingTree,
            context: JSON.parse(treeElement.dataset.incomingTreeContext),
            baseUrl: treeElement.dataset.incomingTreeUrl,
          },
        });
      },
    });
  });
}

let currentBlocks = [];
let priceMovies = [];
onDomChanges(() => {
  currentBlocks.filter((block) => !block.$el.offsetHeight).forEach((block) => block.$destroy());
  currentBlocks = currentBlocks.filter((block) => block.$el.offsetHeight);
  document.querySelectorAll('[data-catalog-order-block]:not([data-initialize="true"])').forEach((element) => {
    currentBlocks.push(createCatalogOrderBlocks(element));
  });

  priceMovies.filter((block) => !block.$el.offsetHeight).forEach((block) => block.$destroy());
  priceMovies = currentBlocks.filter((block) => block.$el.offsetHeight);
  document.querySelectorAll('[data-price-moves]:not([data-initialize="true"])').forEach((element) => {
    priceMovies.push(createPriceMovieBlock(element));
  });
});