import axios from 'axios';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const link = document.querySelector('[data-read-all-mark]');
  if (link) {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      axios.post('/notifications/api/read_mark');
    });
  }
});