class FieldsUpdater {
  static updateFields(stateFields, responseFields) {
    const result = [];
    stateFields.forEach((stateField) => {
      const responseField = responseFields.find((field) => field.key === stateField.key);
      if (responseField) {
        stateField.disabled = responseField.disabled;

        switch (stateField.view_type) {
          case 'range':
            stateField = this.handleRangeField(stateField, responseField);
            break;
          case 'dynamic-checkbox-list':
            stateField = this.handleDynamicCheckboxList(stateField, responseField);
            break;
          default:
            stateField = this.handleDefault(stateField, responseField);
        }
      }

      result.push(stateField);
    });

    return result;
  }

  static handleDefault(stateField, responseField) {
    if (stateField.values.length) { // Выставляем дисейблы
      const disableValues = [];
      const newValueList = responseField.values.map((item) => item.value);
      stateField.values.forEach((item) => {
        if (!newValueList.includes(item.value)) {
          disableValues.push(item.value);
        }
      });

      stateField.disable_values = disableValues;
    }

    return stateField;
  }

  static handleDynamicCheckboxList(stateField, responseField) {
    stateField.values = responseField.values;
    stateField.filtered_count = responseField.filtered_count;
    const newValueList = responseField.values.map((item) => item.value);
    const newCurrentValue = [];
    stateField.current_value.forEach((value) => {
      if (newValueList.includes(value)) {
        newCurrentValue.push(value);
      }
    });
    stateField.current_value = newCurrentValue;

    return stateField;
  }

  static handleRangeField(stateField, responseField) {
    // Равны ли старые значения старым границам
    const fromIsOldBorder = stateField.current_value.from === stateField.borders.from;
    const toIsOldBorder = stateField.current_value.to === stateField.borders.to;

    const { borders } = responseField;
    stateField.borders = borders;

    // Вылазят ли старые значения за новые границы
    const fromIsOut = stateField.current_value.from < borders.from
      || stateField.current_value.from > borders.to;

    const toIsOut = stateField.current_value.to > borders.to
      || stateField.current_value.to < borders.from;

    if (fromIsOut || fromIsOldBorder) {
      stateField.current_value.from = borders.from;
    }

    if (toIsOut || toIsOldBorder) {
      stateField.current_value.to = borders.to;
    }

    return stateField;
  }
}

export default FieldsUpdater;