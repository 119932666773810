var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tree" },
    [
      _vm.treeIsLoading
        ? _c("div", {
            staticStyle: {
              width: "30px",
              height: "30px",
              "background-color": "red",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.treeIsLoading
        ? _c("TreeNodes", {
            attrs: {
              nodes: _vm.treeGetTree.nodes,
              treeId: _vm.treeId,
              "parent-nodes": [],
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var node = ref.node
                    return [_vm._t("default", null, { node: node })]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "tree__save button",
          attrs: { href: "javascript:void(0);", title: "Сохранить" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    Сохранить\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }