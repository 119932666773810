var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__content" }, [
    _c("ul", { staticClass: "stat-page__tabs tabs tabs_black" }, [
      _c(
        "li",
        {
          staticClass: "tabs__item",
          class: { _active: _vm.currentTargetType === "products" },
        },
        [
          _c(
            "a",
            {
              staticClass: "tabs__link",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.setCurrentTargetType("products")
                },
              },
            },
            [_vm._v("\n        Товары\n      ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "tabs__item",
          class: { _active: _vm.currentTargetType === "offers" },
        },
        [
          _c(
            "a",
            {
              staticClass: "tabs__link",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.setCurrentTargetType("offers")
                },
              },
            },
            [_vm._v("\n        Предложения\n      ")]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-page__content-wrap" },
      [
        _vm.currentTargetType === "products"
          ? _c("StatProducts", { attrs: { "product-type": _vm.categoryKey } })
          : _vm._e(),
        _vm._v(" "),
        _vm.currentTargetType === "offers"
          ? _c("StatOffers", { attrs: { "product-type": _vm.categoryKey } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }