class Header {
  constructor(element, type) {
    this.element = element;
    this.type = type;
    this.isEnable = false;
    this.isOnlyMobile = ['mobile'].includes(type);
    this.isOnlyDesktop = ['general'].includes(type);
  }

  enable() {
    this.headerHeight = this.element.offsetHeight;
    this.container = this.element.querySelector('[data-header-container]');

    this.headerInner = this.element.querySelector('[data-header-inner]');
    this.headerTop = this.element.querySelector('[data-header-top]');
    this.headerBottom = this.element.querySelector('[data-header-bottom]');

    this.isEnable = true;
  }

  disable() {
    this.isEnable = false;
  }

  defineSticky(scrollTop) {
    const instance = this;

    if (scrollTop > 0) {
      this.element.classList.add('_sticky');
    } else {
      instance.element.classList.remove('_sticky');
    }
  }

  translate(scrollTop, lastScrollTop) {
    if (this.type === 'general') {
      this.translateGeneral(scrollTop, lastScrollTop);
    } else {
      this.translateDefault(scrollTop, lastScrollTop);
    }
  }

  translateGeneral(scrollTop, lastScrollTop) {
    if (scrollTop < lastScrollTop) { // up
      if (scrollTop < this.headerTop.offsetHeight) {
        // скроллтоп в зоне верхней части шапки
        this.container.style.transition = '';
        this.container.style.position = 'absolute';
        this.container.style.transform = '';
      } else if (scrollTop > this.headerTop.offsetHeight && scrollTop < this.headerHeight) {
        // скроллтоп в зоне нижней части шапки
        if (!this.element.classList.contains('_up')) {
          this.container.style.transition = '';
          this.container.style.position = 'fixed';
          this.container.style.transform = `translate3d(0, -${scrollTop}px, 0)`;
        }
      } else if (scrollTop > this.headerHeight) {
        // скроллтоп ниже высоты шапки
        this.element.classList.add('_up');
        this.container.style.transform = `translate3d(0, -${this.headerTop.offsetHeight}px, 0)`;
      }
    } else { // down
      this.element.classList.remove('_up');

      if (scrollTop < this.headerTop.offsetHeight) {
        // скроллтоп в зоне верхней части шапки
        this.container.style.position = 'absolute';
        this.container.style.transform = '';
      } else if (scrollTop > this.headerTop.offsetHeight && scrollTop < this.headerHeight) {
        // скроллтоп в зоне нижней части шапки
        this.container.style.position = 'fixed';
        this.container.style.transform = `translate3d(0, -${scrollTop}px, 0)`;
      } else if (scrollTop > this.headerHeight) {
        // скроллтоп ниже высоты шапки
        this.container.style.position = 'fixed';
        this.container.style.transform = 'translate3d(0, -100%, 0)';
        this.container.style.transition = 'transform .3s linear';
      }
    }
  }

  translateDefault(scrollTop, lastScrollTop) {
    const fixedElement = this.type === 'mobile' ? this.container : this.element;
    if (scrollTop < lastScrollTop) {
      // up
      if (scrollTop > this.headerHeight) {
        // скролтоп ниже высоты шапки
        this.element.classList.add('_up');
        fixedElement.style.transform = 'translate3d(0, 0, 0)';
      }

      if (scrollTop <= this.headerHeight) {
        // скролтоп в зоне высоты шапки
        if (!this.element.classList.contains('_up')) {
          fixedElement.style.transform = `translate3d(0, -${scrollTop}px, 0)`;
        }
      }
    } else { // down
      this.element.classList.remove('_up');

      if (scrollTop <= this.headerHeight) {
        // скролтоп в зоне высоты шапки
        fixedElement.style.transform = `translate3d(0, -${scrollTop}px, 0)`;
        fixedElement.style.transition = '';
      } else {
        // скролтоп ниже высоты шапки
        fixedElement.style.transform = 'translate3d(0, -100%, 0)';
        fixedElement.style.transition = 'transform .3s linear';
      }
    }
  }
}

export default Header;