var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__content" }, [
    _c("div", { staticClass: "stat-page__inner-content" }, [
      _c(
        "div",
        { staticClass: "stat-page__row" },
        [
          _c("key-value-table", {
            staticClass: "stat-page__table",
            attrs: {
              name: "Количество доступных товаров",
              tableKey: "total_products_by_categories_table",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "stat-page__graph stat-page__graph_end" },
            [
              _c("BaseGraph", {
                attrs: { "graph-key": "products_by_categories_graph" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stat-page__row" },
        [
          _c("key-value-table", {
            staticClass: "stat-page__table",
            attrs: {
              name: "Количество доступных предложений",
              tableKey: "total_offers_by_categories_table",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "stat-page__graph stat-page__graph_end" },
            [
              _c("BaseGraph", {
                attrs: { "graph-key": "offers_by_categories_graph" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stat-page__row" },
        [
          _c("key-value-table", {
            staticClass: "stat-page__table",
            attrs: {
              name: "Количество доступных поставщиков",
              tableKey: "total_providers_by_categories_table",
            },
          }),
          _vm._v(" "),
          _c("key-value-table", {
            staticClass: "stat-page__table",
            attrs: {
              name: "Количество доступных складов",
              tableKey: "total_storages_by_categories_table",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }