import Vue from 'vue';
import TreeService from '../../services/tree.service';

const treeModule = {
  namespaced: true,
  state: {
    trees: [],
  },
  getters: {
    isLoading(state) {
      return (id) => state.trees[id].loading;
    },
    getItem(state) {
      return (id) => state.trees[id].tree;
    },
    isExtend(state) {
      return (id) => (state.trees[id] ? state.trees[id].tree.extend : false);
    },
    getTree(state) {
      return (id) => state.trees[id].tree;
    },
    getBaseUrl(state) {
      return (id) => state.trees[id].baseUrl;
    },
    getContext(state) {
      return (id) => state.trees[id].context;
    },
    getFieldMetadata(state) {
      return (id, fieldName) => {
        let metadataField = null;
        state.trees[id].metadata.fields.forEach((field) => {
          if (field.name === fieldName) {
            metadataField = field;
          }
        });
        return metadataField;
      };
    },
    getFields(state) {
      return (id) => {
        if (!state.trees[id]) {
          return [];
        }
        return state.trees[id].metadata.fields;
      };
    },
    getPreloadedFields(state) {
      return (id, fieldName) => {
        if (state.trees[id].tree.preloadedFieldsValues.length > 0
          && state.trees[id].tree.preloadedFieldsValues.find((field) => field.fieldName === fieldName)) {
          return state.trees[id].tree.preloadedFieldsValues
            .find((field) => field.fieldName === fieldName)
            .values;
        }
        return [];
      };
    },
  },
  actions: {
    async fetchTree({ state, dispatch, commit }, { id, context, baseUrl }) {
      commit('createTree', { id, context, baseUrl });
      commit('setLoading', {
        id,
        loading: true,
      });
      await dispatch('loadTree', id);
      await dispatch('loadMetadata', id);
      commit('setLoading', {
        id,
        loading: false,
      });
    },
    async loadTree({ state, dispatch, commit, getters }, id) {
      const treeResponse = await TreeService.fetch(
        getters.getBaseUrl(id),
        getters.getContext(id),
      );
      if (treeResponse.status === 200) {
        commit('setTree', {
          id,
          tree: treeResponse.data,
        });
      } else {
        throw new Error('Incorrect response from server');
      }
    },
    async loadMetadata({ state, dispatch, commit, getters }, id) {
      const metadataResponse = await TreeService.metadata(
        getters.getBaseUrl(id),
        getters.getContext(id),
      );
      if (metadataResponse.status === 200) {
        commit('setMetadata', {
          id,
          metadata: metadataResponse.data,
        });
      } else {
        throw new Error('Incorrect response from server');
      }
    },
    async search({ state, dispatch, commit, getters }, data) {
      const context = getters.getContext(data.id);
      const searchResponse = await TreeService.search(
        getters.getBaseUrl(data.id),
        { ...context, field: data.fieldName, query: data.query, parentNodes: data.parentNodes },
      );
      if (searchResponse.status === 200) {
        return searchResponse.data;
      }
      throw new Error('Incorrect response from server');
    },
    async saveTree({ state, dispatch, commit, getters }, data) {
      const context = getters.getContext(data.id);
      const searchResponse = await TreeService.save(
        getters.getBaseUrl(data.id),
        { ...context, tree: getters.getTree(data.id) },
      );
      if (searchResponse.status === 200) {
        return 'Save success';
      }
      throw new Error('Incorrect response from server');
    },
    async validateFormula({}, data) {
      try {
        const result = await TreeService.validateFormula(data);
        if (result.data.success) {
          return true;
        }
        if (result.data.error) {
          console.log(result.data.error);
        }
      } catch (e) {
        console.log(e);
      }

      return false;
    },
  },
  mutations: {
    createTree(state, { id, context, baseUrl }) {
      Vue.set(state.trees, id, {
        context,
        baseUrl,
        loading: true,
        tree: {},
        metadata: {},
      });
    },
    setTree(state, { id, tree }) {
      state.trees[id].tree = tree;
    },
    setMetadata(state, { id, metadata }) {
      state.trees[id].metadata = metadata;
    },
    setLoading(state, { id, loading }) {
      state.trees[id].loading = loading;
    },
  },
};

export default treeModule;