var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-order-block" },
    [
      _c("cart-quantity-panel", {
        attrs: {
          "render-quantity": _vm.renderQuantity,
          placeholderValue: _vm.renderQuantityInCart,
          cartKey: _vm.keyInCart,
          maxValue: _vm.maxQuantity,
        },
        on: {
          increase: _vm.increase,
          decrease: _vm.decrease,
          inputQuantity: _vm.inputQuantity,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "catalog-order-block__button-wrap" }, [
        _vm.showOrderButton
          ? _c(
              "button",
              {
                staticClass:
                  "catalog-order-block__action catalog-order-block__action_add button button__cart",
                attrs: { type: "button" },
                on: { click: _vm.addOrSet },
              },
              [_c("cart-icon")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showAlreadyButton
          ? _c(
              "button",
              {
                staticClass:
                  "catalog-order-block__action catalog-order-block__action_already",
                attrs: { type: "button" },
              },
              [_c("check-icon")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showRemoveButton
          ? _c(
              "button",
              {
                staticClass:
                  "catalog-order-block__action catalog-order-block__action_remove",
                attrs: { type: "button" },
                on: { click: _vm.remove },
              },
              [_c("remove-icon")],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }