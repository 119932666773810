<template>
  <picture :class="pictureClass">
    <source
        :srcset="`${getImageUrl('_webp')} 1x, ${getImageUrl('_webp_2x')} 2x`"
        type="image/webp"
    >
    <img
        :srcset="`${getImageUrl('_2x')} 2x, ${getImageUrl('')} 1x`"
        :alt="alt"
        :class="imageClass">
  </picture>
</template>

<script>
export default {
  name: "ThumbImage",
  props: {
    imageUrl: {
      type: String
    },
    thumbGroupName: {
      type: String
    },
    thumbName: {
      type: String
    },
    alt: {
      type: String,
      required: false
    },
    pictureClass: {
      type: String,
      required: false
    },
    imageClass: {
      type: String,
      required: false
    },
  },
  computed: {

  },
  methods: {
    getImageUrl(type) {
      return `/thumbs/thumbs/thumb?file=${this.imageUrl}&thumb_group=${this.thumbGroupName}&thumb=${this.thumbName}${type}`;
    }
  }
}
</script>

<style lang="scss">

</style>