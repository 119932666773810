<template>
  <div v-if="conditions"
       :class="{'tree-condition-set': true, 'tree-condition-set_multiple': hasSubConditions, 'tree-condition-set_root': rootConditionSet}"
  >
    <template v-if="hasSubConditions">
      <div class="tree-condition-set__conditions-wrapper" v-for="(condition, key) in conditions.subConditions"
           :key="key">
        <TreeConditionSet
            :treeId="treeId"
            :conditions="condition"
            :id="key"
            :parent-nodes="parentNodes"
            @last="lastCondition"
            @remove="removeSubItem(key)"
        ></TreeConditionSet>

        <div class="tree-condition-set__condition-logic" v-if="key !== lastKey">
          <select v-model="conditions.subConditionsOperator">
            <option value="and">и</option>
            <option value="or">или</option>
          </select>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="tree-condition-set__conditions-wrapper tree-condition-set__conditions-wrapper_single">
        <TreeCondition :treeId="treeId"
                       :condition="conditions.treeCondition"
                       :allowRemove="isAllowRemoveCondition"
                       :parent-nodes="parentNodes"
                       @split="split" @remove="remove"/>
      </div>
    </template>

    <div class="appender" v-if="hasSubConditions || rootConditionSet">
      <a href="javascript:void(0);" class="append-condition" @click.prevent="append">
        <span class="text">
            + Добавить условие
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import TreeCondition from "./TreeCondition.vue";
import tree from "../../../mixins/tree";

export default {
  name: "TreeConditionSet",
  components: {
    TreeCondition
  },
  props: {
    treeId: String,
    conditions: Object,
    rootConditionSet: Boolean,
    parentNodes: Array,
  },
  mixins: [
    tree
  ],
  computed: {
    lastKey: function () {
      return this.conditions.subConditions.length - 1;
    },
    hasSubConditions() {
      return this.conditions.subConditions && this.conditions.subConditions.length > 0;
    },
    isAllowRemoveCondition() {
      return !this.rootConditionSet
    }
  },
  methods: {
    lastCondition: function (key) {
      var conditions = this.conditionsData.conditions[key];
      var lastCondition = conditions.conditions[0];
      this.$set(this.conditionsData.conditions, key, lastCondition);
    },
    getDefaultCondition() {
      const fields = this.treeGetFields();
      if (!fields.length) {
        return {}
      }
      const field = fields[0];
      const condition = field.allowedConditions[0];
      return {
        fieldName: field.name,
        condition: condition,
        value: this.isScalarValue(condition) ? '' : []
      }
    },
    append() {
      if (!this.conditions.subConditions || !this.conditions.subConditions.length) {
        this.$set(this.conditions, 'subConditions', []);
        this.$set(this.conditions, 'subConditionsOperator', 'and');
        this.conditions.subConditions.push({
          subConditionsOperator: 'and',
          subConditions: [],
          treeCondition: this.conditions.treeCondition
        });
      }
      this.conditions.subConditions.push({
        subConditionsOperator: 'and',
        subConditions: [],
        treeCondition: this.getDefaultCondition()
      });
    },
    split() {
      this.append();
    },
    remove() {
      this.$emit('remove')
    },
    removeSubItem(key) {
      if (this.conditions.subConditions.length <= 2) {
        // Один удаляем
        this.conditions.subConditions.splice(key, 1);
        // Последний схлопываем
        if (this.conditions.subConditions.length === 1) {
          this.$set(this.conditions, 'treeCondition', this.conditions.subConditions[0].treeCondition);
        } else {
          this.$set(this.conditions, 'treeCondition', this.getDefaultCondition());
        }
        this.$set(this.conditions, 'subConditions', []);
        this.$set(this.conditions, 'subConditionsOperator', 'and');
      } else {
        // Тупо удаляем
        this.conditions.subConditions.splice(key, 1);
      }
    }
  },
  mounted() {
    if (this.conditions && !this.conditions.treeCondition) {
      this.conditions.treeCondition = this.getDefaultCondition();
    }
  },
  updated() {
    if (this.conditions && !this.conditions.treeCondition) {
      this.conditions.treeCondition = this.getDefaultCondition();
    }
  }
}
</script>

<style lang="scss">

</style>