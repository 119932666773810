var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sort-field",
      on: {
        click: function ($event) {
          return _vm.handleClick()
        },
      },
    },
    [
      _c("div", { staticClass: "sort-field__inputs" }, [
        _c("div", { staticClass: "sort-field__input-wrap" }, [
          _c("input", {
            staticClass: "sort-field__input",
            attrs: { type: "radio", name: _vm.getInputName, id: _vm.upId },
            domProps: { value: _vm.upValue },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "sort-field__arrow sort-field__arrow_up" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sort-field__input-wrap" }, [
          _c("input", {
            staticClass: "sort-field__input",
            attrs: { type: "radio", name: _vm.getInputName, id: _vm.downId },
            domProps: { value: _vm.upValue },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "sort-field__arrow sort-field__arrow_down",
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sort-field__label" }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }