import { onDomReady } from '../../components/dynamic/observer';
import axios from 'axios';

class ConfirmPage {
    constructor(element) {
        this.page = element;
        this.url = element.dataset.url;
        this.init();
    }

    init() {
        const instance = this;
        setInterval(() => {
            axios.post(instance.url, { refresh: true });
        }, 2000);
    }
}
onDomReady(() => {
    const element = document.querySelector('[data-confirm-page]');
    if (element) {
        new ConfirmPage(element);
    }
});