<template>
  <div class="cart-position">
    <div class="cart-position__wrapper" >

      <div class="cart-position__prices-wrap" v-if="isAvailPosition">
        <div class="cart-position__storage text__small">
          {{ position.storage_name }}
        </div>

        <div class="cart-position__price-block">
          <input
              v-if="isCustomPrice"
              type="text"
              autocomplete="off"
              class="cart-position__price-input"
              :placeholder="position.base_item_price"
              :value="renderPrice"
              @input="inputPrice"
          >
  <!--        TODO: format price-->
          <div v-else class="cart-position__price-number text__small">
            {{ renderPrice }}&nbsp;
          </div>

          <div class="cart-position__price-measure text__small">
            ₽/шт.
          </div>

          <additional-prices
              v-if="isForInternalManager"
              class="cart-position__additional_prices"
              :prices="otherPrices"
          />
        </div>

        <div class="cart-position__panel">
          <cart-quantity-panel
              :cartKey="position.key"
              :renderQuantity="renderQuantity"
              :placeholderValue="renderQuantity"
              @increase="increase"
              @decrease="decrease"
              @inputQuantity="inputQuantity"
          />
        </div>

        <!--        TODO: format price-->
        <div class="cart-position__total text__small">
          {{ formatPrice(position.base_price) }}&nbsp;₽
        </div>
      </div>

      <div class="cart-position__blocked" v-else>
        <WarningIcon/>
        <div class="cart-position__blocked-text">
          Позиция недоступна для заказа
        </div>
      </div>
    </div>

    <!--TODO: delete button-->
    <div class="cart-position__remove-wrap">
      <a href="javascript:void(0);" @click="remove" class="cart-position__remove-icon">
        <RemoveIcon/>
      </a>
    </div>
  </div>
</template>

<script>
import CartQuantityPanel from './CartQuantityPanel.vue';
import RemoveIcon from '../icons/RemoveIcon.vue';
import WarningIcon from '../icons/WarningIcon.vue';
import AdditionalPrices from './AdditionalPrices.vue';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "CartPosition",
  components: {
    WarningIcon,
    RemoveIcon,
    CartQuantityPanel,
    AdditionalPrices
  },
  props: {
    position: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('targetClient', {
      'currentPurchaserId': 'currentPurchaserId',
      'currentClientId': 'currentClientId',
    }),
    ...mapGetters('cart', {
      'isForInternalManager': 'isForInternalManager'
    }),
    renderQuantity() {
      return this.position.quantity.toString();
    },
    renderPrice() {
      return this.formatPrice(this.position.base_item_price.toString());
    },
    isCustomPrice() {
      return !!this.currentPurchaserId;
    },
    isAvailPosition() {
      return this.position.base_item_price > 0;
    },
    otherPrices() {
      return [
        {
          'name': 'Закупочная цена',
          'value': this.position.wholesale_price
        },
        {
          'name': 'Входная цена',
          'value': this.position.incoming_price
        }
      ];
    }
  },
  methods: {
    ...mapActions('cart', {
      'increase': 'increase',
      'decrease': 'decrease',
      'setQuantity': 'setQuantity',
      'setPrice': 'setPrice',
      'cartRemove': 'remove'
    }),
    inputQuantity(e) {
      const key = this.position.key;
      let quantity = e.amount;
      if (quantity > 0) {
        e.sender.value = quantity;
        this.position.quantity = quantity;
        this.setQuantity({key, quantity});
      } else {
        e.sender.value = '';
      }
    },
    remove() {
      this.cartRemove({ key: this.position.key });
    },
    inputPrice(e) {
      const key = this.position.key;
      let value = e.target.value;
      value = value.replace(' ', '');
      if (value !== '') {
        value = parseInt(value, 10);
        if (!value) {
          value = '';
        }
      }

      if (value) {
        this.setPrice({key, price: value})
      }
    },
    formatPrice(rawValue) {
      return this.$root.formatPrice(rawValue);
    }
  }
}
</script>