var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "additional-prices" }, [
    _c(
      "div",
      { staticClass: "additional-prices__icon" },
      [_c("plus-circle")],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "additional-prices__prices-block" }, [
      _c(
        "ul",
        { staticClass: "additional-prices__list" },
        _vm._l(_vm.prices, function (price) {
          return _c("li", { staticClass: "additional-prices__item" }, [
            _c("div", { staticClass: "additional-prices__name" }, [
              _vm._v("\n          " + _vm._s(price.name) + "\n        "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "additional-prices__value" }, [
              _vm._v("\n          " + _vm._s(price.value) + " ₽/шт.\n        "),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }