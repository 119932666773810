<template>
  <div class="cart-client-selector">
    <ul class="smart-tabs tabs tabs_primary">
      <li class="smart-tab tabs__item" v-bind:class="{_active: isClientsTab}" v-if="hasClients">
        <a href="javascript:void(0);" class="tabs__link" @click="selectClientsTabs">
          Оптовый клиент
        </a>
      </li>
      <li class="smart-tab tabs__item" v-bind:class="{_active: isPurchasersTab}" v-if="hasPurchasers">
        <a href="javascript:void(0);" class="tabs__link" @click="selectPurchasersTabs">
          Покупатель
        </a>
      </li>
    </ul>

    <div class="cart-client-selector__select-wrap" v-if="isClientsTab">
      <div class="cart-client-selector__select-label">
        Выбрать клиента
      </div>
      <BaseFieldSelect
          :values="clients"
          :field-id="'client-selector'"
          :label="''"
          :currentValue="targetClientsInfo.managerState.targetClientId"
          v-model="targetClientsInfo.managerState.targetClientId"
          :default-value="{name: 'Не выбрано', value: null}"
          @input="selectClient"
      />
    </div>


    <div class="cart-client-selector__select-wrap" v-if="isPurchasersTab">
      <div class="cart-client-selector__select-label">
        Выбрать покупателя
      </div>
      <BaseFieldSelect
          :values="purchasers"
          :field-id="'purchaser-selector'"
          :label="''"
          :currentValue="targetClientsInfo.managerState.targetPurchaserId"
          v-model="targetClientsInfo.managerState.targetPurchaserId"
          :default-value="{name: 'Не выбрано', value: null}"
          @input="selectPurchaser"
      />
    </div>

  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from "vuex";
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";

export default {
  name: "TheCartClientSelector",
  data: function () {
    return {
      selectedClient: null,
      selectedPurchaser: null,
      currentTab: 'clients'
    }
  },
  components: {
    BaseFieldSelect
  },
  computed: {
    ...mapState('targetClient', {
      'targetClientsInfo': 'targetClientsInfo'
    }),
    ...mapGetters('targetClient', {
      'clients': 'clients',
      'purchasers': 'purchasers',
      'currentClientId': 'currentClientId',
      'currentPurchaserId': 'currentPurchaserId',
      'hasClients': 'hasClients',
      'hasPurchasers': 'hasPurchasers',
    }),
    isClientsTab() {
      return this.currentTab === 'clients';
    },
    isPurchasersTab() {
      return this.currentTab === 'purchasers'
    },
  },
  methods: {
    ...mapActions('targetClient', {
      'setClient': 'setClient',
      'setPurchaser': 'setPurchaser',
      'resetClient': 'resetClient',
      'resetPurchaser': 'resetPurchaser',
    }),
    selectClientsTabs() {
      this.currentTab = 'clients';
    },
    selectPurchasersTabs() {
      this.currentTab = 'purchasers';
    },
    selectClient(value) {
      if (value) {
        this.setClient({ clientId: value })
      } else {
        this.resetClient();
      }
    },
    selectPurchaser(value) {
      if (value) {
        this.setPurchaser({ purchaserId: value })
      } else {
        this.resetPurchaser();
      }
    }
  },
}
</script>
