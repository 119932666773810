import { onDomReady } from '../../components/dynamic/observer';
import Header from './header';

// general, mobile, index, identify
class HeaderManager {
  constructor() {
    // Собираем все шапки на странице
    const headerElements = document.querySelectorAll('[data-header]');

    if (!headerElements.length) {
      return;
    }

    this.headerObjects = [];
    this.isMobile = false;
    this.scrollTop = window.pageYOffset;

    headerElements.forEach((element) => {
      const type = element.dataset.header;
      this.headerObjects.push(new Header(element, type));
    });

    this.defineMobile();
    this.defineEnable();

    this.stickyElements = document.querySelectorAll('[data-sticky-element]');
    this.footer = document.querySelector('[data-footer]');

    this.lastScrollTop = 0;

    const bodyStyles = getComputedStyle(document.body);
    this.gridGap = parseInt(bodyStyles.getPropertyValue('--grid-gap'), 10);

    this.eventListeners();
  }

  eventListeners() {
    const instance = this;
    document.addEventListener('scroll', () => {
      instance.scrollTop = window.pageYOffset;
      instance.updateFooterPosition();

      this.headerObjects.forEach((header) => {
        if (header.isEnable) {
          header.defineSticky(instance.scrollTop);
          header.translate(instance.scrollTop, instance.lastScrollTop);
        }
      });

      // if (instance.isNotProfile) {
      //   instance.translateHeaderByFooter(instance.container, instance.headerHeight);
      // } else {
      //   instance.translateHeaderByFooter(instance.headerInner, this.headerBottom.offsetHeight);
      // }

      instance.lastScrollTop = instance.scrollTop;
    });

    window.addEventListener('resize', () => {
      instance.defineMobile();
      instance.defineEnable();
    });
  }

  handleStickyElements() {
    const instance = this;
    instance.stickyElements.forEach((item) => {
      item.style.transition = 'top .3s linear';
      if (instance.scrollTop < instance.lastScrollTop) {
        item.style.top = `${instance.headerBottom.offsetHeight + instance.gridGap}px`;
      } else {
        item.style.top = `${instance.gridGap}px`;
      }
    });
  }

  updateFooterPosition() {
    const instance = this;
    if (instance.footer) {
      instance.footerTop = instance.footer.getBoundingClientRect().top;
    }
  }

  translateHeaderByFooter(container, containerHeight) {
    const instance = this;
    if (instance.footer) {
      const offset = containerHeight - instance.footerTop;

      if (offset > 0) {
        container.style.transform = `translate3d(0, -${offset}px, 0)`;
      } else {
        container.style.transform = '';
      }
    }
  }

  defineMobile() {
    if (window.innerWidth >= 1280 && (this.isMobile || this.isMobile === null)) {
      this.isMobile = false;
    } else if (window.innerWidth < 1280 && !this.isMobile) {
      this.isMobile = true;
    }
  }

  defineEnable() {
    // Включение выключение в зависимости от разрешения и типа шапки
    this.headerObjects.forEach((header) => {
      if (header.isOnlyDesktop && this.isMobile || header.isOnlyMobile && !this.isMobile) {
        header.disable();
      } else {
        header.enable();
        header.defineSticky(this.scrollTop);
      }
    });
  }
}

onDomReady(() => {
  new HeaderManager();
});