var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkbox-list" },
    [
      _vm.label
        ? _c("label", { staticClass: "checkbox-list__main-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.possibleValues, function (param) {
        return _c("div", { staticClass: "checkbox-list__item" }, [
          _c("input", {
            staticClass: "checkbox-list__item",
            attrs: {
              type: "checkbox",
              id: _vm.uniqueId(_vm.inputId, _vm.valueAsString(param)),
              disabled: _vm.checkDisabled(_vm.valueAsString(param)),
            },
            domProps: {
              value: _vm.valueAsString(param),
              checked: _vm.shouldBeChecked(_vm.valueAsString(param)),
            },
            on: { change: _vm.updateInput },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "checkbox-list__label",
              attrs: {
                for: _vm.uniqueId(_vm.inputId, _vm.valueAsString(param)),
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.nameAsString(param)) + "\n\n      "
              ),
              param.icon
                ? _c(
                    "span",
                    { staticClass: "checkbox-list__icon" },
                    [
                      param.icon === "summer"
                        ? _c("SummerIcon")
                        : param.icon === "winter"
                        ? _c("WinterIcon")
                        : param.icon === "multi"
                        ? _c("MultiIcon")
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }