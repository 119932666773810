var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree-nodes" }, [
    _c(
      "div",
      { staticClass: "tree-nodes__list" },
      _vm._l(_vm.nodes, function (node, key) {
        return _c("TreeNode", {
          key: key,
          attrs: {
            node: node,
            index: key,
            treeId: _vm.treeId,
            "parent-node": _vm.parentNode,
            "parent-nodes": _vm.parentNodes,
          },
          on: {
            prepend: _vm.prependNode,
            remove: _vm.removeNode,
            split: _vm.splitNode,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (slotProps) {
                  return [_vm._t("default", null, { node: slotProps.node })]
                },
              },
            ],
            null,
            true
          ),
        })
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "tree-nodes__append",
        attrs: { href: "javascript:void(0)", title: "Добавить ветку" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.appendNode.apply(null, arguments)
          },
        },
      },
      [
        _c("span", { staticClass: "tree-node__icon-wrap" }, [
          _vm._v("\n            ➕\n        "),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }