<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <circle cx="5" cy="5" r="5" fill="#CAC9CE"/>
    <rect x="4.57141" y="2" width="0.857143" height="6" fill="white"/>
    <rect x="8" y="4.57141" width="0.857143" height="6" transform="rotate(90 8 4.57141)" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "PlusCircle"
}
</script>
