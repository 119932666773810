import { onDomChanges } from '../../components/dynamic/observer';
import {validatorCleanErrors, validatorValidateForm} from "../../components/forms/validation";

class ImagesField {
  constructor(element) {
    element.dataset.initialized = 'true';
    this.element = element;
    this.input = this.element.querySelector('[data-images-field-input]');
    this.icon = this.element.querySelector('[data-images-field-icon]');
    this.list = this.element.querySelector('[data-images-field-list]');
    this.accept = this.input.getAttribute('accept').split(',').map((item) => item.trim());
    this.maxSize = parseInt(this.input.dataset.imagesFieldMaxSize, 10);

    this.form = this.element.closest('form');

    this.errors = {
      size: 'Размер файла не должен превышать 5 Мб',
      format: 'Допустимые форматы: jpg, jpeg, png, pdf',
      count: 'Можно загрузить не более 5 файлов',
    };

    this.bind();
  }

  bind() {
    this.bindIcon();
    this.bindInput();
  }

  bindInput() {
    const instance = this;
    this.input.addEventListener('change', () => {
      validatorCleanErrors(instance.form, instance.form.dataset.formName);
      instance.updateList();
    });

    this.input.addEventListener('clear', () => instance.clear());
  }

  updateList() {
    const instance = this;
    this.filterImages();

    const { files } = this.input;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        instance.addImage(file, i);
      }
    }

    refreshFsLightbox();
  }

  filterImages() {
    const dt = new DataTransfer();
    const { files } = this.input;

    let errors = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let hasError = false;
      if (file) {
        if (file.size > this.maxSize) {
          hasError = true;
          if (!errors.includes('size')) {
            errors.push('size');
          }
        }

        if (!this.accept.includes(file.type)) {
          hasError = true;
          if (!errors.includes('format')) {
            errors.push('format');
          }
        }

        if (i > 4) {
          hasError = true;
          if (!errors.includes('count')) {
            errors.push('count');
          }
        }

        if (!hasError) {
          dt.items.add(file);
        }
      }
    }

    this.input.files = dt.files;

    if (errors.length) {
      errors = errors.map((item) => this.errors[item]);
      validatorValidateForm(this.form, this.form.dataset.formName, { files: errors });
    }
  }

  clear() {
    this.list.innerHTML = '';
    this.input.value = '';
  }

  removeFile(index) {
    const dt = new DataTransfer();
    const { files } = this.input;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file && index !== i) {
        dt.items.add(file);
      }
    }

    this.input.files = dt.files;
    this.list.innerHTML = '';
    this.updateList();
  }

  addImage(file, index) {
    const instance = this;
    const url = URL.createObjectURL(file);

    const a = document.createElement('a');



    let img = null;
    if (file.type === 'application/pdf') {
      img = document.createElement('span');
      img.classList.add('images-field__image_pdf');
      a.target = '_blank';
    } else {
      img = document.createElement('img');
      img.classList.add('images-field__image_img');
      img.src = url;

      a.dataset.fslightbox = 'chat';
    }

    img.classList.add('images-field__image');

    a.href = url;
    a.classList.add('images-field__link');
    a.append(img);

    const closer = document.createElement('a');
    closer.href = 'javascript:void(0)';
    closer.classList.add('images-field__closer');

    const li = document.createElement('li');
    li.classList.add('images-field__item');
    li.appendChild(a);
    li.appendChild(closer);

    this.list.appendChild(li);

    closer.addEventListener('click', (e) => {
      e.preventDefault();
      li.remove();
      instance.removeFile(index);
    });
  }

  bindIcon() {
    if (this.icon && this.input) {
      this.icon.addEventListener('click', () => {
        this.input.click();
      });
    }
  }
}
function init() {
  document.querySelectorAll('[data-images-field]:not([data-initialized="true"])')
    .forEach((field) => new ImagesField(field));
}

onDomChanges(init);