var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "16.5078",
          y: "2.36426",
          width: "2",
          height: "20",
          transform: "rotate(45 16.5078 2.36426)",
          fill: "#CAC9CE",
        },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "2.36523",
          y: "3.77832",
          width: "2",
          height: "20",
          transform: "rotate(-45 2.36523 3.77832)",
          fill: "#CAC9CE",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }