class FieldsSetter {
  static setFields(stateFields, currentData) {
    const excludeFields = ['enable_second_axis'];
    currentData.forEach((current) => {
      if (excludeFields.includes(current.key)) {
        return;
      }
      const isSecond = current.key.includes('second_');
      const key = isSecond ? current.key.replace('second_', '') : current.key;
      const stateField = stateFields.find((field) => field.key === key);

      if (stateField) {
        if (stateField.view_type === 'range') {
          const from = current.value.from;
          const to = current.value.to;
          const borders = stateField.borders;
          if (from !== null) {
            stateField.current_value.from = borders.from > from ? borders.from : from;
          }
          if (to !== null) {
            stateField.current_value.to = borders.to < to ? borders.to : to;
          }
        } else {
          if (isSecond) {
            stateField.current_second_value = current.value;
          } else {
            stateField.current_value = current.value;
          }
        }
      }
    });

    return stateFields;
  }
}

export default FieldsSetter;