<template>
  <div class="vue-field vue-field_switch">
    <input type="checkbox"
           :id="inputId"
           :disabled="disabled"
           :value="shouldBeChecked"
           :checked="shouldBeChecked"
           @change="change"
           :name="inputId"
           class="checkbox-switch"
    >
    <label :for="inputId">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseFieldSwitch",
  model: {
    prop: 'currentValue',
    event: 'change'
  },
  props: {
    inputId: {
      type: String,
      required: true
    },
    currentValue: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
  },
  computed: {
    shouldBeChecked() {
      // В нативном варианте значения true|false представлены как trueValue и falseValue, отсюда эта эмуляция нативности
      // Обратите внимание, что `true-value` и` false-value` являются camelCase в JS
      return this.currentValue === this.trueValue
    }
  },
  methods: {
    change(event) {
      // При изменении инпута передаем событие 'input', что бы связанный объект по v-mode принял изменения
      this.$emit('change', !this.currentValue);
    },
    updateInput(event) {
      // this.$emit('change', event.target.checked ? this.trueValue : this.falseValue)

    }
  }
}
</script>