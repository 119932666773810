var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-client-selector" }, [
    _c("ul", { staticClass: "smart-tabs tabs tabs_primary" }, [
      _vm.hasClients
        ? _c(
            "li",
            {
              staticClass: "smart-tab tabs__item",
              class: { _active: _vm.isClientsTab },
            },
            [
              _c(
                "a",
                {
                  staticClass: "tabs__link",
                  attrs: { href: "javascript:void(0);" },
                  on: { click: _vm.selectClientsTabs },
                },
                [_vm._v("\n        Оптовый клиент\n      ")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPurchasers
        ? _c(
            "li",
            {
              staticClass: "smart-tab tabs__item",
              class: { _active: _vm.isPurchasersTab },
            },
            [
              _c(
                "a",
                {
                  staticClass: "tabs__link",
                  attrs: { href: "javascript:void(0);" },
                  on: { click: _vm.selectPurchasersTabs },
                },
                [_vm._v("\n        Покупатель\n      ")]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.isClientsTab
      ? _c(
          "div",
          { staticClass: "cart-client-selector__select-wrap" },
          [
            _c("div", { staticClass: "cart-client-selector__select-label" }, [
              _vm._v("\n      Выбрать клиента\n    "),
            ]),
            _vm._v(" "),
            _c("BaseFieldSelect", {
              attrs: {
                values: _vm.clients,
                "field-id": "client-selector",
                label: "",
                currentValue: _vm.targetClientsInfo.managerState.targetClientId,
                "default-value": { name: "Не выбрано", value: null },
              },
              on: { input: _vm.selectClient },
              model: {
                value: _vm.targetClientsInfo.managerState.targetClientId,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.targetClientsInfo.managerState,
                    "targetClientId",
                    $$v
                  )
                },
                expression: "targetClientsInfo.managerState.targetClientId",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isPurchasersTab
      ? _c(
          "div",
          { staticClass: "cart-client-selector__select-wrap" },
          [
            _c("div", { staticClass: "cart-client-selector__select-label" }, [
              _vm._v("\n      Выбрать покупателя\n    "),
            ]),
            _vm._v(" "),
            _c("BaseFieldSelect", {
              attrs: {
                values: _vm.purchasers,
                "field-id": "purchaser-selector",
                label: "",
                currentValue:
                  _vm.targetClientsInfo.managerState.targetPurchaserId,
                "default-value": { name: "Не выбрано", value: null },
              },
              on: { input: _vm.selectPurchaser },
              model: {
                value: _vm.targetClientsInfo.managerState.targetPurchaserId,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.targetClientsInfo.managerState,
                    "targetPurchaserId",
                    $$v
                  )
                },
                expression: "targetClientsInfo.managerState.targetPurchaserId",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }