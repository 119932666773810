<template>
  <div class="vue-range-slider" :class="{'_disabled': disabled}">
    <label v-if="label" class="vue-range-slider__label">{{ label }}</label>
    <div class="vue-range-slider__inputs">
      <input type="text"
             class="vue-range-slider__input"
             @input="(e) => inputValue(e, 0)"
             autocomplete="off"
             :value="renderValue[0]">
      <input type="text"
             class="vue-range-slider__input"
             @input="(e) => inputValue(e, 1)"
             autocomplete="off"
             :value="renderValue[1]">
    </div>

    <div class="vue-range-slider__slider">
      <vue-slider
          v-model="currentValue"
          :min="lazyMin"
          :max="lazyMax"
          :dotSize="12"
          :tooltip="'none'"
          @drag-start="dragStart"
          @drag-end="dragEnd"
          :ref="ref"/>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: "BaseRangeSlider",
  components: {
    VueSlider
  },
  props: {
    fieldId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    borders: {
      type: Object,
      required: true
    },
    stateValue: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentValue: [this.stateValue.from, this.stateValue.to],
      lazyMin: this.borders.from,
      lazyMax: this.borders.to,
      isDragging: false,
    }
  },
  computed: {
    renderValue() {
      return this.isDragging ? this.currentValue : [this.stateValue.from, this.stateValue.to];
    },
    ref() {
      return `range_${this.fieldId}`;
    }
  },
  methods: {
    dragStart() {
      this.isDragging = true;
    },
    dragEnd() {
      this.commitCurrentValue();
      this.isDragging = false;
    },
    inputValue(e, index) {
      let value = e.target.value;
      if (value !== '') {
        value = value.replace(/[^0-9]/, '')
        value = parseInt(value, 10);
        if (value) {
          if (value >= this.borders.from && value <= this.borders.to) {
            this.currentValue[index] = value;
            this.$refs[this.ref].setValue(this.currentValue);
            this.commitCurrentValue();
          }
        } else {
          value = '';
        }
      }

      e.target.value = value;
    },
    commitCurrentValue() {
      this.isDragging = true;
      this.stateValue.from = this.currentValue[0];
      this.stateValue.to = this.currentValue[1];
      this.$emit('change', {fieldKey: this.fieldId, value: this.currentValue});
      this.isDragging = false;
    },
  },
  watch: {
    borders: {
      deep: true,
      handler() {
        const minValue = this.currentValue[0] < this.borders.from ? this.currentValue[0] : this.borders.from;
        const maxValue = this.currentValue[1] > this.borders.to ? this.currentValue[1] : this.borders.to;
        //
        this.lazyMin = minValue;
        this.lazyMax = maxValue;

        // У слайдера пролаг после установки границ
        setTimeout(() => {
          // Равны ли старые значения старым границам
          // const fromIsOut = this.currentValue[0] < this.borders.from || this.currentValue[0] > this.borders.to;
          // const toIsOut = this.currentValue[1] > this.borders.to || this.currentValue[1] < this.borders.from;

          // if (fromIsOut) {
          //   this.currentValue[0] = this.borders.from;
          // }
          // if (toIsOut) {
          //   this.currentValue[1] = this.borders.to;
          // }

          this.lazyMin = this.borders.from;
          this.lazyMax = this.borders.to;

          // this.$refs[this.ref].setValue(this.currentValue);
          this.currentValue[0] = this.stateValue.from;
          this.currentValue[1] = this.stateValue.to;
          this.$refs[this.ref].setValue([this.stateValue.from, this.stateValue.to]);
        }, 500)
      },
    },
  },
  mounted() {
    // document.addEventListener('ResetCatalogFilter', () => {
    //   console.log(this.currentValue)
      // this.$refs[this.ref].setValue(this.currentValue);
    // })
  }
}
</script>
