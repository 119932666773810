<template>
  <Tree :treeId="treeId" :baseUrl="baseUrl" :context="context" v-slot="{ node }">
    <MarkupContent :node="node" :treeId="treeId"/>
  </Tree>
</template>

<script>
import Tree from '../Base/Tree.vue';
import MarkupContent from './MarkupContent.vue';
import {mapGetters} from "vuex";

export default {
  name: "MarkupTree",
  components: {
    Tree,
    MarkupContent
  },
  props: {
    treeId: String,
    context: Object,
    baseUrl: String
  },
}
</script>

<style lang="scss">

</style>