<template>
  <span class="cart-widget-count">
    <span class="cart-widget-count__count" v-if="count">
      {{ count }}
    </span>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TheCartCountWidget",
  computed: {
    ...mapGetters('cart', {
      'count': 'countProducts',
    })
  },
  methods: {
    ...mapActions('cart', {
      'loadCheckout': 'loadCheckout',
    })
  },
  mounted() {
    this.loadCheckout();
  }
}
</script>
