<template>
  <div class="vue-preloader">
    <div class="vue-preloader__preloader"></div>
  </div>
</template>

<script>
export default {
  name: "Preloader"
}
</script>
