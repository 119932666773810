<template>
    <div class="tree">
        <!-- Прелоадер типа -->
        <div style="width: 30px; height: 30px;background-color: red;" v-if="treeIsLoading"></div>
        <TreeNodes v-if="!treeIsLoading"
                   :nodes="treeGetTree.nodes"
                   :treeId="treeId"
                   :parent-nodes="[]"
                   v-slot="{ node }"
        >
            <slot :node="node"></slot>
        </TreeNodes>

      <a href="javascript:void(0);"
         title="Сохранить"
         class="tree__save button"
         @click.prevent="save"
      >
        Сохранить
      </a>
    </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import tree from "../../../mixins/tree";
import TreeNodes from "./TreeNodes.vue";

export default {
  name: "Tree",
  mixins: [
    tree,
  ],
  components: {
    TreeNodes
  },
  props: {
    treeId: String,
    context: Object,
    baseUrl: String
  },
  methods: {
    ...mapActions('tree', {
      'fetchTree': 'fetchTree',
      'saveTree': 'saveTree'
    }),
    save() {
      this.saveTree({ id: this.treeId });
    },
  },
  created() {
    this.fetchTree({
      id: this.treeId,
      context: this.context,
      baseUrl: this.baseUrl
    })
  }
}

</script>

<style lang="scss">

</style>