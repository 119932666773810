import ManagerService from '../../services/manager.service';

const TargetClientModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    targetClientsInfo: {
      clients: [],
      purchasers: [],
      managerState: {
        targetClientId: null,
        targetPurchaserId: null,
      },
    },
  },
  getters: {
    info: (state) => state.targetClientsInfo || null,
    clients: (state, getters) => getters.info.clients || [],
    purchasers: (state, getters) => getters.info.purchasers || [],
    managerState: (state, getters) => getters.info.managerState || null,
    currentClientId: (state, getters) => getters.managerState.targetClientId || null,
    currentPurchaserId: (state, getters) => getters.managerState.targetPurchaserId || null,
    hasClients: (state, getters) => !!getters.clients.length,
    hasPurchasers: (state, getters) => !!getters.purchasers.length,
  },
  actions: {
    async loadTargetClientsInfo({ state, dispatch, commit }) {
      if (!state.loaded && !state.loading) {
        commit('setLoading', true);
        await dispatch('fetchTargetClientsInfo');
        commit('setLoaded', true);
        commit('setLoading', false);
      }
    },
    async fetchTargetClientsInfo({ state, dispatch, commit }) {
      try {
        const result = await ManagerService.getClientsInfo();
        await commit('setClientAvailList', result.data.clients);
        await commit('setPurchaserAvailList', result.data.purchasers);
        await commit('setManagerState', result.data.managerState);
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async setClient({ dispatch }, { clientId }) {
      try {
        const result = await ManagerService.setClient(clientId);
        dispatch('showMessage', result.data);
        dispatch('fetchTargetClientsInfo');
        dispatch('cart/fetchCheckout', {}, { root: true });
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }
    },
    async setPurchaser({ dispatch }, { purchaserId }) {
      try {
        const result = await ManagerService.setPurchaser(purchaserId);
        dispatch('showMessage', result.data);
        dispatch('fetchTargetClientsInfo');
        dispatch('cart/fetchCheckout', {}, { root: true });
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async resetClient({ dispatch }) {
      try {
        const result = await ManagerService.resetClient();
        dispatch('showMessage', result.data);
        dispatch('fetchTargetClientsInfo');
        dispatch('cart/fetchCheckout', {}, { root: true });
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async resetPurchaser({ dispatch }) {
      try {
        const result = await ManagerService.resetPurchaser();
        dispatch('showMessage', result.data);
        dispatch('fetchTargetClientsInfo');
        dispatch('cart/fetchCheckout', {}, { root: true });
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    showMessage({ dispatch }, data) {
      if (data.success) {
        dispatch('notifications/showSuccessNotification', data.message, { root: true });
      } else {
        dispatch('notifications/showErrorNotification', data.message, { root: true });
      }
    }
  },
  mutations: {
    setTargetClientsInfo(state, info) {
      state.targetClientsInfo = info;
    },
    setCurrentTargetClient(state, clientId) {
      state.targetClientsInfo.managerState.targetClientId = clientId;
    },
    setManagerState(state, managerState) {
      state.targetClientsInfo.managerState = managerState;
    },
    setCurrentTargetPurchaser(state, purchaserId) {
      state.targetClientsInfo.managerState.targetPurchaserId = purchaserId;
    },
    setClientAvailList(state, list) {
      state.targetClientsInfo.clients = list;
    },
    setPurchaserAvailList(state, list) {
      state.targetClientsInfo.purchasers = list;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
  },
};

export default TargetClientModule;