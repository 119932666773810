var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-field vue-field_select" }, [
    _vm.label
      ? _c("label", { attrs: { for: _vm.fieldId } }, [
          _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "custom-select custom-select_type_single custom-select_type_filterable",
        class: {
          _open: _vm.isOpen,
          _disabled: _vm.disabled,
        },
        attrs: { "data-vue-custom-select": "" },
      },
      [
        _c("div", { staticClass: "custom-select__wrapper" }, [
          _c("div", { staticClass: "custom-select__field-wrapper" }, [
            _c("input", {
              staticClass: "custom-select__field",
              attrs: {
                type: "text",
                autocomplete: "off",
                id: _vm.fieldId,
                placeholder: _vm.placeholder,
              },
              domProps: { value: _vm.showedValue() },
              on: { click: _vm.openable, input: _vm.filter },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "custom-select__arrow-icon custom-select__arrow-icon_absolute",
              },
              [_c("ArrowSmall")],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "custom-select__content-wrap custom-select__content-wrap_openable",
            },
            [
              _c(
                "ul",
                { staticClass: "custom-select__list" },
                [
                  !_vm.noDefault
                    ? _c("li", { staticClass: "custom-select__item" }, [
                        _c("input", {
                          staticClass:
                            "custom-select__input custom-select__input_radio",
                          attrs: {
                            type: "radio",
                            id: _vm.uniqueId(_vm.fieldId),
                            name: _vm.fieldId,
                          },
                          domProps: { value: _vm.defaultValue.value },
                          on: { change: _vm.change },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-select__label",
                            attrs: { for: _vm.uniqueId(_vm.fieldId) },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "custom-select__choice-name" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.defaultValue.name) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.values, function (item) {
                    return _c(
                      "li",
                      {
                        staticClass: "custom-select__item",
                        class: { _hidden: _vm.isHidden(item) },
                      },
                      [
                        _c("input", {
                          staticClass:
                            "custom-select__input custom-select__input_radio",
                          attrs: {
                            type: "radio",
                            name: _vm.fieldId,
                            id: _vm.uniqueId(
                              _vm.fieldId,
                              _vm.valueAsString(item)
                            ),
                            disabled: _vm.checkDisabled(
                              _vm.valueAsString(item)
                            ),
                          },
                          domProps: { value: _vm.valueAsString(item) },
                          on: { change: _vm.change },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-select__label",
                            attrs: {
                              for: _vm.uniqueId(
                                _vm.fieldId,
                                _vm.valueAsString(item)
                              ),
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "custom-select__choice-name" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.nameAsString(item)) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }