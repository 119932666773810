<template>
  <div class="additional-prices">
    <div class="additional-prices__icon">
      <plus-circle/>
    </div>

    <div class="additional-prices__prices-block">
      <ul class="additional-prices__list">
        <li class="additional-prices__item" v-for="price in prices">
          <div class="additional-prices__name">
            {{ price.name }}
          </div>
          <div class="additional-prices__value">
            {{ price.value }} ₽/шт.
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PlusCircle from '../icons/PlusCircle.vue';


export default {
  name: "AdditionalPrices",
  components: {
    PlusCircle
  },
  props: {
    prices: {
      type: Array
    }
  }
}
</script>