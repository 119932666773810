<template>
  <svg width="9" height="2" viewBox="0 0 9 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 1H8.5" stroke="#7D7984" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: "MinusIcon"
}
</script>