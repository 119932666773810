<script>
import { Line } from 'vue-chartjs'
import ColorService from "./../../../services/color.service";
import Vue from "vue";

const colors = ColorService.getColors();

export default {
  name: "StatGraphLine",
  components: {
    Line
  },
  extends: Line,
  props: {
    data: {
      type: Object,
      required: false,
    },
    options: {
      type: Object,
      required: false
    },
    bus: {
      type: Vue,
      required: false
    }
  },
  computed: {
    chartData() {
      const inputData = { ...this.data };

      inputData.datasets = inputData.datasets.map((line, index) => {
        const color = colors[index % colors.length];
        const defaultData = {
          lineTension: 0,
          borderColor: color,
          borderWidth: 2,
          backgroundColor: 'transparent',
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointBorderWidth: 3,
          pointRadius: 3,
          pointHitRadius: 10,

          pointHoverBackgroundColor: color,
          pointHoverBorderColor: color,
          pointHoverBorderWidth: 3,
          pointHoverRadius: 2,
        }
        return ({ ...defaultData, ...line })
      });

      return inputData;
    },
    chartOptions() {
      return this.options;
    },
  },
  methods: {
    update() {
      this.renderChart(this.chartData, this.chartOptions);
      this.$emit('updateLegend', this.generateLegend());
      console.log('update')
    },
    customListeners() {
      if (this.bus) {
        this.bus.$on('update', this.update)
      }

      document.addEventListener('legend-click', (e) => {
        if (e.detail.chartID === this.$data._chart.id) {
          const ci = this.$data._chart;
          const index = e.detail.index;
          const meta = ci.getDatasetMeta(index)
          meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          ci.update();
          this.$emit('updateLegend', this.generateLegend());
        }

      });
    }
  },
  mounted () {
    this.update();
    this.customListeners();
  },
}
</script>
