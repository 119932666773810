var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catalog-client-selector" }, [
    _vm.hasClients
      ? _c(
          "div",
          {
            staticClass: "custom-select custom-select_type_field-iconable",
            class: { _open: _vm.isOpen },
            attrs: { "data-vue-custom-select": "" },
          },
          [
            _c("div", { staticClass: "custom-select__wrapper" }, [
              _c(
                "label",
                {
                  staticClass: "custom-select__iconable-field",
                  on: { click: _vm.openable },
                },
                [
                  _c("span", { staticClass: "custom-select__field-name" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.clientName) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "custom-select__arrow-icon custom-select__arrow-icon_relative",
                    },
                    [_c("ArrowSmall")],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "custom-select__content-wrap custom-select__content-wrap_openable",
                },
                [
                  _c(
                    "div",
                    { staticClass: "custom-select__list" },
                    [
                      _c("div", { staticClass: "custom-select__item" }, [
                        _c("input", {
                          staticClass:
                            "custom-select__input custom-select__input_radio",
                          attrs: {
                            type: "radio",
                            id: _vm.uniqueId(),
                            name: "catalog-target-client",
                          },
                          domProps: { value: null },
                          on: { change: _vm.selectClient },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-select__label",
                            attrs: { for: _vm.uniqueId() },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "custom-select__choice-name" },
                              [
                                _vm._v(
                                  "\n                Выбрать клиента\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.clients, function (client) {
                        return _c(
                          "div",
                          { staticClass: "custom-select__item" },
                          [
                            _c("input", {
                              staticClass:
                                "custom-select__input custom-select__input_radio",
                              attrs: {
                                type: "radio",
                                name: "catalog-target-client",
                                id: _vm.uniqueId(client.value),
                              },
                              domProps: { value: client.value },
                              on: { change: _vm.selectClient },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "custom-select__label",
                                attrs: { for: _vm.uniqueId(client.value) },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "custom-select__choice-name" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(client.name) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }