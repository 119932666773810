var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "date-field" }, [
    _c(
      "label",
      { staticClass: "date-field__label", attrs: { for: _vm.inputId } },
      [_vm._v(_vm._s(_vm.label))]
    ),
    _vm._v(" "),
    (_vm.enableTime ? "datetime-local" : "date") === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value",
            },
          ],
          staticClass: "date-field__input",
          attrs: { id: _vm.inputId, disabled: _vm.disabled, type: "checkbox" },
          domProps: {
            value: _vm.currentValue,
            checked: Array.isArray(_vm.value)
              ? _vm._i(_vm.value, _vm.currentValue) > -1
              : _vm.value,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.value,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.currentValue,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.value = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.value = $$c
                }
              },
              _vm.change,
            ],
          },
        })
      : (_vm.enableTime ? "datetime-local" : "date") === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value",
            },
          ],
          staticClass: "date-field__input",
          attrs: { id: _vm.inputId, disabled: _vm.disabled, type: "radio" },
          domProps: {
            value: _vm.currentValue,
            checked: _vm._q(_vm.value, _vm.currentValue),
          },
          on: {
            change: [
              function ($event) {
                _vm.value = _vm.currentValue
              },
              _vm.change,
            ],
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value",
            },
          ],
          staticClass: "date-field__input",
          attrs: {
            id: _vm.inputId,
            disabled: _vm.disabled,
            type: _vm.enableTime ? "datetime-local" : "date",
          },
          domProps: { value: _vm.currentValue, value: _vm.value },
          on: {
            change: _vm.change,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.value = $event.target.value
            },
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }