<template>
  <div class="stat-page__content">
    <div class="stat-page__inner-content">

      <div class="stat-page__row">
        <key-value-table class="stat-page__table"
            name="Количество доступных товаров"
            tableKey="total_products_by_categories_table"
        />

        <div class="stat-page__graph stat-page__graph_end">
          <BaseGraph graph-key="products_by_categories_graph"/>
        </div>
      </div>

      <div class="stat-page__row">
        <key-value-table  class="stat-page__table"
            name="Количество доступных предложений"
            tableKey="total_offers_by_categories_table"
        />

        <div class="stat-page__graph stat-page__graph_end">
          <BaseGraph graph-key="offers_by_categories_graph"/>
        </div>
      </div>

      <div class="stat-page__row">
        <key-value-table class="stat-page__table"
            name="Количество доступных поставщиков"
            tableKey="total_providers_by_categories_table"
        />

        <key-value-table class="stat-page__table"
            name="Количество доступных складов"
            tableKey="total_storages_by_categories_table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KeyValueTable from "./tables/KeyValueTable.vue";
import BaseGraph from "./graphs/BaseGraph.vue";

export default {
  name: "TheStatGeneral",
  components: {
    KeyValueTable,
    BaseGraph
  },
}
</script>
