import axios from 'axios';

const PriceMovesService = {
  async fetchPriceMoves(productClass, productId, formData) {
    const url = '/stat/api/get_prices_moves';
    return axios.get(url, {
      params: { productClass, productId, ...formData },
    });
  },
  async fetchAvgClientMargins(productClass, productId, formData) {
    const url = '/stat/api/get_avg_client_margins';
    return axios.get(url, {
      params: { productClass, productId, ...formData },
    });
  },
  async fetchAvgMrcMargins(productClass, productId, formData) {
    const url = '/stat/api/get_avg_mrc_margins';
    return axios.get(url, {
      params: { productClass, productId, ...formData },
    });
  },
};

export default PriceMovesService;