<template>
    <div class="tree-node">
        <a href="javascript:void(0)" class="tree-node__append-before" title="Добавить ветку" @click.prevent="prepend">
            <span class="tree-node__icon-wrap">
                ➕
            </span>
        </a>

        <div class="tree-node__head">
            <div class="tree-node__title">
                Если
            </div>

            <a href="javascript:void(0)" class="tree-node__remove-branch" title="Удалить ветку" @click.prevent="remove">
                &times;
            </a>
        </div>

        <div class="tree-node__conditions">
            <TreeConditionSet :treeId="treeId"
                              :conditions="node.conditions"
                              :rootConditionSet="true"
                              :parent-nodes="parentNodes"
            />

            <textarea
                class="tree-node__comment"
                v-model="node.comment"
            />
        </div>

        <div class="tree-node__content" v-if="!hasChildren">
            <slot :node="node"></slot>
        </div>

        <div class="tree-node__create-sub-branch" v-if="!hasChildren">
            <a href="javascript:void();" class="tree-node__sub-branch-button" title="Разбить ветку" @click.prevent="split">
                <span class="tree-node__icon-wrap">
                    ➕
                </span>
            </a>
        </div>

        <div class="tree-node__sub" v-if="hasChildren">
            <TreeNodes
                :treeId="treeId"
                :nodes="node.children"
                :parentNode="thisNodeIfItsParent"
                :parent-nodes="createParentNodes"
                v-slot="slotProps"
            >
                <slot :node="slotProps.node"></slot>
            </TreeNodes>
        </div>
    </div>
</template>

<script>
import TreeConditionSet from "./TreeConditionSet.vue";

export default {
  name: "TreeNode",
  components: {
    TreeConditionSet,
    TreeNodes: () => import('./TreeNodes.vue')
  },
  props: {
    treeId: String,
    index: Number|String,
    node: Object,
    parentNode: Object,
    parentNodes: Array,
  },
  data() {
    return {
      parentProducersData: [],
    }
  },
  computed: {
    // parentProducers() {
    //   this.getParentNodes.forEach((item) => {
    //     if (item.conditions.treeCondition.fieldName === 'producer') {
    //       item.conditions.treeCondition.value.forEach((value) => {
    //         if (this.parentProducersData.includes(value)) {
    //           return;
    //         }
    //         this.parentProducersData.push(value);
    //       });
    //     }
    //   });
    //
    //   return this.parentProducersData;
    // },
    thisNodeIfItsParent() {
      if (this.hasChildren) {
        return this.node;
      }
    },
    getParentNode() {
      if (this.hasChildren) {
        return this.node;
      }
    },
    getParentNodes() {
      if (this.parentNodes) {
        return this.parentNodes;
      }
    },
    hasChildren() {
      if (!this.node) {
        return false;
      }
      return this.node.children && this.node.children.length > 0;
    },
    createParentNodes() {
      return Array.from([...this.getParentNodes, this.thisNodeIfItsParent]);
    }
  },
  methods: {
    buildDefaultContent() {

    },
    buildDefaultTreeCondition() {

    },
    prepend: function () {
      this.$emit('prepend', this.index);
    },
    remove: function () {
      this.$emit('remove', this.index);
    },
    split: function () {
      this.$emit('split', this.index);
    },
  },
  created() {
  },
  mounted() {

  },
}
</script>

<style lang="scss">

</style>