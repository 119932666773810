import Vue from 'vue';
import Vuex from 'vuex';

import cart from './modules/cart.module';
import targetClient from './modules/targetClient.module';
import order from './modules/order.module';
import catalogFilter from './modules/catalogFilter.module';
import filterParam from './modules/filterParam.module';
import autoParam from './modules/autoParam.module';
import notifications from './modules/notifications.module';
import filterCollection from './modules/filterCollection.module';
import notifySettings from './modules/notifySettings.module';
import tree from './modules/tree.module';
import stat from './modules/stat.module';
import statClient from './modules/statClient.module';
import priceMoves from './modules/priceMoves.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cart,
    targetClient,
    order,
    catalogFilter,
    filterParam,
    autoParam,
    notifications,
    filterCollection,
    notifySettings,
    tree,
    stat,
    statClient,
    priceMoves,
  },
  getters: {
    isLoading(state) {
      let loading = false;
      for (const key in state) {
        if (state.hasOwnProperty(key) && state[key].hasOwnProperty('loading')) {
          loading = loading || state[key].loading;
        }
      }
      return loading;
    },
  },
});