var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-item" }, [
    _c("input", {
      attrs: {
        type: "checkbox",
        id: _vm.inputId,
        disabled: _vm.disabled,
        name: _vm.inputId,
      },
      domProps: { value: _vm.shouldBeChecked, checked: _vm.shouldBeChecked },
      on: { change: _vm.change },
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.inputId } }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }