<template>
  <div class="stat-page__vue">
    <ul class="stat-page__tabs tabs tabs_main">
      <li class="tabs__item" :class="{'_active': currentTab === 'general'}">
        <a href="javascript:void(0)" class="tabs__link" @click="setCurrentTab('general')">
          Общая
        </a>
      </li>
      <li class="tabs__item" :class="{'_active': currentTab === 'client'}">
        <a href="javascript:void(0)" class="tabs__link" @click="setCurrentTab('client')">
          Пользователи
        </a>
      </li>

      <li class="tabs__item" v-for="productType in productTypes"
          :class="{'_active': currentTab === productType.key}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTab(productType.key)">
          {{ productType.name }}
        </a>
      </li>

      <li class="tabs__item" :class="{'_active': currentTab === 'sync'}">
        <a href="javascript:void(0)" class="tabs__link" @click="setCurrentTab('sync')">
          Синхронизация
        </a>
      </li>
    </ul>

    <div class="stat-page__contents">
      <TheStatGeneral v-if="currentTab === 'general'"/>

      <TheStatClient v-if="currentTab === 'client'"/>

      <div v-for="productType in productTypes">
        <div class="stat-page__content-wrap" v-if="currentTab === productType.key">
          <stat-category :category-key="productType.key"/>
        </div>
      </div>

      <TheSyncPage v-if="currentTab === 'sync'"/>
    </div>
  </div>
</template>

<script>
import TheStatGeneral from "./TheStatGeneral.vue";
import StatOffers from "./StatOffers.vue";
import StatProducts from "./StatProducts.vue";
import TheStatClient from "./TheStatClient.vue";
import StatCategory from "./StatCategory.vue";
import TheSyncPage from "./TheSyncPage.vue";

export default {
  name: "TheStatPage",
  components: {
    StatCategory,
    TheStatGeneral,
    StatOffers,
    StatProducts,
    TheStatClient,
    TheSyncPage
  },
  data() {
    return {
      currentTab: 'general',
      currentProductType: 'car_tyre',
      productTypes: [
        {
          name: 'Легковые шины',
          key: 'car_tyre'
        },
        {
          name: 'Грузовые шины',
          key: 'truck_tyre'
        },
        {
          name: 'Мото шины',
          key: 'moto_tyre'
        },
        {
          name: 'Легковые диски',
          key: 'car_wheel'
        },
        {
          name: 'Грузовые диски',
          key: 'truck_wheel'
        },
        {
          name: 'Прочее',
          key: 'eav'
        },
      ]
    };
  },
  methods: {
    setCurrentTab(tab) {
      this.currentTab = tab;
    },
  },
}
</script>
