var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-range-slider", class: { _disabled: _vm.disabled } },
    [
      _vm.label
        ? _c("label", { staticClass: "vue-range-slider__label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "vue-range-slider__inputs" }, [
        _c("input", {
          staticClass: "vue-range-slider__input",
          attrs: { type: "text", autocomplete: "off" },
          domProps: { value: _vm.renderValue[0] },
          on: {
            input: function (e) {
              return _vm.inputValue(e, 0)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "vue-range-slider__input",
          attrs: { type: "text", autocomplete: "off" },
          domProps: { value: _vm.renderValue[1] },
          on: {
            input: function (e) {
              return _vm.inputValue(e, 1)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vue-range-slider__slider" },
        [
          _c("vue-slider", {
            ref: _vm.ref,
            attrs: {
              min: _vm.lazyMin,
              max: _vm.lazyMax,
              dotSize: 12,
              tooltip: "none",
            },
            on: { "drag-start": _vm.dragStart, "drag-end": _vm.dragEnd },
            model: {
              value: _vm.currentValue,
              callback: function ($$v) {
                _vm.currentValue = $$v
              },
              expression: "currentValue",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }