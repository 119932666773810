<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.58293 4.16699H15.4163C15.5363 4.20908 15.6451 4.27808 15.7343 4.36871C15.8235 4.45934 15.8908 4.56919 15.931 4.68984C15.9713 4.8105 15.9833 4.93876 15.9663 5.0648C15.9493 5.19084 15.9037 5.31131 15.8329 5.41699L11.6663 10.0003V15.8337L8.33293 13.3337V10.0003L4.16627 5.41699C4.09551 5.31131 4.04988 5.19084 4.03287 5.0648C4.01587 4.93876 4.02794 4.8105 4.06815 4.68984C4.10837 4.56919 4.17567 4.45934 4.2649 4.36871C4.35413 4.27808 4.46292 4.20908 4.58293 4.16699Z" stroke="#FF7A00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "FilterIcon"
}
</script>
