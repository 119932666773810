import axios from 'axios';
import { onDomReady } from '../../components/dynamic/observer';

class OrderCancel {
  constructor(selector) {
    this.cancelLink = selector;
    this.url = this.cancelLink.getAttribute('href');
    this.orderId = this.cancelLink.dataset.orderCancel;

    this.bind();
  }

  bind() {
    this.cancelLink.addEventListener('click', (e) => {
      e.preventDefault();

      this.requestSend()
        .then((r) => {
          if (r.data.status === 'success') {
            document.dispatchEvent(new CustomEvent('showSuccessNotification', {
              detail: {
                text: 'Заказ отменен',
              },
            }));
            this.cancelLink.classList.add('_hidden');
          } else {
            document.dispatchEvent(new CustomEvent('showErrorNotification', {
              detail: {
                text: 'Произошла ошибка',
              },
            }));
          }
        })
        .catch(() => {
          throw new Error('Incorrect response from server');
        });
    });
  }

  requestSend() {
    return axios.post(this.url, { orderId: this.orderId });
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-order-cancel]')
    .forEach((item) => new OrderCancel(item));
});