import axios from 'axios';

const FilterCollectionService = {
  async save(filterType, productType, data) {
    const url = `/saves/filter/save/${filterType}/${productType}`;
    return axios.post(url, data);
  },
  async list(filterType, productType, categoryId) {
    let url = `/saves/filter/list/${filterType}/${productType}`;
    if (categoryId) {
      url += `/${categoryId}`;
    }
    return axios.get(url);
  },
  async apply(filterType, productType, filterId) {
    const url = `/saves/filter/apply/${filterType}/${productType}/${filterId}`;
    return axios.get(url);
  },
  async delete(filterType, productType, filterId) {
    const url = `/saves/filter/delete/${filterType}/${productType}/${filterId}`;
    return axios.get(url);
  },
};

export default FilterCollectionService;