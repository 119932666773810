var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notify-settings" },
    [
      _c(
        "a",
        {
          staticClass: "notify-settings__link",
          attrs: { href: "javascript:void(0);", title: "Настроить оповещения" },
          on: { click: _vm.openSettings },
        },
        [
          _c(
            "span",
            { staticClass: "notify-settings__icon" },
            [_c("GearIcon")],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "notify-settings__title h2" }, [
            _vm._v("\n      Настроить оповещения\n    "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: {
            opened: _vm.isModalOpened("notify_settings"),
            "is-processing": _vm.isProcessing("notify_settings"),
          },
          on: {
            close: function ($event) {
              return _vm.closeModal("notify_settings")
            },
          },
        },
        [
          _c("div", { staticClass: "notify-settings-page" }, [
            _c("h2", { staticClass: "notify-settings-page__title" }, [
              _vm._v("\n        Настройка оповещений\n      "),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass:
                  "notify-settings-page__form notify-settings-page__form_email",
                attrs: { action: "" },
              },
              [
                _c("div", { staticClass: "notify-settings-page__grid" }, [
                  _c(
                    "div",
                    { staticClass: "notify-settings-page__label text__main" },
                    [
                      _vm._v(
                        "\n            Укажите e-mail на который вы хотите получать оповещения\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "notify-settings-page__fields" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "notify-settings-page__text-input",
                      attrs: { type: "text", placeholder: "E-mail" },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass:
                  "notify-settings-page__form notify-settings-page__form_order",
                attrs: { action: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveOrderStatuses.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "notify-settings-page__grid" }, [
                  _c(
                    "div",
                    { staticClass: "notify-settings-page__form-name" },
                    [_vm._v("\n            Оповещения по заказам\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "notify-settings-page__fields" },
                    [
                      _c("base-field-switch", {
                        attrs: {
                          label: "Получать оповещения о смене статуса заказа",
                          "input-id": "enable-order-notify",
                        },
                        model: {
                          value: _vm.orders.enable,
                          callback: function ($$v) {
                            _vm.$set(_vm.orders, "enable", $$v)
                          },
                          expression: "orders.enable",
                        },
                      }),
                      _vm._v(" "),
                      _c("base-field-checkbox-list", {
                        attrs: {
                          "possible-values": _vm.orders.avail_statuses,
                          "current-values": _vm.orders.current_statuses,
                          "input-id": "order-statuses",
                          "disabled-values": _vm.disableStatuses,
                        },
                        model: {
                          value: _vm.orders.current_statuses,
                          callback: function ($$v) {
                            _vm.$set(_vm.orders, "current_statuses", $$v)
                          },
                          expression: "orders.current_statuses",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "notify-settings-page__grid" }, [
                  _c(
                    "button",
                    { staticClass: "notify-settings-page__button button" },
                    [_vm._v("\n            Сохранить настройки\n          ")]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass:
                  "notify-settings-page__form notify-settings-page__form_product",
                attrs: { action: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveProductsEvents.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "notify-settings-page__grid" }, [
                  _c(
                    "div",
                    { staticClass: "notify-settings-page__form-name" },
                    [_vm._v("\n            Оповещения по товарам\n          ")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "notify-settings-page__grid" }, [
                  _c("div", { staticClass: "notify-settings-page__point" }, [
                    _c(
                      "div",
                      { staticClass: "notify-settings-page__point-number" },
                      [_vm._v("\n              1\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "notify-settings-page__point-text" },
                      [_vm._v("\n              Выберите фильтр\n            ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "notify-settings-page__fields" },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "notify-settings-page__tabs tabs tabs_small",
                        },
                        [
                          _c(
                            "li",
                            {
                              staticClass: "tabs__tab",
                              class: { _active: _vm.personalIsActive },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "tabs__tab-link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCurrentTab("personal")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Мои фильтры\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              staticClass: "tabs__tab",
                              class: { _active: _vm.generalIsActive },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "tabs__tab-link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCurrentTab("general")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Общие фильтры\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.currentList && _vm.currentList.length
                        ? _c("base-field-select", {
                            attrs: {
                              values: _vm.currentList,
                              "current-value": _vm.selectedFilter,
                              "field-id": "filter_list",
                              "no-default": true,
                            },
                            on: { input: _vm.handleSelect },
                            model: {
                              value: _vm.selectedFilter,
                              callback: function ($$v) {
                                _vm.selectedFilter = $$v
                              },
                              expression: "selectedFilter",
                            },
                          })
                        : _c(
                            "div",
                            { staticClass: "notify-settings-page__empty" },
                            [
                              _vm._v(
                                "\n              Нет доступных фильтров\n            "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.selectedFilter
                  ? _c("div", { staticClass: "notify-settings-page__grid" }, [
                      _c(
                        "div",
                        { staticClass: "notify-settings-page__point" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "notify-settings-page__point-number",
                            },
                            [_vm._v("\n              2\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "notify-settings-page__point-text" },
                            [
                              _vm._v(
                                "\n              Укажите какие оповещения вы хотели бы получать\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "notify-settings-page__fields" },
                        [
                          _c("base-field-checkbox-list", {
                            attrs: {
                              "possible-values": _vm.products.avail_events,
                              currentValues: _vm.products.current_events,
                              "input-id": "event_list",
                              "no-default": true,
                            },
                            model: {
                              value: _vm.products.current_events,
                              callback: function ($$v) {
                                _vm.$set(_vm.products, "current_events", $$v)
                              },
                              expression: "products.current_events",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedFilter
                  ? _c("div", { staticClass: "notify-settings-page__grid" }, [
                      _c(
                        "div",
                        { staticClass: "notify-settings-page__point" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "notify-settings-page__point-number",
                            },
                            [_vm._v("\n              3\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "notify-settings-page__point-text" },
                            [
                              _vm._v(
                                "\n              Укажите дату, до которой нужно получать оповещения\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "notify-settings-page__fields" },
                        [
                          _c("BaseFieldDate", {
                            attrs: {
                              label: "",
                              "input-id": "end_at",
                              "current-value": _vm.products.end_at,
                            },
                            on: { change: _vm.changeDate },
                            model: {
                              value: _vm.products.end_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.products, "end_at", $$v)
                              },
                              expression: "products.end_at",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedFilter && _vm.availReserve
                  ? _c("div", { staticClass: "notify-settings-page__grid" }, [
                      _c(
                        "div",
                        { staticClass: "notify-settings-page__point" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "notify-settings-page__point-number",
                            },
                            [_vm._v("\n              4\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "notify-settings-page__point-text" },
                            [
                              _vm._v(
                                "\n              Укажите количество для автоматического резервирования\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "notify-settings-page__fields" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.products.reserve_count,
                                expression: "products.reserve_count",
                              },
                            ],
                            staticClass: "notify-settings-page__text-input",
                            attrs: { type: "text", placeholder: "0" },
                            domProps: { value: _vm.products.reserve_count },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.products,
                                  "reserve_count",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedFilter
                  ? _c("div", { staticClass: "notify-settings-page__grid" }, [
                      _c(
                        "button",
                        { staticClass: "notify-settings-page__button button" },
                        [
                          _vm._v(
                            "\n            Сохранить настройки\n          "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }