<template>
  <div class="stat-price-move">
    <ul class="stat-price-move__tabs tabs tabs_black">
      <li v-for="(tab, tabKey) in tabs" class="tabs__item" :class="{_active: currentTab === tabKey}">
        <a href="javascript:void(0);" class="tabs__link"  @click="setCurrentTab(tabKey)">
          {{ tab }}
        </a>
      </li>
    </ul>

    <div class="stat-price-move__tab-contents">
      <price-move-graph
          :class="{_active: currentTab === 'wholesale'}"
          :title="'Движения закупочных цен'"
          :graph-key="'provider_prices_moves_graph'"
          :product="productData"
      />
      <price-move-graph
          :class="{_active: currentTab === 'clientMargin'}"
          :title="'Средняя наценка до клиента'"
          :graph-key="'client_margins_graph'"
          :product="productData"
      />
      <price-move-graph
          :class="{_active: currentTab === 'mrcMargin'}"
          :title="'Наценка до МРЦ'"
          :graph-key="'mrc_margins_graph'"
          :product="productData"
      />

    </div>
  </div>
</template>

<script>
import PriceMoveGraph from "./graphs/PriceMoveGraph.vue";

export default {
  name: "PriceMove",
  components: {
    PriceMoveGraph,
  },
  data() {
    return {
      productData: {
        productClass: '',
        productId: 0,
      },

      currentTab: 'wholesale',
      tabs: {
        wholesale: 'Движения закупочных цен',
        clientMargin: 'Средняя наценка до клиента',
        mrcMargin: 'Наценка до МРЦ',
      },
    }
  },

  methods: {
    setCurrentTab(tabKey) {
      this.currentTab = tabKey;
    },
  },

}
</script>
