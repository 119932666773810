var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-table _clients" }, [
    _vm.name
      ? _c("div", { staticClass: "stat-table__title" }, [
          _vm._v("\n    " + _vm._s(_vm.name) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "stat-table__form" },
      [
        _c("base-field-date", {
          staticClass: "stat-table__field",
          attrs: { label: "От", "input-id": _vm.getFieldId("from") },
          on: { change: _vm.update },
          model: {
            value: _vm.formData.dateFrom,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "dateFrom", $$v)
            },
            expression: "formData.dateFrom",
          },
        }),
        _vm._v(" "),
        _c("base-field-date", {
          staticClass: "stat-table__field",
          attrs: { label: "До", "input-id": _vm.getFieldId("to") },
          on: { change: _vm.update },
          model: {
            value: _vm.formData.dateTo,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "dateTo", $$v)
            },
            expression: "formData.dateTo",
          },
        }),
        _vm._v(" "),
        _c("base-field-select-multi", {
          attrs: {
            values: _vm.availCategories,
            "field-id": _vm.getFieldId("category"),
            "current-values": _vm.formData.categories,
            label: "Категория",
          },
          on: {
            input: function (e) {
              return _vm.update()
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "stat-table__content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "stat-table__rows" },
        _vm._l(_vm.rows, function (row) {
          return _c("li", { staticClass: "stat-table__row" }, [
            _c(
              "a",
              {
                staticClass: "stat-table__link",
                attrs: { href: row.url, "data-modal": "" },
              },
              [_vm._v("\n          " + _vm._s(row.name) + "\n        ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "stat-table__count" }, [
              _vm._v("\n          " + _vm._s(row.count) + "\n        "),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stat-table__headers" }, [
      _c("div", { staticClass: "stat-table__header-item _product" }, [
        _vm._v("\n        Товар\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "stat-table__header-item _count" }, [
        _vm._v("\n        Количество\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }