<template>
  <div class="checkbox-list">
    <label v-if="label" class="checkbox-list__main-label">{{ label }}</label>
    <div class="checkbox-list__item" v-for="param in possibleValues">
      <input type="checkbox"
             class="checkbox-list__item"
             :id="uniqueId(inputId, valueAsString(param))"
             :value="valueAsString(param)"
             :disabled="checkDisabled(valueAsString(param))"
             :checked="shouldBeChecked(valueAsString(param))"
             @change="updateInput"
      >
      <label class="checkbox-list__label" :for="uniqueId(inputId, valueAsString(param))">
        {{ nameAsString(param) }}

        <span v-if="param.icon" class="checkbox-list__icon">
          <SummerIcon v-if="param.icon === 'summer'"/>
          <WinterIcon v-else-if="param.icon === 'winter'"/>
          <MultiIcon v-else-if="param.icon === 'multi'"/>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import SummerIcon from "../../icons/SummerIcon.vue";
import WinterIcon from "../../icons/WinterIcon.vue";
import MultiIcon from "../../icons/MultiIcon.vue";

export default {
  name: "BaseFieldCheckboxList",
  components: {
    SummerIcon,
    WinterIcon,
    MultiIcon
  },
  props: {
    /*
      Все возможные значения для чекбоксов
      - список объектов с полями 'name' и 'value'. Может еще icon
    */
    possibleValues: {
      required: true
    },
    // Объект для v-model
    currentValues: {
      required: true
    },
    // Склеивается со значениями инпутов
    inputId: {
      type: String,
      required: true
    },
    // Список значений на дисейбл (строки)
    disabledValues: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    /*
      Лейбл для всего листа
     */
    label: {
      type: String,
      required: false
    }
  },
  methods: {
    // Проверка значения на наличие в списке недоступных значений
    checkDisabled(value) {
      return this.disabledValues ? this.disabledValues.includes(value) : false;
    },
    // Поклейка уникального id
    uniqueId(inputId, value) {
      return `${inputId}_${value}`;
    },
    // Если объект со свойством value, вернет value, иначе пусть это будет строка
    valueAsString(item) {
      return item.value === undefined ? '' : item.value;
    },
    nameAsString(item) {
      return item.name === undefined ? '' : item.name;
    },
    // При изменении инпута
    updateInput(event) {
      const target = event.target;
      // Если инпут стал активным
      if (target.checked) {
        // Добавляем значение в текущие значения
        this.currentValues.push(target.value)
      } else {
        // Иначе убираем от туда
        this.currentValues.splice(this.currentValues.indexOf(target.value), 1)
      }
      // Даем родителю знать, что что-то в списке поменялось
      this.$emit('change', event);
    },
    // Проверяем есть ли значение в текущих значениях
    shouldBeChecked(value) {
      return this.currentValues ? this.currentValues.includes(value) : false;
    }
  }
}
</script>
