<template>
  <div class="stat-page__content">
    <ul class="stat-page__tabs tabs tabs_black">
      <li class="tabs__item" :class="{'_active': currentTargetType === 'products'}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTargetType('products')">
          Товары
        </a>
      </li>
      <li class="tabs__item" :class="{'_active': currentTargetType === 'offers'}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTargetType('offers')">
          Предложения
        </a>
      </li>
    </ul>

    <div class="stat-page__content-wrap">
      <StatProducts v-if="currentTargetType === 'products'" :product-type="categoryKey"/>
      <StatOffers v-if="currentTargetType === 'offers'" :product-type="categoryKey"/>
    </div>
  </div>
</template>

<script>
import StatOffers from "./StatOffers.vue";
import StatProducts from "./StatProducts.vue";

export default {
  name: "StatCategory",
  components: {
    StatOffers,
    StatProducts
  },
  props: {
    categoryKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentTargetType: 'products'
    }
  },
  methods: {
    setCurrentTargetType(type) {
      this.currentTargetType = type;
    }
  }
}
</script>