<template>
  <div class="cart-product" :class="{'_opacity': !totalPrice()}">
    <div class="cart-product__image-wrap">
      <ThumbImage
          v-if="product.image"
          :image-url="product.image"
          :thumb-group-name="'cart_page'"
          :thumb-name="'product_image'"
          :alt="product.title"
          :picture-class="'cart-product__picture'"
          :image-class="'cart-product__image'"
      />


      <div v-else class="catalog-product__no-image-wrapper">
        <img src="static/images/assets/no-image.png"
             alt="Изображение отсутствует"
             class="catalog-product__no-image">
      </div>

      <div class="cart-product__id text__small">
        {{ product.id_prefix }}-{{ product.id }}
      </div>
    </div>

    <div class="cart-product__info-wrap">

      <div class="cart-product__title text__card-heading">
        {{ product.title }}
      </div>

      <div class="cart-product__params">
        <div class="cart-product__params-string text__small">
          {{ product.characters }}
        </div>
        <div class="cart-product__param-icon">
          <SummerIcon v-if="product.param_icon === 'summer'"/>
          <WinterIcon v-else-if="product.param_icon === 'winter'"/>
          <MultiIcon v-else-if="product.param_icon === 'multi'"/>
        </div>
      </div>

      <!--    TODO: технологии от модели притащить-->
      <ul class="cart-product__technologies" v-if="product.additional_list">
        <li v-for="additional in product.additional_list" class="cart-product__technology">
          {{ additional }}
        </li>
      </ul>

    </div>

    <div class="cart-product__offers-wrap">
      <ul class="cart-product__offers-list">
        <li class="cart-product__offers-item" v-for="position in item.positions">
          <cart-position :position="position"/>
        </li>
      </ul>
    </div>

    <div class="cart-product__total-wrap" v-if="totalPrice()">
      <div class="cart-product__total">
<!--        TODO: товар|товара|товаров-->
        <span class="text__small">{{ totalCount() }} товаров на сумму</span>
        <span class="cart-product__total-sum">{{ this.formatPrice(totalPrice()) }} ₽</span>
      </div>
    </div>
  </div>
</template>

<script>
import CartPosition from "./CartPosition.vue";
import ThumbImage from "../common/ThumbImage.vue";
import RemoveIcon from '../icons/RemoveIcon.vue';
import SummerIcon from "../icons/SummerIcon.vue";
import WinterIcon from "../icons/WinterIcon.vue";
import MultiIcon from "../icons/MultiIcon.vue";

export default {
  name: "CartProduct",
  components: {
    MultiIcon,
    WinterIcon,
    SummerIcon,
    CartPosition,
    RemoveIcon,
    ThumbImage
  },
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    product: (state) => {
      return state.item.product;
    }
  },
  methods: {
    totalCount() {
      let totalCount = 0;
      this.item.positions.forEach((position) => totalCount += position.quantity);
      return totalCount;
    },
    totalPrice() {
      let totalPrice = 0;
      this.item.positions.forEach((position) => totalPrice += position.base_price);
      return totalPrice;
    },
    formatPrice(rawValue) {
      return this.$root.formatPrice(rawValue);
    }
  }
}
</script>

<style lang="scss">

</style>