var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "cart-order-comment" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.orderData.comment,
          expression: "orderData.comment",
        },
      ],
      staticClass: "cart-order-comment__comment",
      attrs: { placeholder: "Комментарий..." },
      domProps: { value: _vm.orderData.comment },
      on: {
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.orderData, "comment", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cart-order-comment__labels" }, [
      _c("h3", { staticClass: "cart-order-comment__title" }, [
        _vm._v("\n      Комментарий к заказу\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cart-order-comment__subtitle text text__main" },
        [_vm._v("\n      Что нужно учесть при выполнении заказа?\n    ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }