import CartService from '../../services/cart.service';

const CartModule = {
  namespaced: true,
  state: {
    loading: false,
    checkout: null,
  },
  getters: {
    total(state) {
      let total = 0;
      if (state.checkout) {
        total = parseFloat(state.checkout.total);
      }
      return total;
    },
    countPositions(state) {
      let count = 0;
      if (state.checkout) {
        state.checkout.items.forEach((item) => {
          item.positions
            .filter((position) => position.base_price)
            .forEach((position) => {
              count += position.quantity;
            });
        });
      }
      return count;
    },
    countProducts(state) {
      let count = 0;
      if (state.checkout) {
        count = state.checkout.items.length;
      }
      return count;
    },
    isForManager(state) {
      return state.checkout ? state.checkout.isForManager : null;
    },
    isForInternalManager(state) {
      return state.checkout ? state.checkout.isForInternalManager : null;
    },
    forOneCOrder(state) {
      return state.checkout ? state.checkout.forOneCOrder : null;
    },
  },
  actions: {
    async loadCheckout({ state, dispatch, commit }) {
      if (!state.checkout && !state.loading) {
        commit('setLoading', true);
        await dispatch('fetchCheckout');
        commit('setLoading', false);
        if (state.checkout.isForManager) {
          dispatch('targetClient/loadTargetClientsInfo', {}, { root: true });
        }
      }
    },
    async fetchCheckout({ commit }) {
      try {
        const result = await CartService.fetchCheckout();
        commit('setCheckout', result.data);
        if (result.data.comment) {
          commit('order/setComment', result.data.comment, { root: true });
        }
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async increase({ state, dispatch, commit }, { key, amount }) {
      try {
        const result = await CartService.increase(key, amount);
        dispatch('fetchCheckout');
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async decrease({ state, dispatch, commit }, { key, amount }) {
      try {
        const result = await CartService.decrease(key, amount);
        dispatch('fetchCheckout');
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async add({ dispatch }, { buyable, id, quantity }) {
      try {
        const result = await CartService.add(buyable, id, quantity);
        dispatch('fetchCheckout');
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async setQuantity({ dispatch, commit, state }, { key, quantity }) {
      try {
        const result = await CartService.setQuantity(key, quantity);
        dispatch('fetchCheckout');
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async setPrice({ dispatch, commit, state }, { key, price }) {
      try {
        const result = await CartService.setPrice(key, price);
        dispatch('fetchCheckout');
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async addOrSet({ dispatch }, { buyable, id, quantity }) {
      try {
        const result = await CartService.addOrSet(buyable, id, quantity);
        dispatch('fetchCheckout');
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
    async remove({ dispatch }, { key }) {
      try {
        const result = await CartService.remove(key);
        dispatch('fetchCheckout');
      } catch (e) {
        // dispatch('notifyError', e);
      }
    },
  },
  mutations: {
    setCheckout(state, data) {
      state.checkout = data;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
};

export default CartModule;