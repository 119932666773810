var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-product", class: { _opacity: !_vm.totalPrice() } },
    [
      _c(
        "div",
        { staticClass: "cart-product__image-wrap" },
        [
          _vm.product.image
            ? _c("ThumbImage", {
                attrs: {
                  "image-url": _vm.product.image,
                  "thumb-group-name": "cart_page",
                  "thumb-name": "product_image",
                  alt: _vm.product.title,
                  "picture-class": "cart-product__picture",
                  "image-class": "cart-product__image",
                },
              })
            : _c("div", { staticClass: "catalog-product__no-image-wrapper" }, [
                _c("img", {
                  staticClass: "catalog-product__no-image",
                  attrs: {
                    src: "static/images/assets/no-image.png",
                    alt: "Изображение отсутствует",
                  },
                }),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "cart-product__id text__small" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.product.id_prefix) +
                "-" +
                _vm._s(_vm.product.id) +
                "\n      "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "cart-product__info-wrap" }, [
        _c("div", { staticClass: "cart-product__title text__card-heading" }, [
          _vm._v("\n        " + _vm._s(_vm.product.title) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cart-product__params" }, [
          _c(
            "div",
            { staticClass: "cart-product__params-string text__small" },
            [
              _vm._v(
                "\n          " + _vm._s(_vm.product.characters) + "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cart-product__param-icon" },
            [
              _vm.product.param_icon === "summer"
                ? _c("SummerIcon")
                : _vm.product.param_icon === "winter"
                ? _c("WinterIcon")
                : _vm.product.param_icon === "multi"
                ? _c("MultiIcon")
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.product.additional_list
          ? _c(
              "ul",
              { staticClass: "cart-product__technologies" },
              _vm._l(_vm.product.additional_list, function (additional) {
                return _c("li", { staticClass: "cart-product__technology" }, [
                  _vm._v("\n          " + _vm._s(additional) + "\n        "),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cart-product__offers-wrap" }, [
        _c(
          "ul",
          { staticClass: "cart-product__offers-list" },
          _vm._l(_vm.item.positions, function (position) {
            return _c(
              "li",
              { staticClass: "cart-product__offers-item" },
              [_c("cart-position", { attrs: { position: position } })],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.totalPrice()
        ? _c("div", { staticClass: "cart-product__total-wrap" }, [
            _c("div", { staticClass: "cart-product__total" }, [
              _c("span", { staticClass: "text__small" }, [
                _vm._v(_vm._s(_vm.totalCount()) + " товаров на сумму"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "cart-product__total-sum" }, [
                _vm._v(_vm._s(this.formatPrice(_vm.totalPrice())) + " ₽"),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }