<template>
  <div class="stat-table" :class="{'_loading': rows === null}">
    <div v-if="name" class="stat-table__title">
      {{ name }}
    </div>

    <form class="stat-table__form" @submit.prevent="updateTable">
      <base-field-checkbox
          :label="'Текущее состояние'"
          :input-id="getFieldId('now')"
          class="stat-table__now"
          :current-value="isNow"
          v-model="isNow"
          @change="updateTable"
      />

      <base-field-date
          :label="'От'"
          :input-id="getFieldId('from')"
          @change="updateTable"
          class="stat-table__field"
          v-model="dateFrom"
          :disabled="isNow"
      />
      <base-field-date
          :label="'До'"
          :input-id="getFieldId('to')"
          @change="updateTable"
          class="stat-table__field"
          v-model="dateTo"
          :disabled="isNow"
      />
    </form>

    <div class="stat-table__content">
      <Preloader v-if="rows === null || rows === undefined"/>

      <ul v-else-if="rows.length" class="stat-table__rows">
        <li v-for="row in rows" class="stat-table__row">
          <div class="stat-table__key">
            {{ row.key }}
          </div>
          <div class="stat-table__value">
            {{ row.value }}
          </div>
        </li>
      </ul>

      <div v-else class="stat-table__empty">
        Нет данных
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from '../../parts/Preloader.vue';
import BaseFieldCheckbox from "../../parts/fields/BaseFieldCheckbox.vue";
import BaseFieldDate from "../../parts/fields/BaseFieldDate.vue";
import { mapActions } from "vuex";
import getLastMonthRange from "../../../helpers/dates";

export default {
  name: "KeyValueTable",
  components: {
    Preloader,
    BaseFieldDate,
    BaseFieldCheckbox
  },
  props: {
    name: {
      type: String,
      required: false
    },
    tableKey: {
      type: String,
      required: true
    },
    extra: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      isNow: true,

      rows: []
    };
  },
  methods: {
    ...mapActions('stat', {
      'loadStatData':'loadStatData'
    }),
    getFieldId(name) {
      return `${this.getFullKey()}__${name}`;
    },
    getFullKey() {
      return this.extra && this.extra.type ? `${this.extra.type}_${this.tableKey}` : this.tableKey;
    },
    async updateTable() {
      this.rows = await this.loadStatData({
        statDataKey: this.tableKey,
        params: {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          isNow: this.isNow,
          extra: this.extra
        }
      });
    },
  },
  mounted() {
    const range = getLastMonthRange();
    this.dateFrom = range.from;
    this.dateTo = range.to;
    this.updateTable();
  }
}
</script>
