<template>
  <Tree :treeId="treeId" :baseUrl="baseUrl" :context="context" v-slot="{ node }">
    <IncomingContent :node="node"/>
  </Tree>
</template>

<script>
import Tree from '../Base/Tree.vue';
import IncomingContent from './IncomingContent.vue';

export default {
  name: "IncomingTree",
  components: {
    Tree,
    IncomingContent
  },
  props: {
    treeId: String,
    context: Object,
    baseUrl: String
  },
}
</script>

<style lang="scss">

</style>