<template>
  <div class="cart-result-block">
    <div class="cart-result-block__total-count">
      Итого {{ count }} товаров на сумму:
    </div>
    <div class="cart-result-block__total-price">
      {{ formatPrice(total) }} ₽
    </div>

    <button
        type="button"
        class="cart-result-block__button cart-result-block__button_send button"
        :disabled="isDisable"
        @click="send"
    >
      Оформить заказ
    </button>

    <a href="/catalog" class="cart-result-block__button cart-result-block__button_continue button button__secondary">
      Продолжить покупки
    </a>

    <div class="cart-result-block__one-c-block" v-if="forOneCOrder">
      <div class="cart-result-block__one-c-label">
        Отправка заказа 1с <br>
        №{{ forOneCOrder }}
      </div>
      <a href="/manager/api/cancel-one-c" class="cart-result-block__button cart-result-block__button_continue button button__secondary">
        Отменить
      </a>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from "vuex";

export default {
  name: "TheCartResult",
  computed: {
    ...mapState({
      'checkout': 'cart/checkout',
    }),
    ...mapGetters({
      'isForManager': 'cart/isForManager',
      'forOneCOrder': 'cart/forOneCOrder',
      'count': 'cart/countPositions',
      'total': 'cart/total',
      'currentClientId': 'targetClient/currentClientId',
      'currentPurchaserId': 'targetClient/currentPurchaserId'
    }),
    isDisable() {
      if (this.isForManager) {
        if (!this.currentClientId && !this.currentPurchaserId) {
          return true;
        }

        if (!this.total || !this.count) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions('order', {
      'send': 'send'
    }),
    formatPrice(rawValue) {
      return this.$root.formatPrice(rawValue);
    }
  }
}
</script>