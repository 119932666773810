var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-auto-filter-extend" },
    [
      _c("div", { staticClass: "catalog-auto-filter-extend__info-wrap" }, [
        _c(
          "div",
          { staticClass: "catalog-auto-filter-extend__modification-name" },
          [_vm._v("\n      " + _vm._s(_vm.currentModification.name) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "catalog-auto-filter-extend__info-list" },
          _vm._l(_vm.currentModification.info, function (info) {
            return _c(
              "div",
              { staticClass: "catalog-auto-filter-extend__info-item" },
              [
                _c(
                  "div",
                  { staticClass: "catalog-auto-filter-extend__info-name" },
                  [_vm._v("\n          " + _vm._s(info.name) + "\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "catalog-auto-filter-extend__info-value" },
                  [_vm._v("\n          " + _vm._s(info.value) + "\n        ")]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "catalog-auto-filter-extend__fields-wrap",
          on: { submit: _vm.submit },
        },
        [
          _c(
            "div",
            { staticClass: "catalog-auto-filter-extend__top" },
            _vm._l(_vm.getTopFields, function (field) {
              return _c(
                "div",
                { staticClass: "catalog-auto-filter-extend__field" },
                [
                  field.view_type === "select-multi"
                    ? _c("BaseFieldSelectMulti", {
                        attrs: {
                          values: field.values,
                          "field-id": field.key,
                          label: field.label,
                          currentValues: field.current_value,
                          "disable-values": field.disable_values,
                          "has-filter": field.has_filter,
                        },
                        on: { input: _vm.debounceChange },
                      })
                    : field.view_type === "range"
                    ? _c("BaseRangeSlider", {
                        attrs: {
                          "field-id": field.key,
                          label: field.label,
                          borders: field.borders,
                          "state-value": field.current_value,
                          disabled: field.disabled,
                        },
                        on: { change: _vm.change },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "catalog-auto-filter-extend__middle" },
            _vm._l(_vm.getMiddleFields, function (field) {
              return _c(
                "div",
                {
                  staticClass: "catalog-auto-filter-extend__field",
                  class: "_" + field.key,
                },
                [
                  field.view_type === "select-multi"
                    ? _c("BaseFieldSelectMulti", {
                        attrs: {
                          values: field.values,
                          "field-id": field.key,
                          label: field.label,
                          currentValues: field.current_value,
                          "disable-values": field.disable_values,
                          "has-filter": field.has_filter,
                        },
                        on: { input: _vm.debounceChange },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  field.view_type === "checkbox-list"
                    ? _c("BaseFieldCheckboxList", {
                        attrs: {
                          "input-id": field.key,
                          "current-values": field.current_value,
                          "possible-values": field.values,
                          "disabled-values": field.disable_values,
                        },
                        on: { change: _vm.debounceChange },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  field.view_type === "checkbox"
                    ? _c("BaseFieldCheckbox", {
                        attrs: {
                          label: field.label,
                          "input-id": field.key,
                          value: field.current_value,
                          disabled: field.disabled,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.change(field)
                          },
                        },
                        model: {
                          value: field.current_value,
                          callback: function ($$v) {
                            _vm.$set(field, "current_value", $$v)
                          },
                          expression: "field.current_value",
                        },
                      })
                    : field.view_type === "range"
                    ? _c("BaseRangeSlider", {
                        attrs: {
                          "field-id": field.key,
                          label: field.label,
                          borders: field.borders,
                          "state-value": field.current_value,
                          disabled: field.disabled,
                        },
                        on: { change: _vm.change },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "catalog-auto-filter-extend__bottom" }, [
            _c(
              "div",
              { staticClass: "catalog-auto-filter-extend__bottom-fields" },
              _vm._l(_vm.getBottomFields, function (field) {
                return _c(
                  "div",
                  { staticClass: "catalog-auto-filter-extend__field" },
                  [
                    field.view_type === "checkbox"
                      ? _c("BaseFieldCheckbox", {
                          attrs: {
                            label: field.label,
                            "input-id": field.key,
                            value: field.current_value,
                            disabled: field.disabled,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.change(field)
                            },
                          },
                          model: {
                            value: field.current_value,
                            callback: function ($$v) {
                              _vm.$set(field, "current_value", $$v)
                            },
                            expression: "field.current_value",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "catalog-auto-filter-extend__buttons" }, [
              _c(
                "button",
                {
                  staticClass:
                    "catalog-auto-filter-extend__button button__secondary",
                  on: { click: _vm.reset },
                },
                [_vm._v("\n          Сбросить фильтры\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "catalog-auto-filter-extend__button",
                  attrs: { type: "submit", disabled: _vm.disableSubmit },
                },
                [_vm._v("\n          Применить фильтры\n        ")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.loading ? _c("preloader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }