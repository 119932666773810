<template>
  <div class="cart-page__vue">
    <div class="cart-page__container grid-block" v-if="(count)">
      <div class="cart-page__cart-wrapper">

        <the-cart-client-selector v-if="isForManager"/>

        <div class="cart-page__products">
          <ul class="cart-page__products-list">
            <li class="cart-page__products-item" v-for="item in checkout.items">
              <cart-product
                :item="item"
              />
            </li>
          </ul>
        </div>

        <the-cart-order-comment/>

        <the-cart-manager-form v-if="isForManager"/>
      </div>

      <div class="cart-page__sidebar">
        <div class="cart-page__sidebar-sticky">
          <the-cart-result/>
        </div>
      </div>
    </div>

    <div class="cart-page__empty" v-else>
      <h2 class="cart-page__empty-text">
        Ваша корзина пуста
      </h2>

      <div class="cart-page__empty-text-subtitle">
        Перейдите в каталог для выбора товаров
      </div>

      <div class="cart-page__empty-button-wrapper">
        <a href="/catalog/tyre/car" class="cart-page__empty-button button button__secondary">
          <TyreIcon class="cart-page__icon"/>
          <span class="cart-page__empty-button-text">Шины</span>
        </a>
        <a href="/catalog/wheel/car" class="cart-page__empty-button button button__secondary">
          <WheelIcon class="cart-page__icon"/>
          <span class="cart-page__empty-button-text">Диски</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import TheCartResult from "./TheCartResult.vue";
import TheCartOrderComment from "./TheCartOrderComment.vue";
import TheCartClientSelector from "./TheCartClientSelector.vue";
import CartProduct from "./CartProduct.vue";
import TyreIcon from '../icons/TyreIcon.vue';
import WheelIcon from './WheelIcon.vue';
import TheCartManagerForm from './TheCartManagerForm.vue';

export default {
  name: "TheCartPage",
  components: {
    TheCartManagerForm,
    WheelIcon,
    TyreIcon,
    TheCartResult,
    TheCartOrderComment,
    TheCartClientSelector,
    CartProduct
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout'
    }),
    ...mapGetters('cart', {
      'total': 'total',
      'count': 'countProducts',
      'isForManager': 'isForManager'
    })
  },
  methods: {
    ...mapActions({
      'loadCheckout': 'cart/loadCheckout',
      'fetchCheckout': 'cart/fetchCheckout',
      'loadTargetClientsInfo': 'targetClient/loadTargetClientsInfo'
    }),
  },
  mounted() {
    this.loadCheckout();
  },
}
</script>
