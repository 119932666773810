class Runner { // TODO сделать красиво
  constructor(element) {
    this.element = element;
  }

  translate(index) {
    let bulletWidth = 0;
    let bulletsListGap = 0;
    let runnerWidth = 0;

    if (window.innerWidth > 1279) {
      bulletWidth = 8;
      bulletsListGap = 16;
      runnerWidth = 32;
    } else {
      bulletWidth = 6;
      bulletsListGap = 10;
      runnerWidth = 22;
    }

    this.element.style.width = `${bulletWidth}px`;
    this.element.style.transform = `translateX(${index * (bulletWidth + bulletsListGap)}px)`;
    setTimeout(() => {
      this.element.style.width = `${runnerWidth}px`;
    }, 400);
  }
}

export default Runner;