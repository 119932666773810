import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  props: {
    treeId: String,
  },
  computed: {
    ...mapGetters('tree', {
      proxyTreeIsLoading: 'isLoading',
    }),
    treeIsLoading() {
      return this.proxyTreeIsLoading(this.treeId);
    },
    ...mapGetters('tree', {
      proxyTreeGetTree: 'getTree',
    }),
    treeGetTree() {
      return this.proxyTreeGetTree(this.treeId);
    },
    ...mapGetters('tree', {
      proxyGetFieldMetadata: 'getFieldMetadata',
    }),
    ...mapGetters('tree', {
      proxyGetFields: 'getFields',
    }),
    ...mapGetters('tree', {
      proxyPreloadedFields: 'getPreloadedFields',
    }),
  },
  methods: {
    treeGetFieldMetadata(fieldName) {
      return this.proxyGetFieldMetadata(this.treeId, fieldName);
    },
    treeGetFields() {
      return this.proxyGetFields(this.treeId);
    },
    isScalarValue(condition) {
      return !(condition === 'in' || condition === 'nin');
    },
    getPreloadedFieldValues(fieldName) {
      return this.proxyPreloadedFields(this.treeId, fieldName);
      // if (this.treeGetTree.preloadedFieldsValues
      //   .find((preloadedField) => preloadedField.fieldName === fieldName)
      //   .values) {
      //   return this.treeGetTree.preloadedFieldsValues
      //     .find((preloadedField) => preloadedField.fieldName === fieldName)
      //     .values;
      // }
      // return null;
    },
  },
};