import { onDomChanges } from '../../components/dynamic/observer';
import { singleInit } from '../../components/dynamic/bind';
import Sorter from './sorter';

function initSorters() {
  document.querySelectorAll('[data-sorter-list]')
    .forEach((element) => singleInit(element, 'initialized', (sorterElement) => {
      new Sorter(sorterElement);
    }));
}

onDomChanges(initSorters);