<template>
  <div class="checkbox-list">
    <div class="checkbox-list__range-wrap" v-if="this.hasRange">
      <input type="text"
             class="checkbox-list__range-input checkbox-list__range-input_from"
             placeholder="от"
             autocomplete="off"
             @input="updateRangeInputFrom"
             :ref="'from'"
      >

      <input type="text"
             class="checkbox-list__range-input checkbox-list__range-input_to"
             placeholder="до"
             autocomplete="off"
             @input="updateRangeInputTo"
             :ref="'to'"
      >
    </div>

    <label v-if="label" class="checkbox-list__main-label">{{ label }}</label>
    <div class="checkbox-list__chunks">
      <div v-for="group in groupedValues" class="checkbox-list__chunk">
        <div v-for="param in group" class="checkbox-list__item">
          <input type="checkbox"
                 class="checkbox-list__item"
                 :id="uniqueId(inputId, valueAsString(param))"
                 :value="valueAsString(param)"
                 :disabled="checkDisabled(valueAsString(param))"
                 :checked="shouldBeChecked(valueAsString(param))"
                 @change="updateInput"
                 :ref="'checkbox'"
          >
          <label class="checkbox-list__label" :for="uniqueId(inputId, valueAsString(param))">
            {{ nameAsString(param) }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from "vue";

export default {
  name: "BaseFieldCheckboxListChunked",
  props: {
    /*
      Все возможные значения для чекбоксов
      - список объектов с полями 'name' и 'value'. Может еще icon
    */
    possibleValues: {
      required: true
    },
    // Объект для v-model
    currentValues: {
      required: true
    },
    // Склеивается со значениями инпутов
    inputId: {
      type: String,
      required: true
    },
    // Список значений на дисейбл (строки)
    disabledValues: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    /*
      Лейбл для всего листа
     */
    label: {
      type: String,
      required: false
    },
    hasRange: {
      type: Boolean,
      required: false
    },
    bus: {
      type: Vue,
      required: false,
    }
  },
  data() {
    return {
      debounceOrderFrom: 0,
      debounceOrderTo: 0,
      min: '',
      max: '',

      isMobile: false,
    }
  },
  computed: {
    groupedValues() {
      const result = [];
      const columns = this.getColumnCount();
      const maxInGroup = Math.ceil(this.possibleValues.length / columns);

      for (let i = 0; i < this.possibleValues.length; i += maxInGroup) {
        result.push(this.possibleValues.slice(i, i + maxInGroup));
      }

      this.$emit('recomputedColumns', this.inputId);

      return result;
    },

  },
  methods: {
    getColumnCount() {
      // Костылим количество колонок

      if (!this.isMobile) {
        return 5;
      } else {
        const charWidth = 10; // Примерно {10px} на символ
        let availSpace = window.innerWidth; // Берем ширину экрана
        availSpace -= 20 * 4; // убираем внутренние и внешние отступы
        // Находим самое длинное слово
        const maxLength = Math.max(...this.possibleValues.map((value) => value.name.toString().length));
        if (isNaN(maxLength)) {
          return 1;
        }
        // Считаем сколько нужно на колонку: слово + чекбокс + гап
        const minColumnWidth = maxLength * charWidth + 25 + 20;
        const columns = Math.floor(availSpace / minColumnWidth);
        if (columns) {
          // console.log(
          //     this.inputId,
          //     this.possibleValues
          // )
          return columns;
        }

        return 1;
      }
    },
    // Проверка значения на наличие в списке недоступных значений
    checkDisabled(value) {
      return this.disabledValues ? this.disabledValues.includes(value) : false;
    },
    // Поклейка уникального id
    uniqueId(inputId, value) {
      return `${inputId}_${value}`;
    },
    // Если объект со свойством value, вернет value, иначе пусть это будет строка
    valueAsString(item) {
      return item.value === undefined ? '' : item.value;
    },
    nameAsString(item) {
      return item.name === undefined ? '' : item.name;
    },
    // При изменении инпута
    updateInput(event) {
      const target = event.target;
      // Если инпут стал активным
      if (target.checked) {
        // Добавляем значение в текущие значения
        this.currentValues.push(target.value)
      } else {
        // Иначе убираем от туда
        this.currentValues.splice(this.currentValues.indexOf(target.value), 1)
      }
      // Даем родителю знать, что что-то в списке поменялось
      this.$emit('change', event);
    },
    // При изменении инпута "От"
    updateRangeInputFrom(event) {
      this.debounceUpdateFrom((e) => {
        const value = e.target.value;
        // Если инпут не пустой
        if (value) {
          this.possibleValues.every((possibleValue, index) => {
            // Если значение чекбокса больше равно значения в инпуте
            if (possibleValue.name >= parseInt(value, 10)) {
              // записываем как минимальное значение и продолжаем поиск
              this.min = index;
              return false;
            }
            // Если значение всех чекбоксов меньше значения в инпуте то минимальным значением будет последний чекбокс
            this.min = null;
            return true;
          });
        } else {
          // Если инпут пустой то очищаем минимальное значение
          this.min = '';
        }
        this.clearAllInputs();
        this.setCheckboxes();
      }, event)
    },
    // При изменении инпута "До"
    updateRangeInputTo(event) {
      this.debounceUpdateTo((e) => {
        const value = e.target.value;
        // Если инпут не пустой
        if (value) {
          // Ищем совпадения в возможных чекбоксах
          // Поиск с конца к началу
          this.possibleValues.slice().reverse().every((possibleValue, index) => {
            // Если значение чекбокса меньше равно значения в инпуте
            if (possibleValue.name <= parseInt(value, 10)) {
              // записываем как максимальное значение и продолжаем поиск
              this.max = (this.possibleValues.length - 1) - index;
              return false;
            }
            // Если значение всех чекбоксов меньше значения в инпуте то максимальным значением будет первый чекбокс
            this.max = this.$refs.checkbox[0];
            return true;
          });
        } else {
          this.max = '';
        }

        this.clearAllInputs();
        this.setCheckboxes();
      }, event);
    },
    clearAllInputs() {
      this.$refs.checkbox.forEach((input) => {
        input.checked = false
        input.dispatchEvent(new Event('change'));
      });
    },
    setCheckboxes() {
      let chosenInputs;
      if ((this.min || this.min === 0) && this.max === '') {
        chosenInputs = this.$refs.checkbox.filter((input, index) => index >= this.min && index <= this.possibleValues.length - 1);
      } else if ((this.min || this.min === 0) && (this.max || this.max === 0)) {
        chosenInputs = this.$refs.checkbox.filter((input, index) => index >= this.min && index <= this.max);
      } else if (this.min === '' && (this.max || this.max === 0)) {
        chosenInputs = this.$refs.checkbox.filter((input, index) => index >= this.possibleValues[0].name && index <= this.max);
      }

      if (chosenInputs) {
        chosenInputs.forEach((input) => {
          input.checked = true;
          input.dispatchEvent(new Event('change'));
        })
      }
    },
    // Проверяем есть ли значение в текущих значениях
    shouldBeChecked(value) {
      return this.currentValues ? this.currentValues.includes(value) : false;
    },
    debounceUpdateFrom(callback, arg) {
      this.debounceOrderFrom = this.debounceOrderFrom ? this.debounceOrderFrom + 1 : 1;
      setTimeout(() => {
        this.debounceOrderFrom = this.debounceOrderFrom - 1;
        if (!this.debounceOrderFrom) {
          callback(arg);
        }
      }, 1000);
    },
    debounceUpdateTo(callback, arg) {
      this.debounceOrderTo = this.debounceOrderTo ? this.debounceOrderTo + 1 : 1;
      setTimeout(() => {
        this.debounceOrderTo = this.debounceOrderTo - 1;
        if (!this.debounceOrderTo) {
          callback(arg);
        }
      }, 1000);
    },
  },
  created() {
    // слушатель родительского события сброса фильтров
    this.bus.$on('resetFilters', () => {
      // Очищаем инпуты диапазонов
      if (this.$refs.from) {
        this.$refs.from.value = '';
        this.min = '';
      }
      if (this.$refs.to) {
        this.$refs.to.value = '';
        this.max = '';
      }
    });
  },
  mounted() {
    if (window.innerWidth < 1280) {
      this.isMobile = true;
    }
  }
}
</script>

<style lang="scss">

</style>