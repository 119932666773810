<template>
  <div class="stat-page__content-inner">
    <div class="stat-page__row">
      <key-value-table class="stat-page__table"
          :name="'Количество товаров по поставщикам'"
          :tableKey="'products_by_providers_table'"
          :extra="{ type: this.productType}"
      />
      <key-value-table  class="stat-page__table"
          :name="'Количество товаров на складах'"
          :tableKey="'products_by_storages_table'"
          :extra="{ type: this.productType}"
      />
    </div>
  </div>
</template>

<script>
import KeyValueTable from "./tables/KeyValueTable.vue";

export default {
  name: "StatProducts",
  components: {
    KeyValueTable
  },
  props: {
    productType: {
      type: String,
      required: false
    }
  },
}
</script>
