import axios from 'axios';

const ManagerService = {
  async getClientsInfo() {
    return axios.post('/manager/api/get_clients_info');
  },
  async setClient(clientId) {
    return axios.post('/manager/api/set_client', { clientId });
  },
  async resetClient() {
    return axios.post('/manager/api/reset_client');
  },
  async setPurchaser(purchaserId) {
    return axios.post('/manager/api/set_purchaser', { purchaserId });
  },
  async resetPurchaser() {
    return axios.post('/manager/api/reset_purchaser');
  },
};

export default ManagerService;