var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkbox-list" },
    [
      _c(
        "div",
        { staticClass: "checkbox-list__group-switch" },
        [
          _vm.managerLoaded
            ? _c("BaseFieldSwitch", {
                attrs: {
                  "input-id": _vm.uniqueId(_vm.inputId, "switcher"),
                  label: "Сгруппировать по поставщикам",
                },
                on: { change: _vm.onSwitch },
                model: {
                  value: _vm.groupByProvider,
                  callback: function ($$v) {
                    _vm.groupByProvider = $$v
                  },
                  expression: "groupByProvider",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "checkbox-list__group" }, [
        _vm.checkAll
          ? _c("div", { staticClass: "checkbox-list__group-value" }, [
              _c("input", {
                staticClass: "checkbox-list__input",
                attrs: {
                  type: "checkbox",
                  id: _vm.uniqueId(_vm.inputId, "all"),
                  name: _vm.uniqueId(_vm.inputId, "all"),
                },
                domProps: { value: _vm.defaultValue.value },
                on: { change: _vm.changeAll },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "checkbox-list__label",
                  attrs: { for: _vm.uniqueId(_vm.inputId, "all") },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.defaultValue.name) + "\n      "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._l(_vm.groupedValues, function (group) {
        return _c("div", { staticClass: "checkbox-list__group" }, [
          _c("label", { staticClass: "checkbox-list__group-name" }, [
            _vm._v("\n      " + _vm._s(group.name) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "checkbox-list__group-values" },
            _vm._l(group.values, function (value) {
              return _c("div", { staticClass: "checkbox-list__group-value" }, [
                _c("input", {
                  ref: "checkbox",
                  refInFor: true,
                  staticClass: "checkbox-list__item",
                  attrs: {
                    type: "checkbox",
                    id: _vm.uniqueId(_vm.inputId, value.value),
                    disabled: _vm.checkDisabled(value.value),
                  },
                  domProps: {
                    value: value.value,
                    checked: _vm.shouldBeChecked(value.value),
                  },
                  on: { change: _vm.updateInput },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "checkbox-list__label",
                    attrs: { for: _vm.uniqueId(_vm.inputId, value.value) },
                  },
                  [_vm._v("\n          " + _vm._s(value.name) + "\n        ")]
                ),
              ])
            }),
            0
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }