import axios from 'axios';
import Jax from '../../components/jax/jax';
import { onDomReady } from '../../components/dynamic/observer';

class IdentifyFilterForm {
  constructor(element) {
    this.form = element;
    this.selects = Array.from(element.querySelectorAll('select'));
    this.checboxes = Array.from(element.querySelectorAll('input[type="checkbox"]'));

    this.removeItemUrl = '/identify/remove_item/';

    this.bind();
  }

  bind() {
    this.bindChange(this.selects);
    this.bindChange(this.checboxes);
    this.bindRemoves();
  }

  bindChange(inputs) {
    inputs.forEach((input) => {
      input.addEventListener('change', (e) => {
        this.request();
      });
    });
  }

  async request() {
    const jax = new Jax();
    const html = await jax.send(new FormData(this.form));

    const page = document.createElement('div');
    page.innerHTML = html;

    const currentContent = document.querySelector('[data-identify-index-content]');
    const newContent = page.querySelector('[data-identify-index-content]');

    const currentTotal = document.querySelector('[data-identfy-total]');
    const newTotal = page.querySelector('[data-identfy-total]');

    currentContent.innerHTML = newContent.innerHTML;
    currentTotal.innerHTML = newTotal.innerHTML;

    this.bindRemoves();

    document.dispatchEvent(new Event('DOMContentMutated'));
  }

  bindRemoves() {
    document.querySelectorAll('[data-remove-fail]').forEach((item) => {
      console.log(item)
      item.addEventListener('click', (e) => {
        e.preventDefault();
        axios.post(item.getAttribute('href'));
        item.closest('[data-failed-item]').remove();
      });
    });
  }
}

onDomReady(() => {
  const element = document.querySelector('[data-identify-filter-form]');
  if (element) {
    new IdentifyFilterForm(element);
  }
})