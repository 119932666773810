var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-list" }, [
    this.hasRange
      ? _c("div", { staticClass: "checkbox-list__range-wrap" }, [
          _c("input", {
            ref: "from",
            staticClass:
              "checkbox-list__range-input checkbox-list__range-input_from",
            attrs: { type: "text", placeholder: "от", autocomplete: "off" },
            on: { input: _vm.updateRangeInputFrom },
          }),
          _vm._v(" "),
          _c("input", {
            ref: "to",
            staticClass:
              "checkbox-list__range-input checkbox-list__range-input_to",
            attrs: { type: "text", placeholder: "до", autocomplete: "off" },
            on: { input: _vm.updateRangeInputTo },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.label
      ? _c("label", { staticClass: "checkbox-list__main-label" }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "checkbox-list__chunks" },
      _vm._l(_vm.groupedValues, function (group) {
        return _c(
          "div",
          { staticClass: "checkbox-list__chunk" },
          _vm._l(group, function (param) {
            return _c("div", { staticClass: "checkbox-list__item" }, [
              _c("input", {
                ref: "checkbox",
                refInFor: true,
                staticClass: "checkbox-list__item",
                attrs: {
                  type: "checkbox",
                  id: _vm.uniqueId(_vm.inputId, _vm.valueAsString(param)),
                  disabled: _vm.checkDisabled(_vm.valueAsString(param)),
                },
                domProps: {
                  value: _vm.valueAsString(param),
                  checked: _vm.shouldBeChecked(_vm.valueAsString(param)),
                },
                on: { change: _vm.updateInput },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "checkbox-list__label",
                  attrs: {
                    for: _vm.uniqueId(_vm.inputId, _vm.valueAsString(param)),
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.nameAsString(param)) +
                      "\n        "
                  ),
                ]
              ),
            ])
          }),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }