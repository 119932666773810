<template>
  <a href="javascript:void(0);" class="plus-button" @click="emitClick" :class="{'_extend': isOpen}">
      <span class="plus-button__circle">
          <span class="plus-button__line"></span>
          <span class="plus-button__line"></span>
        </span>

    <span v-if="text && isOpen" class="plus-button__text">{{ getSwitchText() }}</span>
    <span v-else-if="text && !isOpen" class="plus-button__text">{{ text }}</span>
  </a>
</template>

<script>
export default {
  name: "plus-button",
  data: () => {
    return {
      opened: false
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    textAlt: {
      type: String,
      required: false
    }
  },
  methods: {
    getSwitchText() {
      return this.textAlt ? this.textAlt : this.text;
    },
    emitClick() {
      this.$emit('click');
    }
  }
}
</script>