var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-filter" },
    [
      _vm.loading ? _c("preloader") : _vm._e(),
      _vm._v(" "),
      _vm.hasAutoTab
        ? _c("div", { staticClass: "catalog-filter__top" }, [
            _c("div", { staticClass: "catalog-filter__tabs-wrap" }, [
              _c("ul", { staticClass: "smart-tabs tabs tabs_black" }, [
                _c(
                  "li",
                  {
                    staticClass: "smart-tab tabs__item",
                    class: { _active: _vm.isParamFilter },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "tabs__link",
                        attrs: { href: "javascript:void(0);" },
                        on: { click: _vm.clickParamTab },
                      },
                      [_vm._v("\n            По параметрам\n          ")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "smart-tab tabs__item",
                    class: { _active: _vm.isAutoFilter },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "tabs__link",
                        attrs: { href: "javascript:void(0);" },
                        on: { click: _vm.clickAutoTab },
                      },
                      [_vm._v("\n            По автомобилю\n          ")]
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isParamFilter
        ? _c("TheFilterParam")
        : _vm.isAutoFilter
        ? _c("TheFilterAuto")
        : _vm._e(),
      _vm._v(" "),
      _c("TheFilterPanel"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }