import PriceMovesService from '../../services/priceMoves.service';

const PriceMovesModule = {
  namespaced: true,
  state: {},
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async loadStatData({ }, {
      statDataKey,
      productData,
      formData,
    }) {
      let result = null;
      switch (statDataKey) {
        case 'provider_prices_moves_graph': result = await PriceMovesService.fetchPriceMoves(
          productData.productClass,
          productData.productId, formData,
        );
          break;

        case 'client_margins_graph': result = await PriceMovesService.fetchAvgClientMargins(
          productData.productClass,
          productData.productId,
          formData,
        );
          break;

        case 'mrc_margins_graph': result = await PriceMovesService.fetchAvgMrcMargins(
          productData.productClass,
          productData.productId,
          formData,
        );
          break;
        default: break;
      }
      if (result) {
        return result.data;
      }

      return null;
    },
  },
};

export default PriceMovesModule;