var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-result-block" }, [
    _c("div", { staticClass: "cart-result-block__total-count" }, [
      _vm._v("\n    Итого " + _vm._s(_vm.count) + " товаров на сумму:\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cart-result-block__total-price" }, [
      _vm._v("\n    " + _vm._s(_vm.formatPrice(_vm.total)) + " ₽\n  "),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass:
          "cart-result-block__button cart-result-block__button_send button",
        attrs: { type: "button", disabled: _vm.isDisable },
        on: { click: _vm.send },
      },
      [_vm._v("\n    Оформить заказ\n  ")]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass:
          "cart-result-block__button cart-result-block__button_continue button button__secondary",
        attrs: { href: "/catalog" },
      },
      [_vm._v("\n    Продолжить покупки\n  ")]
    ),
    _vm._v(" "),
    _vm.forOneCOrder
      ? _c("div", { staticClass: "cart-result-block__one-c-block" }, [
          _c("div", { staticClass: "cart-result-block__one-c-label" }, [
            _vm._v("\n      Отправка заказа 1с "),
            _c("br"),
            _vm._v("\n      №" + _vm._s(_vm.forOneCOrder) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "cart-result-block__button cart-result-block__button_continue button button__secondary",
              attrs: { href: "/manager/api/cancel-one-c" },
            },
            [_vm._v("\n      Отменить\n    ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }