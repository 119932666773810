import axios from 'axios';
import { onDomChanges, onDomReady } from '../../components/dynamic/observer';

class FileFieldInput {
  constructor(element) {
    element.dataset.initialized = 'true';
    this.element = element;
    this.checkbox = this.element.querySelector('input[type="file"]');

    this.bind();
  }

  bind() {
    this.bindCheckbox();
  }

  bindCheckbox() {
    this.checkbox.addEventListener('change', () => {
      const form = this.checkbox.closest('form');
      const file = this.checkbox.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.sendFile(form.getAttribute('action'), formData);
    });
  }

  sendFile(url, data) {
    try {
      axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((result) => {
        window.location.reload();
      });
    } catch (e) {
      console.log('error', e);
    }
  }
}

function init() {
  const fields = document.querySelectorAll('[data-file-download-input]:not([data-initialized="true"])');
  fields.forEach((field) => new FileFieldInput(field));
}

onDomChanges(() => {
  setTimeout(init, 200);
});