<template>
  <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 4H8.5" stroke="#7D7984" stroke-width="1.5"/>
    <path d="M4.5 0L4.5 8" stroke="#7D7984" stroke-width="1.5"/>
  </svg>

</template>

<script>
export default {
  name: "PlusIcon"
}
</script>