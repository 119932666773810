<template>
  <div class="notifications-block" :class="{'_show': count}">
    <div class="notifications-block__outer">
      <div class="container container_spacer">
        <div class="notifications-block__inner">
          <ul class="notifications-block__list">
            <li
                class="notifications-block__item"
                v-for="(notification, key) in notifications"
                :key="notification.key"
                :class="['_' + notification.type]"
            >
              <div class="notifications-block__icon">
                <warning-icon v-if="notification.type === 'error'"/>
                <success-green-icon v-else-if="notification.type === 'success'"/>
              </div>

              <div class="notifications-block__text">
                {{ notification.text }}
              </div>

              <a href="javascript:void(0);" class="notifications-block__closer" @click="removeNotification(key)">
                <closer-icon/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WarningIcon from '../icons/WarningIcon.vue';
import CloserIcon from '../icons/CloserIcon.vue';
import SuccessGreenIcon from '../icons/SuccessGreenIcon.vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

export default {
  name: "TheNotifications",
  components: {
    CloserIcon,
    WarningIcon,
    SuccessGreenIcon
  },
  computed: {
    ...mapState('notifications', {
      'notifications': 'notifications',
      'count': 'count'
    }),
    count() {
      return Object.keys(this.notifications).length;
    }
  },
  methods: {
    ...mapMutations('notifications', {
      'removeNotification': 'removeNotification',
    }),
    ...mapActions('notifications', {
      'notifyListeners': 'notifyListeners'
    }),
  },
  mounted() {
    this.notifyListeners();
  }
}
</script>