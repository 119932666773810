import { onDomChanges } from '../../components/dynamic/observer';

function recalcTextareaHeight(element) {
  const numberOfLineBreaks = (element.value.match(/\n/g) || []).length;
  const styles = window.getComputedStyle(element);
  const padding = parseInt(styles.paddingTop, 10) + parseInt(styles.paddingBottom, 10);
  const lineHeight = parseInt(styles.lineHeight, 10);
  const newHeight = lineHeight + numberOfLineBreaks * lineHeight + padding + 2;
  element.style.height = `${newHeight}px`;
}
onDomChanges(() => {
  const selector = '[data-resizable-textarea] textarea:not([data-init-resizable="true"])';
  document.querySelectorAll(selector).forEach((element) => {
    element.dataset.initResizable = 'true';
    recalcTextareaHeight(element);
    element.addEventListener('keyup', () => {
      recalcTextareaHeight(element);
    });
  });
});