<template>
  <div class="stat-page__content">
    <div class="stat-page__inner-content">
      <div class="stat-page____row">
        <div class="stat-page__large-block">
          <BaseGraph
              graph-key="durations-graph"
              title="Отслеживание продолжительности (сек.)"
              :initial-range="getDayRange()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseGraph from "./graphs/BaseGraph.vue";
import getLastDay from '../../helpers/dates'
export default {
  name: "TheSyncPage",
  components: {
    BaseGraph
  },
  methods: {
    getDayRange() {
      return getLastDay();
    }
  }
}
</script>
