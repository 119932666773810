import Vue from 'vue';

const notificationModule = {
  namespaced: true,
  state: {
    notifications: {},
    id: 0,
  },
  actions: {
    showSuccessNotification({ commit, state }, text) {
      commit('nextId');
      const { id } = state;
      const type = 'success';
      commit('addNotification', { id, type, text: text || 'Успешно' });
      setTimeout(() => {
        commit('removeNotification', id);
      }, 5000);
    },
    showErrorNotification({ commit, state }, text) {
      commit('nextId');
      const { id } = state;
      const type = 'error';
      commit('addNotification', { id, type, text: text || 'Произошла ошибка' });
      setTimeout(() => {
        commit('removeNotification', id);
      }, 5000);
    },
    notifyListeners({ dispatch }) {
      // Вне VUE события:
      // document.dispatchEvent(new CustomEvent('showErrorNotification', {detail: {text: 'Ошибка'}}))
      // document.dispatchEvent(new CustomEvent('showSuccessNotification', {detail: {text: 'Сакцесс'}}))
      document.addEventListener('showSuccessNotification', (e) => {
        const text = e.detail.text;
        dispatch('showSuccessNotification', text);
      });
      document.addEventListener('showErrorNotification', (e) => {
        const text = e.detail.text;
        dispatch('showErrorNotification', text);
      });
    },
  },
  mutations: {
    addNotification(state, { id, type, text }) {
      const key = Math.random().toString(36).substr(2, 9);
      Vue.set(state.notifications, id, { type, text, key });
    },
    removeNotification(state, id) {
      Vue.delete(state.notifications, id);
    },
    nextId(state) {
      state.id += 1;
    },
  },
};

export default notificationModule;