var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "header__button",
      attrs: { href: "/cart", target: "_blank" },
    },
    [
      _c("cart-gray-icon"),
      _vm._v(" "),
      _vm.count
        ? _c(
            "span",
            { staticClass: "header__button-counter header__cart-counter" },
            [_vm._v("\n      " + _vm._s(_vm.count) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }