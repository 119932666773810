var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-client-actions" }, [
    _c("h3", { staticClass: "stat-client-actions__title" }, [
      _vm._v("\n    Действия клиента по товарам\n  "),
    ]),
    _vm._v(" "),
    _c("form", { staticClass: "stat-client-actions__form" }, [
      _c(
        "div",
        { staticClass: "stat-client-actions__fields" },
        [
          _c("base-field-date", {
            attrs: {
              label: "От",
              "input-id": "client_actions_date_from",
              "enable-time": true,
            },
            on: { change: _vm.update },
            model: {
              value: _vm.dateFrom,
              callback: function ($$v) {
                _vm.dateFrom = $$v
              },
              expression: "dateFrom",
            },
          }),
          _vm._v(" "),
          _c("base-field-date", {
            attrs: {
              label: "До",
              "input-id": "client_actions_date_to",
              "enable-time": true,
            },
            on: { change: _vm.update },
            model: {
              value: _vm.dateTo,
              callback: function ($$v) {
                _vm.dateTo = $$v
              },
              expression: "dateTo",
            },
          }),
          _vm._v(" "),
          _c("base-field-select-multi", {
            attrs: {
              "field-id": "client_actions_date_type",
              values: _vm.availTypes,
              label: "Тип",
              "no-default": true,
              "current-values": _vm.currentTypes,
            },
            on: {
              input: function (e) {
                return _vm.update()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-client-actions__table" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.isLoaded
          ? _c("preloader")
          : _vm.actions
          ? _c(
              "ul",
              { staticClass: "stat-client-actions__list" },
              _vm._l(_vm.actions, function (action) {
                return _c("li", { staticClass: "stat-client-actions__item" }, [
                  _c(
                    "div",
                    { staticClass: "stat-client-actions__item-value _date" },
                    [
                      _vm._v(
                        "\n          " + _vm._s(action.date) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "stat-client-actions__item-value _time" },
                    [
                      _vm._v(
                        "\n          " + _vm._s(action.time) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "stat-client-actions__item-value _type",
                      class: _vm.getTypeClass(action.typeEnum),
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(action.type) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "stat-client-actions__item-value _product" },
                    [
                      action.url
                        ? _c(
                            "a",
                            {
                              staticClass: "stat-client-actions__product",
                              attrs: { href: action.url, "data-modal": "" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(action.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _c(
                            "span",
                            { staticClass: "stat-client-actions__product" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(action.name) +
                                  "\n          "
                              ),
                            ]
                          ),
                    ]
                  ),
                ])
              }),
              0
            )
          : _c("div", { staticClass: "stat-client-actions__empty" }, [
              _vm._v("\n      Нет данных по данному запросу\n    "),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.total && _vm.total > _vm.actions.length
      ? _c("div", { staticClass: "stat-client-actions__total" }, [
          _vm._v(
            "\n    Показано " +
              _vm._s(_vm.actions.length) +
              " строк из " +
              _vm._s(_vm.total) +
              "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stat-client-actions__headers" }, [
      _c("div", { staticClass: "stat-client-actions__header-item _date" }, [
        _vm._v("\n        Дата\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "stat-client-actions__header-item _time" }, [
        _vm._v("\n        Время\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "stat-client-actions__header-item _type" }, [
        _vm._v("\n        Тип\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "stat-client-actions__header-item _product" }, [
        _vm._v("\n        Товар\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }