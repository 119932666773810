<template>
  <div class="vue-field vue-field_select">
    <label v-if="label" :for="uniqueId(fieldId, 'empty')">
      {{ label }}
    </label>

    <div data-vue-custom-select
         class="custom-select custom-select_type_multi"
         :class="{_open: isOpen, 'custom-select_type_filterable': hasFilter}">
      <div class="custom-select__wrapper">
        <div class="custom-select__field-wrapper">
          <input type="hidden" :name="uniqueId(fieldId, 'empty')" :id="uniqueId(fieldId, 'empty')">
          <input type="text" class="custom-select__field"
                 autocomplete="off"
                 :value="getNameByValue(currentValues)"
                 :id="uniqueId(fieldId, 'opener')"
                 @click="openable"
                 @input="manualInput"
                 @focusin="setFocusIn"
                 @focusout="setFocusOut"
          >
          <div class="custom-select__arrow-icon custom-select__arrow-icon_absolute">
            <ArrowSmall/>
          </div>
        </div>
        <div class="custom-select__content-wrap custom-select__content-wrap_openable">
          <ul class="custom-select__list">
            <li v-if="!noDefault" class="custom-select__item">
              <input
                  class="custom-select__input" :class="{'_hidden': isAllHidden}"
                  type="checkbox"
                  :value="defaultValue.value"
                  :id="uniqueId(fieldId)"
                  :name="uniqueId(fieldId)"
                  @change="changeAll"
              >
              <label :for="uniqueId(fieldId)" class="custom-select__label">
                <span class="custom-select__choice-icon">
                  <CheckIconWhite/>
                </span>
                <span class="custom-select__choice-name">
                  {{ defaultValue.name }}
                </span>
              </label>
            </li>
            <li v-for="item in values" class="custom-select__item" :class="{'_hidden': isHidden(item)}">
              <input
                  class="custom-select__input"
                  type="checkbox"
                  :name="uniqueId(fieldId)"
                  :value="valueAsString(item)"
                  :id="uniqueId(fieldId, valueAsString(item))"
                  :disabled="checkDisabled(valueAsString(item))"
                  :checked="shouldBeChecked(valueAsString(item))"
                  @change="change"
              >
              <label :for="uniqueId(fieldId, valueAsString(item))" class="custom-select__label">
                <span class="custom-select__choice-icon">
                  <CheckIconWhite/>
                </span>
                <span class="custom-select__choice-name">
                  {{ nameAsString(item) }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowSmall from '../../icons/ArrowSmall.vue';
import CheckIconWhite from '../../icons/CheckIconWhite.vue';

export default {
  name: "BaseFieldSelect",
  components: {
    ArrowSmall,
    CheckIconWhite
  },
  props: {
    // Уникальный id селекта
    fieldId: {
      type: String,
      required: true
    },
    /*
      Все возможные значения селекта
      - список объектов с полями 'name' и 'value'
    */
    values: {
      type: Array,
      required: true
    },
    currentValues: {
      required: false,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disableValues: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    label: {
      type: String,
      required: false
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    // true - не добавлять null => 'Все'
    noDefault: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultValue: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: 'Все',
          value: null
        }
      }
    },
    dataType: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isOpen: false,
      isBlockChanges: false,
      hiddenValues: [],
      inputText: '',
      isFocus: false
    }
  },
  methods: {
    openable(e) {
      e.preventDefault();
      if (!this.isFocus) {
        this.isOpen = !this.isOpen;
      }
    },
    setFocusIn() {
      this.isFocus = true;
      this.isOpen = true;
      const names = this.getArrayNames(this.currentValues);
      if (names.length) {
        this.inputText = names.join(', ') + ',';
        if (this.hasFilter) {
          this.filter('');
        }
      } else {
        this.inputText = '';
      }
    },
    setFocusOut() {
      this.isFocus = false;
      if (!this.inputText) {
        this.clear();
      }
    },
    manualInput(e) {
      // Введенный текст
      this.inputText = e.target.value;
      // Массив из значений во введенном тексте в нижнем регистре
      const values = this.inputText.toLowerCase().split(',').map((value) => value.trim());

      // Если требуется запускать фильтрацию
      if (this.hasFilter) {
        // Текущее последнее вводимое значение
        const lastValue = values.length ? values[values.length - 1] : '';
        this.filter(lastValue)
        const visible = this.values.filter((valueObj) => !this.hiddenValues.includes(valueObj.value.toString()))
        if (visible.length === 1) {
          const value = visible.pop().value
          this.currentValues.push(value);
          this.$emit('input', {target: { value }});
          return;
        }
      }

      // Перебираем все доступные значения
      this.values.forEach((valueObj) => {
        // Название - в нижний регистр
        const name = valueObj.name.toString().toLowerCase();
        const value = valueObj.value.toString();

        // Если во введенных значениях есть это значение
        if (values.includes(name) && !this.currentValues.includes(value)) {
          this.currentValues.push(value);
          this.$emit('input', {target: { value }});
          return;
        }

        // Заканчиваем функцию, если включена фильтрация и вводимое значение - не первое
        if (this.hasFilter && values.length > 1) {
          return;
        }

        // Если значение поля уже отмечено, но его нет во введенных значениях
        if (!values.includes(name) && this.currentValues.includes(value)) {
          this.$emit('input', {target: { value }});
          this.currentValues.splice(this.currentValues.indexOf(value), 1);
        }
      });
    },
    filter(value) {
      value = value.toLowerCase();
      if (value === '') {
        this.hiddenValues.splice(0, this.hiddenValues.length);
      } else {
        this.values.forEach((item) => {
          const includes = item.name.toString().trim().toLowerCase().includes(value);
          const alreadyHidden = this.hiddenValues.includes(item.value);
          if (includes && alreadyHidden) {
            this.hiddenValues.splice(this.hiddenValues.indexOf(item.value), 1);
          } else if (!includes && !alreadyHidden) {
            this.hiddenValues.push(item.value)
          }
        })
      }

      this.checkNeedAllMark();
    },
    change(event) {
      const target = event.target;
      if (target.checked) {
        this.currentValues.push(target.value)
      } else {
        this.currentValues.splice(this.currentValues.indexOf(target.value), 1);
      }
      this.checkNeedAllMark();
      this.$emit('input', event);
    },
    changeAll(event) {
      const target = event.target;
      target.checked ? this.all() : this.clear();
      this.$emit('input', event);
    },
    checkNeedAllMark() {
      if (!this.noDefault) {
        const allElement = this.$el.querySelector(`#${this.uniqueId(this.fieldId)}`);
        const avail = this.values
            .filter((item) => !this.hiddenValues.includes(item.value) && !this.disableValues.includes(item.value));
        const checkedLength = avail.filter((item) => this.currentValues.includes(item.value)).length;
        const isAll = avail.length === checkedLength;
        const checked = allElement.checked;

        if (isAll && !checked) {
          allElement.checked = true;
        } else if (!isAll && checked) {
          allElement.checked = false;
        }
      }
    },
    all() {
      this.values.forEach((item) => {
        const inCurrent = this.currentValues.includes(item.value);
        const isDisable = this.disableValues.includes(item.value);
        const isHidden = this.isHidden(item);
        if (!inCurrent && !isDisable && !isHidden) {
          this.currentValues.push(item.value);
        }
      });
    },
    clear() {
      const currentValues = Array.from(this.currentValues);
      currentValues.forEach((value) => {
        if (!this.hiddenValues.includes(value)) {
          this.currentValues.splice(this.currentValues.indexOf(value), 1);
        }
      });
    },
    getNameByValue(values) {
      if (this.isFocus) {
        return this.inputText;
      }

      const names = this.getArrayNames(values);
      if (names.length) {
        return names.join(', ');
      }

      return 'Все';
    },
    getArrayNames(values) {
      const names = [];
      const items = this.getItemsByValues(values);
      items.filter((item) => !this.disableValues.includes(item.value)).forEach((item) => names.push(item.name));

      return names;
    },
    getItemByValue(value) {
      return this.values.find((item) => item.value === value);
    },
    getItemsByValues(values) {
      return this.values.filter((item) => values.includes(item.value))
    },
    uniqueId(prefix, value) {
      value = value === undefined ? 'all' : value;
      return `multi-select_${prefix}_${value}`;
    },
    valueAsString(item) {
      return item.value !== undefined ? item.value : '';
    },
    nameAsString(item) {
      return item.name !== undefined ? item.name : '';
    },
    checkDisabled(value) {
      return this.disableValues ? this.disableValues.includes(value) : false;
    },
    isHidden(item) {
      return this.hiddenValues.includes(item.value);
    },
    isAllHidden() {
      return this.values.length === this.hiddenValues.length;
    },
    // Проверяем есть ли значение в текущих значениях
    shouldBeChecked(value) {
      return this.currentValues ? this.currentValues.includes(value) : false;
    },
    closeIfOther(e, instance) {
      const el = instance.$el.querySelector('[data-vue-custom-select]');
      if (instance.isOpen && !(el === e.target || el.contains(e.target))) {
        instance.isOpen = false;
      }
    }
  },
  mounted() {
    const instance = this;
    document.addEventListener('click', (e) => this.closeIfOther(e, instance));
    document.addEventListener('focusin', (e) => this.closeIfOther(e, instance));
  }
}
</script>