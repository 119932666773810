class MobileMenu {
    constructor(burgerElement, menuElement) {
        this.burger = burgerElement;
        this.container = menuElement;

        this.opened = false;

        this.closer = menuElement.querySelector('[data-closer]');

        this.bind();
    }

    bind() {
        this.bindBurger();
        this.bindCloser();
    }

    bindBurger() {
        this.burger.addEventListener('click', (e) => {
            e.preventDefault();
            if (this.opened) {
                this.closeMenu();
            } else {
                this.openMenu();
            }
        });
    }

    bindCloser() {
        this.closer.addEventListener('click', (e) => {
           e.preventDefault();
           this.closeMenu();
        });
    }

    openMenu() {
        this.opened = true;
        this.container.classList.add('_active');
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
    }

    closeMenu() {
        this.opened = false;
        this.container.classList.remove('_active');
        document.body.style.overflow = '';
        document.body.style.height = '';
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const burgerElement = document.querySelector('[data-menu-burger]');
    const menuElement = document.querySelector('[data-mobile-menu]');

    if (burgerElement && menuElement) {
        new MobileMenu(burgerElement, menuElement);
    }
});