import axios from 'axios';

const OrderService = {
  async save(orderData) {
    return axios.post('/order/api/index', orderData);
  },
  async success(orderId) {
    return axios.post('/order/success', { orderId }, {
      responseType: 'document',
    });
  },
};

export default OrderService;