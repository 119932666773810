import axios from 'axios';

const TreeService = {
  async fetch(baseUrl, context) {
    return axios.post(`${baseUrl}/fetch`, context);
  },
  async metadata(baseUrl, context) {
    return axios.post(`${baseUrl}/metadata`, context);
  },
  async search(baseUrl, context) {
    return axios.post(`${baseUrl}/search`, context);
  },
  async save(baseUrl, context) {
    return axios.post(`${baseUrl}/save`, context);
  },
  async validateFormula(context) {
    return axios.post('/markup/math-validate', context);
  },
};

export default TreeService;